import styled from "styled-components";

export const CommentContainer = styled.div`
  margin-top: 2rem;
`;

export const CommentContent = styled.div`
  display: flex;
  align-items: flex-start;
  p {
    margin-top: 0;
  }
  &:not(:last-child) {
    border-bottom: 2px solid rgba(76, 76, 76, 1);
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
`;

export const CommentUserInfo = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0 0 0 0.5rem;
  }
  h4 {
    margin: 0;
  }
  margin-bottom: 0.5rem;
`;

export const CommentUserAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.backgrounds.primary};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1rem;
`;

export const CommentEditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    transition: 0.2s;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    &:hover {
      cursor: pointer;
      color: #ff360e;
    }
  }
`;

export const CommentEditingActions = styled.div`
  svg {
    margin-left: 1rem;
    transition: 0.2s;
    &:hover {
      cursor: pointer;
      color: #ff360e;
    }
  }
`;
