import styled from "styled-components";
import { Avatar } from "@mui/material";

export const ProfileActions = styled.div`
  width: 100%;
  ul {
    list-style: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    li {
      transition: 0.2s;
      text-align: center;
      font-weight: 500;
      padding: 1rem;
      color: #fff;
      background: rgb(76, 76, 76);
      background: linear-gradient(
        180deg,
        rgba(76, 76, 76, 1) 0%,
        rgba(25, 25, 25, 1) 100%
      );
      border-radius: 0.25rem;
      margin-bottom: 1rem;
      &:hover {
        // color: ${(props) => props.theme.colors.orange};
      }
    }
  }
`;

export const UserProfileInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h5 {
    margin: 0;
  }
`;

export const UserAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.colors.dark};
  margin-right: 1rem;
  width: 80px !important;
  height: 80px !important;
  &:hover {
    cursor: pointer;
  }
`;

export const UserMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -1rem;
  padding: 0.5rem;
  background-color: #121015;
  ul {
    margin-top: 0;
    padding-inline-start: 0;
    list-style: none;
    margin-bottom: -1.05rem;
    li {
      transition: 0.3s;
      display: inline-block;
      padding-bottom: 1rem;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
`;

export const MessagesContainer = styled.div`
  div {
    background-color: #121015;
    padding: 0.25rem;
    p {
      margin: 0;
    }
    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
    &:first-child {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    &:last-child {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }
`;
