import styled from "styled-components";
import {
  Alert,
  Button,
  Checkbox,
  Container,
  Radio,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const ThemeSpacing = styled.div`
  margin: 2rem 0;
  padding: 0 2rem;
`;

export const ThemePaddingSpacing = styled.div`
  padding: 2rem 0;
`;

export const GlobalInput = styled.input`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #fff;
  background: #1e1b23;
  border: 1px solid #1e1b23;
  width: 100%;
  margin-bottom: 0.5rem;
  // firefox
  &:focus-visible {
    outline: 0;
  }
`;

export const GlobalInputDark = styled.input`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #fff;
  background: #121015;
  border: 1px solid #121015;
  // firefox
  &:focus-visible {
    outline: 0;
  }
`;

export const GlobalButtonSmall = styled.button`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #fff;
  background: #1e1b23;
  border: 1px solid #1e1b23;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  // firefox
  &:focus-visible {
    outline: 0;
  }
`;

export const GlobalButtonDark = styled.button`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #fff;
  background: #121015;
  border: 1px solid #121015;
  width: 300px;
  &:hover {
    cursor: pointer;
  }
  // firefox
  &:focus-visible {
    outline: 0;
  }
`;

export const GlobalButtonDarkNoWidth = styled.button`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #fff;
  background: #1e1b23;
  border: 1px solid #1e1b23;
  &:hover {
    cursor: pointer;
  }
  // firefox
  &:focus-visible {
    outline: 0;
  }
`;

export const GlobalButtonSmallNoWidth = styled.button`
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #fff;
  background: #121015;
  border: 1px solid #121015;
  &:hover {
    cursor: pointer;
  }
  // firefox
  &:focus-visible {
    outline: 0;
  }
`;

export const GlobalBox = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  background-color: ${(props) => props.theme.backgrounds.primary};
`;

export const GlobalBoxTransparent = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  background-color: rgba(45, 65, 89, 0.6);
  backdrop-filter: blur(4px);
`;

export const GlobalBoxDark = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  background-color: #4c4c4c;
  p {
    margin-top: 0;
  }
`;

export const GlobalBoxDarkFakeInput = styled.div`
  border-radius: 1rem;
  padding: 1rem;
  background-color: #121015;
  &:hover {
    cursor: text;
  }
`;

export const InputLabel = styled.label`
  margin-bottom: 1rem;
  display: block;
  font-weight: 500;
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const GlobalDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: #4c4c4c;
    color: #fff;
  }
`;

export const GlobalDialogRgba = styled(Dialog)`
  .MuiPaper-root {
    background: none;
    color: #fff;
  }
`;

export const GlobalDialogFullScreen = styled(Dialog)`
  .MuiPaper-root {
    background-color: #4c4c4c;
    color: #fff;
  }
`;

export const GlobalDialogContainer = styled.div`
  background-color: #4c4c4c;
  color: #fff;
`;

export const GlobalDialogContainerRgba = styled.div`
  background-color: rgba(25, 25, 25, 0.7);
  color: #fff;
`;

export const FileInputWrapper = styled.div`
  border: 2px dashed #fff;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
`;

export const SmallDark = styled.small`
  color: darkgray;
`;

export const GlobalSwitch = styled(Switch)`
  .MuiSwitch-thumb {
    background: rgb(254, 59, 24) !important;
    background: linear-gradient(
      180deg,
      rgba(254, 59, 24, 1) 0%,
      rgba(255, 111, 45, 1) 100%
    ) !important;
  }
  // .Mui-checked {
  //   color: #1b2838 !important;
  // }
  .MuiSwitch-track {
    background-color: white !important;
  }
`;

export const GlobalEditorContainer = styled.textarea`
  padding: 1rem;
  border-radius: 1rem;
  width: 80%;
  color: #fff;
  border: 1px solid #121015;
  background-color: #121015;
  resize: none;
`;

export const GlobalTextField = styled(TextField)`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  .MuiFormLabel-root {
    color: #000;
  }
  .MuiInputBase-root {
    color: #121212;
    background-color: #fff;
    border-radius: 0.25rem;
    .MuiInputAdornment-root {
      .MuiTypography-root {
        color: #000 !important;
      }
    }
    .Mui-disabled {
      color: #000 !important;
      -webkit-text-fill-color: #000;
    }
  }
`;

export const GlobalTextFieldDark = styled(TextField)`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  .MuiFormLabel-root {
    color: #fff;
  }
  .MuiInputBase-root {
    color: #fff;
    background-color: #1b2838;
    border-radius: 1rem;
    .MuiInputAdornment-root {
      .MuiTypography-root {
        color: #fff !important;
      }
    }
    .Mui-disabled {
      color: #fff !important;
      -webkit-text-fill-color: white;
    }
  }
`;

export const GlobalSelect = styled(Select)`
  background-color: #fff;
  color: #000 !important;
  width: 100%;
  border-radius: 0.25rem !important;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const GlobalSelectDark = styled(Select)`
  background-color: #fff;
  color: #000 !important;
  width: 100%;
  border-radius: 0.25rem !important;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  .MuiSvgIcon-root {
    color: #fff !important;
  }
  .MuiPaper-root {
    background-color: #fff;
    color: #000 !important;
  }
`;

export const GlobalButtonStyled = styled(Button)`
  font-weight: bold !important;
  padding: 0 1.5rem !important;
  text-transform: none !important;
  color: #fff !important;
  border-radius: 0.25rem !important;
  //border: 2px solid #e86f13 !important;
  height: 50px;
  background: rgb(254, 59, 24) !important;
  background: linear-gradient(
    180deg,
    rgba(254, 59, 24, 1) 0%,
    rgba(255, 111, 45, 1) 100%
  ) !important;
  small {
    margin-left: 2rem;
  }
`;

export const GlobalButtonStyledDanger = styled(Button)`
  font-weight: bold !important;
  padding: 0 1.5rem !important;
  text-transform: none !important;
  color: #fff !important;
  border-radius: 0.25rem !important;
  border: 2px solid #c10000ff !important;
  height: 50px;
  background-color: #c10000ff !important;

  small {
    margin-left: 2rem;
  }
`;

export const GlobalButtonStyledDark = styled(Button)`
  padding: 0 1.5rem !important;
  font-weight: bold !important;
  border-radius: 1rem !important;
  border: 1px solid #121015;
  text-transform: none !important;
  height: 50px;
  color: #fff !important;
  background-color: #121015 !important;
  &:disabled {
    background-color: darkgray !important;
  }
`;

export const GlobalButtonStyledDarkSmall = styled(Button)`
  padding: 0 1.5rem !important;
  font-weight: bold !important;
  border-radius: 1rem !important;
  border: 2px solid ${(props) => props.theme.colors.orange} !important;
  text-transform: none !important;
  height: 40px;
  color: #fff !important;
  background-color: ${(props) => props.theme.colors.orange} !important;
  &:disabled {
    background-color: darkgray !important;
  }
`;

export const GlobalButtonStyledDarkSmallClicked = styled(Button)`
  padding: 0 1.5rem !important;
  font-weight: bold !important;
  border-radius: 1rem !important;
  border: 2px solid limegreen !important;
  text-transform: none !important;
  height: 40px;
  color: #fff !important;
  background-color: #e86f13 !important;
  &:disabled {
    background-color: darkgray !important;
  }
`;

export const GlobalButtonStyledSmall = styled(Button)`
  font-weight: bold !important;
  padding: 0 1rem !important;
  text-transform: none !important;
  color: #fff !important;
  border-radius: 0.25rem !important;
  //border: 2px solid #e86f13 !important;
  height: 40px;
  background: rgb(254, 59, 24) !important;
  background: linear-gradient(
    180deg,
    rgba(254, 59, 24, 1) 0%,
    rgba(255, 111, 45, 1) 100%
  ) !important;
  small {
    margin-left: 2rem;
  }
`;

export const FullWidthContainer = styled(Container)`
  max-width: 1920px !important;
`;

export const GlobalTabs = styled(Tabs)`
  .MuiTabs-scroller {
    border-bottom: 2px solid #e86f13;
    .MuiTabs-indicator {
      background-color: #e86f13;
    }
  }
`;

export const GlobalTab = styled(Tab)`
  text-transform: none !important;
  color: #fff !important;
  // border-top: 2px solid #121015 !important;
  // border-left: 2px solid #121015 !important;
  // border-right: 2px solid #121015 !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  background: rgb(254, 59, 24) !important;
  background: linear-gradient(
    180deg,
    rgba(254, 59, 24, 1) 0%,
    rgba(255, 111, 45, 1) 100%
  ) !important;
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const GlobalCode = styled.code`
  background-color: #1e1b23;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
`;

export const GlobalCodeDiscussion = styled.code`
  background-color: #fe5121;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.7rem;
  color: #191919;
`;

export const GlobalCodeDark = styled.code`
  background-color: #121015;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
`;

export const ShowMore = styled.span`
  background-color: ${(props) => props.theme.colors.orange};
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  &:hover {
    cursor: pointer;
  }
`;

export const GlobalCodeDarkSmall = styled.code`
  font-size: 0.6rem;
  background-color: #121015;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  width: max-content;
  margin-bottom: 0.25rem;
  &:not(:last-child) {
    margin-right: 0.25rem;
  }
`;

export const GlobalAlert = styled(Alert)`
  border-radius: 0.25rem !important;
`;

export const Small = styled.p`
  font-size: 0.8rem;
`;

export const Smaller = styled.p`
  font-size: 0.7rem;
`;

export const Badge = styled.div`
  margin-left: 0.5rem;
  width: max-content;
  font-size: 0.8rem;
  color: #191919;
  background-color: #ff360e;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
`;

export const GameTitle = styled.div`
  h1,
  h4 {
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  }
`;

export const GlobalRadio = styled(Radio)`
  &.MuiRadio-root {
    color: #fff;
    &.Mui-checked {
      color: #1b2838;
    }
  }
`;

export const GlobalTooltip = styled(Tooltip)`
  && .MuiTooltip-tooltip {
    background-color: #121015 !important;
  }
`;

export const CursorPointer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const AdornmentHover = styled.div`
  color: #191919;
  transition: 0.25s;
  &:hover {
    cursor: pointer;
    color: #ff360e;
  }
`;

export const GlobalCheckbox = styled(Checkbox)`
  margin-right: 0.5rem !important;
  &.MuiCheckbox-root {
    color: #fff !important;
  }
  &.Mui-checked {
    color: #ff360e !important; /* Example blue color when checked */
  }
  &.MuiCheckbox-indeterminate {
    color: #f44336;
  }
`;
