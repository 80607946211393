import React, { useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { CommentEditorContainer } from "./styles/editorStyles";
import ReactQuill from "react-quill";
import {
  GlobalButtonStyled,
  GlobalButtonStyledSmall,
  Small,
} from "../../utils/globalStyles";
import {
  CommentContainer,
  CommentContent,
  CommentUserAvatar,
} from "../comments/styles/commentsStyles";

interface EditorProps {
  editingCommentId?: any;
  comment: any;
  setComment: any;
  handleSaveComment: any;
}

const CommentEditor: React.FC<EditorProps> = ({
  editingCommentId,
  comment,
  setComment,
  handleSaveComment,
}) => {
  const quillRef = useRef<ReactQuill | null>(null);

  const modules = {
    toolbar: [],
  };

  const handleChange = (value: string) => {
    setComment(value);
  };

  return (
    <>
      <CommentEditorContainer>
        <ReactQuill
          ref={quillRef}
          modules={modules}
          value={comment}
          onChange={handleChange}
          placeholder="Leave a comment..."
        />
      </CommentEditorContainer>
      {comment !== "<p><br></p>" && comment !== "" ? (
        <>
          {editingCommentId === undefined ? (
            <GlobalButtonStyled
              style={{ marginTop: "1rem" }}
              onClick={() => {
                handleSaveComment();
              }}
            >
              Add Comment
            </GlobalButtonStyled>
          ) : (
            <GlobalButtonStyledSmall
              style={{ marginTop: "1rem" }}
              onClick={() => {
                handleSaveComment();
              }}
            >
              Update Comment
            </GlobalButtonStyledSmall>
          )}
        </>
      ) : null}
    </>
  );
};

export default CommentEditor;
