export function parseDate(date: string): string {
  const currentDate = new Date();
  const joinedDate = new Date(date);

  const timeDifferenceInMilliseconds =
    currentDate.getTime() - joinedDate.getTime();
  const timeDifferenceInSeconds = Math.floor(
    timeDifferenceInMilliseconds / 1000,
  );
  const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);
  const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);
  const timeDifferenceInDays = Math.floor(timeDifferenceInHours / 24);
  const timeDifferenceInMonths = Math.floor(timeDifferenceInDays / 30);
  const timeDifferenceInYears = Math.floor(timeDifferenceInDays / 365);

  if (timeDifferenceInYears > 0) {
    return `${timeDifferenceInYears} year${
      timeDifferenceInYears > 1 ? "s" : ""
    } ago`;
  } else if (timeDifferenceInMonths > 0) {
    return `${timeDifferenceInMonths} month${
      timeDifferenceInMonths > 1 ? "s" : ""
    } ago`;
  } else if (timeDifferenceInDays > 0) {
    return `${timeDifferenceInDays} day${
      timeDifferenceInDays > 1 ? "s" : ""
    } ago`;
  } else if (timeDifferenceInHours > 0) {
    return `${timeDifferenceInHours} hour${
      timeDifferenceInHours > 1 ? "s" : ""
    } ago`;
  } else if (timeDifferenceInMinutes > 0) {
    return `${timeDifferenceInMinutes} minute${
      timeDifferenceInMinutes > 1 ? "s" : ""
    } ago`;
  } else {
    return "just now";
  }
}
