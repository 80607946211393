import React, { useEffect, useState } from "react";
import { GlobalButtonStyled, GlobalTooltip } from "../../utils/globalStyles";
import { Container, Grid } from "@mui/material";
import LoginDialog from "../login/LoginDialog";
import { GameTypes, GameImageTypes } from "../../utils/types/GameTypes";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  GameActionButtons,
  GameActionContainer,
  GameImage,
  GameImageContainer,
  GamesHeader,
} from "./styles/gameStyles";
import GameHeaderInfo from "./GameHeaderInfo";
import GameDisplayLeft from "./GameDisplayLeft";
import GameDisplayCenter from "./GameDisplayCenter";
import GameDisplayRight from "./GameDisplayRight";
import NotFound from "../notFound/NotFound";
import { GameFollowersTypes } from "../../utils/types/GameFollowersTypes";
import GameImageDialog from "./dialog/GameImageDialog";
import GameComments from "./GameComments";
import GameRequirements from "./GameRequirements";
import LoadingBackdrop from "../loading/LoadingBackdrop";

function Game({ user, cart, setCart }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [game, setGame] = useState<GameTypes>({} as GameTypes);
  const [gameLikes, setGameLikes] = useState({
    like_count: 0,
    data: [
      {
        user_id: "",
        game_id: "",
      },
    ],
  });
  const [gameFollowers, setGameFollowers] = useState<GameFollowersTypes>({
    follower_count: 0,
    data: [
      {
        user_id: "",
        game_id: "",
      },
    ],
  });
  const [gameComments, setGameComments] = useState<any>({
    comment_count: 0,
    data: [
      {
        user_id: "",
        comment: "",
        created_at: "",
        updated_at: "",
      },
    ],
  });
  const [gameImages, setGameImages] = useState<any>([] as any);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openGameCommentDialog, setOpenGameCommentDialog] =
    React.useState(false);
  const [noGameFound, setNoGameFound] = useState(false);
  const [viewCountUpdated, setViewCountUpdated] = useState(false);
  const [gameLikesChanged, setGameLikesChanged] = useState(false);
  const [gameFollowersChanged, setGameFollowersChanged] = useState(false);
  const [comment, setComment] = useState("");
  const [commentsChanged, setCommentsChanged] = useState(false);
  const [userOwnsGame, setUserOwnsGame] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openGameImageDialog, setOpenGameImageDialog] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedType, setSelectedType] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gameResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/get-game`,
          { id },
        );
        if (gameResponse.data.error) {
          setNoGameFound(true);
          setLoading(false);
        } else {
          setGame(gameResponse.data);
          document.title = `Reaktor Arcade - ${gameResponse.data.title}`;
        }

        const gameImagesResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/get-game-images`,
          {
            game_id: gameResponse.data.game_id,
          },
        );
        setGameImages(gameImagesResponse.data);

        const gameLikesResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/get-game-likes`,
          {
            game_id: gameResponse.data.game_id,
          },
        );
        setGameLikes(gameLikesResponse.data.like_count);

        const gameFollowersResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/get-game-followers`,
          {
            game_id: gameResponse.data.game_id,
          },
        );
        setGameFollowers(gameFollowersResponse.data);

        const gameCommentsResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/get-game-comments`,
          {
            game_id: gameResponse.data.game_id,
          },
        );
        setGameComments(gameCommentsResponse.data);

        setCommentsChanged(false);
        setGameLikesChanged(false);
        setGameFollowersChanged(false);

        const gamesOwnedResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/get-users-owned-games`,
          { user_id: user.id },
        );

        if (gamesOwnedResponse.data.error) {
          console.error(gamesOwnedResponse.data.error);
        } else {
          const userOwnsGame = gamesOwnedResponse.data.games.some(
            (game: any) => game.game_id === id,
          );
          if (userOwnsGame || user.admin === 1) {
            setUserOwnsGame(true);
          } else {
            setUserOwnsGame(false);
          }
        }
      } catch (error) {
        console.error("Error fetching game information:", error);
      }
    };

    fetchData().then(() => setLoading(false));
  }, [gameLikesChanged, gameFollowersChanged, commentsChanged, userOwnsGame]);

  useEffect(() => {
    if (!viewCountUpdated) {
      const updateViewCount = async () => {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_HOST}/games/update-game-view-count`,
            {
              game_id: id,
            },
          );
        } catch (error) {
          console.error("Error updating view count:", error);
        }
      };
      updateViewCount().then(() => setViewCountUpdated(true));
    }
  }, [id]);

  const handleAddGameFollower = () => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/games/update-game-followers`, {
        game_id: game.game_id,
        user_id: user.id,
      })
      .then((response) => {
        setGameFollowers(response.data);
        setGameFollowersChanged(true);
      })
      .catch((error) => {
        console.error("Error fetching game information:", error);
      });
  };

  const handleAddGameLike = () => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/games/update-game-likes`, {
        game_id: game.game_id,
        user_id: user.id,
      })
      .then((response) => {
        // Assuming response.data includes both like_count and an array of likes data
        setGameLikes({
          like_count: response.data.like_count,
          data: response.data.data, // Ensure this matches the actual response structure
        });
        setGameLikesChanged(true);
      })
      .catch((error) => {
        console.error("Error fetching game information:", error);
      });
  };

  const handleRemoveGameLike = () => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/games/delete-game-like`, {
        game_id: game.game_id,
        user_id: user.id,
      })
      .then((response) => {
        // Update setGameLikes to handle an object with like_count and data array
        setGameLikes({
          like_count: response.data.like_count,
          data: response.data.data, // Adjust based on actual response structure
        });
        setGameLikesChanged(true); // Assuming you're using this to trigger re-fetching or re-rendering elsewhere
      })
      .catch((error) => {
        console.error("Error fetching game information:", error);
      });
  };

  const isUserAFollower = () => {
    if (!Array.isArray(gameFollowers.data)) {
      // console.log("gameFollowers.data is not an array:", gameFollowers.data);
      return false;
    }

    const userIdAsString = String(user?.id);
    const follower = gameFollowers.data.find(
      (follower) => follower.user_id === userIdAsString,
    );

    return !!follower;
  };

  const handleOpenGameImageDialog = (imageSource: any, type: string) => {
    if (imageSource != null) {
      setSelectedImage(imageSource);
      setSelectedType(type);
    }
    setOpenGameImageDialog(true);
  };

  const hasUserLiked = () => {
    if (!Array.isArray(gameLikes.data)) {
      return false;
    }

    const userIdAsString = String(user.id);
    const likes = gameLikes.data.find(
      (likes) => likes.user_id === userIdAsString,
    );

    return !!likes;
  };

  const handleSaveComment = async (commentId: any) => {
    // Saving a brand-new comment
    if (!commentId) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/add-game-comment`,
          {
            user_id: user.id,
            game_id: game.game_id,
            comment: comment,
          },
        );
        if (response.data.success) {
          setCommentsChanged(true);
          setComment("");
        }
      } catch (error) {
        console.error("Axios Error:", error);
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/edit-game-comment`,
          {
            comment_id: commentId,
            comment: comment,
          },
        );
        if (response.data.success) {
          setCommentsChanged(true);
          setComment("");
        }
      } catch (error) {
        console.error("Axios Error:", error);
      }
    }
  };

  const handleDeleteComment = async (commentId: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/delete-game-comment`,
        {
          comment_id: commentId,
          user_id: user?.id,
        },
      );
      if (response.data.success) {
        setCommentsChanged(true);
        setComment("");
      }
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  const isGameInCart = (gameId: any) => {
    return cart.some((item: any) => item.game_id === gameId);
  };

  const handleAddToGameLibrary = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/user/add-game-to-user`,
        {
          user_id: user.id,
          game_id: game.game_id,
        },
      );
      if (response.data.success) {
        setUserOwnsGame(true);
      }
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  const handleRemoveFromCart = (gameId: any) => {
    const updatedCart = cart.filter(
      (item: { game_id: string }) => item.game_id !== gameId,
    );
    setCart(updatedCart);
    sessionStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleDownloadGame = () => {
    navigate(`/@${user.username}/games/owned`);
  };

  if (loading) return <LoadingBackdrop />;

  if (noGameFound) {
    return <NotFound />;
  }

  return (
    <>
      <LoginDialog open={open} handleClose={handleClose} />
      <GameImageDialog
        game={game}
        open={openGameImageDialog}
        setOpen={setOpenGameImageDialog}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        editing={false}
        gameImages={gameImages}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
        selectedType={selectedType}
      />
      <GamesHeader
        style={{
          backgroundImage: `url('${process.env.REACT_APP_API_GAME_UPLOADS}/${game.header_path}')`,
        }}
      >
        <div style={{ zIndex: 99 }}>
          <h1>{game.title}</h1>
          <h4 style={{ marginTop: "0.5rem", marginBottom: 0 }}>{game.stage}</h4>
        </div>
      </GamesHeader>
      <div style={{ padding: "2rem" }}>
        <GameActionContainer>
          {/* <GameHeaderInfo
            game={game}
            gameFollowers={gameFollowers}
            gameComments={gameComments}
            setOpenGameCommentDialog={setOpenGameCommentDialog}
          /> */}
          <GameActionButtons>
            {game.website && (
              <a
                href={`https://${game.website}`}
                target="_blank"
                rel="noreferrer"
              >
                <GlobalButtonStyled style={{ marginRight: "1rem" }}>
                  View Website
                </GlobalButtonStyled>
              </a>
            )}
            {game.store && (
              <a
                href={`https://${game.website}`}
                target="_blank"
                rel="noreferrer"
              >
                <GlobalButtonStyled style={{ marginRight: "1rem" }}>
                  View Store
                </GlobalButtonStyled>
              </a>
            )}
            {isUserAFollower() ? (
              <GlobalTooltip
                title={`You follow this game!`}
                placement="top"
                arrow
              >
                <GlobalButtonStyled
                  style={{
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    handleAddGameFollower();
                  }}
                >
                  Following
                  <span style={{ marginLeft: "3rem" }}>
                    {gameFollowers.follower_count
                      ? gameFollowers.follower_count
                      : 0}
                  </span>
                </GlobalButtonStyled>
              </GlobalTooltip>
            ) : (
              <GlobalButtonStyled
                style={{
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  if (user?.id) {
                    handleAddGameFollower();
                  } else {
                    setOpen(true);
                  }
                }}
              >
                Follow
                <span style={{ marginLeft: "3rem" }}>
                  {gameFollowers.follower_count
                    ? gameFollowers.follower_count
                    : 0}
                </span>
              </GlobalButtonStyled>
            )}
            {/*<GlobalButtonStyledDarkSmall>*/}
            {/*  <FontAwesomeIcon icon={faPlus} />*/}
            {/*</GlobalButtonStyledDarkSmall>*/}
            {user?.id === game.user_id || user?.admin === 1 ? (
              <a href={`/games/edit/${game.game_id}`}>
                <GlobalButtonStyled style={{ marginLeft: "0.5rem" }}>
                  Edit Game
                </GlobalButtonStyled>
              </a>
            ) : isGameInCart(game.game_id) ? (
              <GlobalButtonStyled
                style={{ marginLeft: "0.5rem" }}
                onClick={() => handleRemoveFromCart(game.game_id)}
              >
                {/* <span
                  style={{ fontSize: "0.8rem", marginRight: "1rem" }}
                >{`$${game.price}`}</span>{" "} */}
                Remove from Cart
              </GlobalButtonStyled>
            ) : (
              <GlobalButtonStyled
                style={{ marginLeft: "0.5rem" }}
                onClick={() => {
                  if (user?.id) {
                    handleAddToGameLibrary();
                  } else {
                    setOpen(true);
                  }
                }}
              >
                <>
                  {userOwnsGame ? (
                    <span
                      onClick={() => {
                        handleDownloadGame();
                      }}
                    >
                      Download Game
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          marginRight: "1rem",
                        }}
                      >
                        Free to Play
                      </span>
                      Add to Game Library
                    </>
                  )}
                </>
              </GlobalButtonStyled>
            )}
          </GameActionButtons>
        </GameActionContainer>
        <GameImageContainer>
          {/* @ts-ignore */}
          {gameImages.map((image: any) => (
            image.video_path !== null && (
              <GameImage key={image.id}
                style={{
                  backgroundImage: `url(https://img.youtube.com/vi/${image.video_path}/hqdefault.jpg)`
                }}
                onClick={() => {
                  handleOpenGameImageDialog(image.video_path, "video");
                  setCurrentImageIndex(gameImages.findIndex((img: { video_path: any; }) => img.video_path === image.video_path));
                }}
              />
            )
          ))}
          {/* @ts-ignore */}
          {gameImages.map((image: any) => (
            image.image_path !== null && (
              <GameImage
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_API_GAME_UPLOADS}/${image.image_path})`,
                }}
                key={image.id}
                onClick={() => {
                  handleOpenGameImageDialog(image.image_path, "image");
                  setCurrentImageIndex(gameImages.findIndex((img: { image_path: any; }) => img.image_path === image.image_path));
                }}
              />
            )
          ))}
        </GameImageContainer>
        <Container maxWidth="xl">
          <Grid container spacing={3} style={{ marginTop: "1rem" }}>
            <Grid item lg={8} xs={12}>
              <GameDisplayCenter game={game} />
            </Grid>
            <Grid item lg={4} xs={12}>
              <GameDisplayLeft
                user={user}
                game={game}
                gameLikes={gameLikes}
                handleAddGameLike={handleAddGameLike}
                handleRemoveGameLike={handleRemoveGameLike}
                hasUserLiked={hasUserLiked}
                handleOpen={handleOpen}
              />
              <GameRequirements
                game={game}
              />
              <GameComments
                user={user}
                open={openGameCommentDialog}
                setOpenGameCommentDialog={setOpenGameCommentDialog}
                comment={comment}
                gameComments={gameComments}
                setComment={setComment}
                handleSaveComment={handleSaveComment}
                handleDeleteComment={handleDeleteComment}
                game={game}
              />
              <GameDisplayRight game={game} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Game;
