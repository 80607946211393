import React from "react";
import { ProfileActions } from "./styles/profileStyles";

function ProfileActionBar() {
  return (
    <ProfileActions>
      <ul>
        <a href="/profile/settings">
          <li>Profile</li>
        </a>
        <a href="/profile/settings/change-password">
          <li>Change Password</li>
        </a>
        <li>
          <a href="/profile/settings/two-factor">2FA Settings</a>
        </li>
      </ul>
    </ProfileActions>
  );
}

export default ProfileActionBar;
