import React from "react";
import DOMPurify from "dompurify";
import { GameDescription } from "./styles/gameStyles";

function GameDisplayCenter({ game }: any) {
  const sanitizedDescription = DOMPurify.sanitize(game.description);

  return (
    <GameDescription
      dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
    />
  );
}

export default GameDisplayCenter;
