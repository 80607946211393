import React, { useEffect, useState } from "react";
import { GlobalButtonSmall, GlobalButtonStyledSmall, Small } from "../../utils/globalStyles";
import CommentEditor from "../editor/CommentEditor";
import {
  CommentContainer,
  CommentContent,
  CommentEditContainer,
  CommentEditingActions,
  CommentUserAvatar,
  CommentUserInfo,
} from "../comments/styles/commentsStyles";
import Sanitize from "../sanitize/Sanitize";
import { parseDate } from "../../utils/helpers/parseDate";
import { getUserInfo } from "../../utils/helpers/getUserInfo";
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons/faPenToSquare";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons/faRotateLeft";

function GameComments({
  user,
  open,
  setOpenGameCommentDialog,
  comment,
  setComment,
  handleSaveComment,
  handleDeleteComment,
  gameComments,
  game,
}: any) {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(new Map());
  const [editingCommentId, setEditingCommentId] = useState<null | string>(null);
  const [currentTab, setCurrentTab] = useState("All"); // Add state for current tab

  const handleClose = () => {
    setOpenGameCommentDialog(false);
    setEditingCommentId(null); // Reset editing state when dialog is closed
  };

  const handleStopEditing = () => {
    setComment("");
    setEditingCommentId(null);
  };

  const handleEditing = (commentId: string) => {
    setEditingCommentId(commentId);
    // Find the comment by its ID and set it as the current comment to be edited.
    const commentToEdit = gameComments.data.find(
      (c: any) => c.id === commentId,
    );
    if (commentToEdit) {
      setComment(commentToEdit.comment);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfos = new Map();
      const userIds = gameComments.data.map(
        (gameComment: { user_id: any; display_name: any }) =>
          gameComment.user_id,
      );
      for (const userId of userIds) {
        const info = await getUserInfo(userId);
        userInfos.set(userId, info);
      }
      setUserInfo(userInfos);
    };

    if (gameComments.data.length > 0) {
      fetchUserInfo().then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [gameComments]);

  if (loading) return null;

  const filteredComments =
    currentTab === "Dev"
      ? gameComments.data.filter((comment: any) => comment?.user_id == game?.user_id)
      : gameComments.data;

  return (
    <div style={{ marginTop: "2rem" }}>
      <h3>
        {gameComments.comment_count ? gameComments.comment_count : 0} comments
      </h3>
      <div style={{ marginBottom: "1rem" }}>
        <GlobalButtonStyledSmall style={{ marginRight: "0.5rem" }} onClick={() => setCurrentTab("All")}>All</GlobalButtonStyledSmall>
        <GlobalButtonStyledSmall onClick={() => setCurrentTab("Dev")}>From the Developer</GlobalButtonStyledSmall>
      </div>
      {!editingCommentId && user ? (
        <CommentEditor
          comment={comment}
          setComment={setComment}
          handleSaveComment={handleSaveComment}
        />
      ) : null}
      <CommentContainer>
        {filteredComments && filteredComments.length > 0 ? (
          filteredComments.map((gameComment: any) => (
            <CommentContent key={gameComment.id}>
              {editingCommentId === gameComment.id ? (
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    {userInfo.get(gameComment.user_id)?.avatar !== null ? (
                      <CommentUserAvatar
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_API_USER_UPLOADS
                            }/avatar/${userInfo.get(gameComment.user_id)?.avatar
                            })`,
                        }}
                      />
                    ) : (
                      <CommentUserAvatar
                        style={{
                          backgroundSize: "50%",
                          backgroundImage: `url(${logo}`,
                        }}
                      />
                    )}
                    <CommentUserInfo>
                      <h4>{userInfo.get(gameComment.user_id)?.username}</h4>
                      {userInfo.get(gameComment.user_id)?.display_name !==
                        null ? (
                        <Small>
                          {userInfo.get(gameComment.user_id)?.display_name
                            ? `@${userInfo.get(gameComment.user_id)?.display_name}`
                            : null}
                        </Small>
                      ) : null}
                      <Small>{parseDate(gameComment.created_at)}</Small>
                    </CommentUserInfo>
                  </div>
                  <CommentEditingActions>
                    <CommentEditor
                      editingCommentId={editingCommentId}
                      comment={comment}
                      setComment={setComment}
                      handleSaveComment={() => {
                        handleSaveComment(editingCommentId);
                        setEditingCommentId(null);
                      }}
                    />
                    <FontAwesomeIcon
                      style={{ fontSize: "1.25rem", marginBottom: "-0.6rem" }}
                      icon={faRotateLeft}
                      onClick={() => {
                        handleStopEditing();
                      }}
                    />
                  </CommentEditingActions>
                </div>
              ) : (
                <>
                  {userInfo.get(gameComment.user_id)?.avatar !== null ? (
                    <CommentUserAvatar
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_USER_UPLOADS
                          }/avatar/${userInfo.get(gameComment.user_id)?.avatar}`,
                      }}
                    />
                  ) : (
                    <CommentUserAvatar
                      style={{
                        backgroundSize: "50%",
                        backgroundImage: `url(${logo}`,
                      }}
                    />
                  )}
                  <div style={{ width: "85%" }}>
                    <CommentUserInfo>
                      <h4>{userInfo.get(gameComment.user_id)?.username}</h4>
                      {userInfo.get(gameComment.user_id)?.display_name !==
                        null ? (
                        <Small>
                          {userInfo.get(gameComment.user_id)?.display_name
                            ? `@${userInfo.get(gameComment.user_id)?.display_name}`
                            : null}
                        </Small>
                      ) : null}
                      <Small>{parseDate(gameComment.created_at)}</Small>
                    </CommentUserInfo>
                    <Sanitize content={gameComment.comment} />
                    {user?.username ===
                      userInfo.get(gameComment.user_id)?.username ? (
                      <CommentEditContainer>
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          onClick={() => handleEditing(gameComment.id)}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => {
                            handleDeleteComment(gameComment.id);
                          }}
                        />
                      </CommentEditContainer>
                    ) : null}
                  </div>
                </>
              )}
            </CommentContent>
          ))
        ) : (
          <p style={{ margin: 0 }}>
            This game doesn't have any comments yet, be the first!
          </p>
        )}
      </CommentContainer>
    </div>
  );
}

export default GameComments;
