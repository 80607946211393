import React, { useEffect } from "react";
import { GlobalButtonStyled, GlobalButtonStyledSmall, GlobalInput, GlobalTextField, ThemeSpacing } from "../../utils/globalStyles";
import Container from "@mui/material/Container/Container";
import Grid from "@mui/material/Grid/Grid";
import { useNavigate, useParams } from "react-router-dom";
import CommunityEditor from "../editor/CommunityEditor";
import axios from "axios";
import LoadingBackdrop from "../loading/LoadingBackdrop";
import NotFound from "../notFound/NotFound";

function CommunityAnnouncementEdit({ user }: { user: any }) {
  const navigate = useNavigate();
  const { id: communityId, title: communityTitle, announcementId: announcementId } = useParams();

  const [description, setDescription] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [noAnnouncementFound, setNoAnnouncementFound] = React.useState(false);
  const [community, setCommunity] = React.useState<any>(null);
  const [announcement, setAnnouncement] = React.useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const communityResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/community/get-community`,
          { id: communityId },
        );
        if (communityResponse.data.error) {
          setNoAnnouncementFound(true);
        } else {
          setCommunity(communityResponse.data);
        }
        const announcementResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/community/get-announcement`,
          { id: announcementId, community_id: communityId },
        );
        if (announcementResponse.data.error) {
          setNoAnnouncementFound(true);
        } else {
          if (announcementResponse.data.posted_by !== user.id) {
            navigate(`/communities/${communityTitle}/${communityId}/announcements/${announcementId}`);
          }
          setAnnouncement(announcementResponse.data);
          setDescription(announcementResponse.data.content);
          setTitle(announcementResponse.data.title);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData().then(() => setLoading(false));
  }, [communityId, communityTitle, announcementId])

  if (loading) return <LoadingBackdrop />;

  if (noAnnouncementFound) {
    return <NotFound />;
  }

  const handleDescriptionChange = (description: string) => {
    setDescription(description);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleEditAnnouncement = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_HOST}/community/edit-announcement`, {
        announcement_id: announcementId,
        title,
        content: description,
        user_id: user.id,
      });

      if (response.status === 200) {
        //alert("Announcement edited successfully");
        // Redirect to announcements page
        navigate(`/communities/${communityTitle}/${communityId}/announcements/${announcementId}`);
      } else {
        //alert("Failed to create announcement");
      }
    } catch (error) {
      console.error("Error creating announcement:", error);
      alert("An error occurred while creating the announcement");
    }
  };

  return (
    <ThemeSpacing>
      <Container>
        <Grid container>
          <Grid item xs={8}>
            <a href={`/communities/${communityTitle}/${communityId}/announcements/${announcementId}`}>
              <GlobalButtonStyledSmall style={{ marginBottom: "1rem" }}>
                Go Back
              </GlobalButtonStyledSmall>
            </a>
            <h1>Edit Announcement</h1>
            <GlobalTextField
              onChange={handleTitleChange}
              style={{ marginBottom: "1rem" }}
              placeholder="Announcement title"
              value={title}
            />
            <CommunityEditor
              description={description}
              handleDescriptionChange={handleDescriptionChange}
            />
            <GlobalButtonStyled style={{ marginTop: "1rem" }} onClick={handleEditAnnouncement}>
              Edit Announcement
            </GlobalButtonStyled>
          </Grid>
        </Grid>
      </Container>
    </ThemeSpacing>
  );
}

export default CommunityAnnouncementEdit;
