import Grid from "@mui/material/Grid/Grid";
import Pagination from "@mui/material/Pagination/Pagination";
import React, { useEffect, useState } from "react";
import { GlobalButtonStyled, GlobalButtonStyledSmall, GlobalTextField } from "../../utils/globalStyles";
import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { CAContainer } from "./styles/communityStyles";
import { parseTitle } from "../../utils/helpers/parseTitle";
import axios from "axios";
import { parseDate } from "../../utils/helpers/parseDate";
import DOMPurify from "dompurify";
import LoadingBackdrop from "../loading/LoadingBackdrop";
import userEvent from "@testing-library/user-event";

function CommunityAnnouncements({ community, user }: { community: any, user: any }) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [announcements, setAnnouncements] = React.useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/community/get-announcements`, { community_id: community.game_id })
      .then((response) => {
        setAnnouncements(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching announcements:", error);
        setLoading(false);
      });
  }, [community.game_id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setSearchTerm(event.target.value);
  }

  const handleSearch = () => {
    return null;
  }

  const truncateAndSanitize = (htmlContent: any, limit: any) => {
    let div = document.createElement('div');
    div.innerHTML = DOMPurify.sanitize(htmlContent);
    let textContent = div.textContent || div.innerText || "";
    if (textContent.length > limit) {
      textContent = textContent.substring(0, limit) + "...";
    }
    return DOMPurify.sanitize(textContent);
  };

  if (loading) return <LoadingBackdrop />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "1rem" }}>
          <div>
            <span>Showing {announcements.length} out of {announcements.length} announcements</span>
          </div>
          <div>
            <Pagination count={1} variant="outlined" />
          </div>
        </div>
        <div>
          {announcements.map((announcement: any) => (
            <CAContainer key={announcement.id}>
              <h2><a href={`/communities/${parseTitle(community.title)}/${community.game_id}/announcements/${announcement.announcement_id}`}>{announcement.title}</a></h2>
              <small>{parseDate(announcement.updated_at)}</small>
              <div dangerouslySetInnerHTML={{ __html: `<p>${truncateAndSanitize(announcement.content, 340)}</p>` }} />
              <GlobalButtonStyledSmall style={{ marginTop: "1rem" }}>
                <a href={`/communities/${parseTitle(community.title)}/${community.game_id}/announcements/${announcement.announcement_id}`}>
                  See more
                </a>
              </GlobalButtonStyledSmall>
            </CAContainer>
          ))}
        </div>
      </Grid>
      <Grid item xs={12} lg={4}>
        <GlobalTextField
          variant="outlined"
          placeholder="Search announcements"
          name="search"
          value={searchTerm}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{ paddingLeft: "0.5rem" }}
                  onClick={(e) => {
                    handleSearch();
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        {
          announcements[0]?.user_id === user?.id && (
            <a href={`/communities/${parseTitle(community.title)}/${community.game_id}/announcements/create`}>
              <GlobalButtonStyled
                style={{ marginTop: "0.5rem", width: "100%" }}
              >
                Create Announcement
              </GlobalButtonStyled>
            </a>
          )
        }
      </Grid>
    </Grid >
  );
}

export default CommunityAnnouncements;