import Grid from "@mui/material/Grid/Grid";
import DOMPurify from "dompurify";
import React from "react";

function CommunityAbout({ community }: any) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={9}>
        <h2>About {community.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(community.overview) }} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <h2>Membership</h2>
        <h4>{community.membership_count} members</h4>
      </Grid>
    </Grid>
  );
}

export default CommunityAbout;