import React, { useEffect, useState } from "react";
import {
  GlobalButtonStyled,
  GlobalCheckbox,
  GlobalCodeDarkSmall,
  GlobalTextField,
} from "../../utils/globalStyles";
import { Grid, InputAdornment } from "@mui/material";
import LoginDialog from "../login/LoginDialog";
import {
  CategoryContainer,
  GamesHeader,
  GameThumbnail,
} from "./styles/gameStyles";
import gameSplash from "../../assets/games_header.webp";
import categoryPlaceholder from "../../assets/categories/category_placeholder.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { GameTypes } from "../../utils/types/GameTypes";
import axios from "axios";
import gameDefaultImage from "../../assets/game_default.png";
import ActionCategoryImage from "../../assets/categories/action-00.png";
import AdventureCategoryImage from "../../assets/categories/adventure-00.png";
import StrategyCategoryImage from "../../assets/categories/strategy-00.png";
import ArcadeCategoryImage from "../../assets/categories/arcade-00.png";
import HorrorCategoryImage from "../../assets/categories/horror-00.png";
import FightingCategoryImage from "../../assets/categories/fighting-00.png";
import { parseStringToOriginal } from "../../utils/helpers/parseStringToOriginal";
import { parseTitle } from "../../utils/helpers/parseTitle";
import { gameTags } from "../../utils/gameTags";
import LoadingBackdrop from "../loading/LoadingBackdrop";

function Games({ user }: any) {
  const [open, setOpen] = useState(false);
  const [games, setGames] = React.useState<GameTypes[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [platformFilters, setPlatformFilters] = useState<string[]>([]);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [genreFilters, setGenreFilters] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    document.title = "Reaktor Arcade - Games";
    axios
      .post(`${process.env.REACT_APP_API_HOST}/games/get-games`)
      .then((response) => {
        setGames(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user's games:", error);
      });
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  }

  const handlePlatformChange = (platform: any) => {
    const normalizedPlatform = platform.replace(/\s+/g, '-').toLowerCase();
    setPlatformFilters(prev => {
      return prev.includes(normalizedPlatform) ? prev.filter(item => item !== normalizedPlatform) : [...prev, normalizedPlatform];
    });
  };

  const handleStatusChange = (status: any) => {
    setStatusFilters(prev => {
      return prev.includes(status) ? prev.filter(item => item !== status) : [...prev, status];
    });
  };

  const handleGenreChange = (genre: any) => {
    const normalizedGenre = genre.replace(/\s+/g, '-').toLowerCase();
    setGenreFilters(prev => {
      return prev.includes(normalizedGenre) ? prev.filter(item => item !== normalizedGenre) : [...prev, normalizedGenre];
    });
  };

  const filteredGames = games.filter(game => {
    const titleMatch = game.title && game.title.toLowerCase().includes(searchTerm.toLowerCase());

    const platformMatch = platformFilters.length === 0 || (typeof game.platforms === 'string' && game.platforms.split(',').some((platform: string) => platformFilters.includes(platform.trim())));

    const statusMatch = statusFilters.length === 0 || (game.stage && statusFilters.includes(game.stage));

    const genreMatch = genreFilters.length === 0 || (typeof game.genres === 'string' && game.genres.split(',').some((genre: string) => {
      const trimmedGenre = genre.trim();
      return genreFilters.includes(trimmedGenre);
    }));

    return titleMatch && platformMatch && statusMatch && genreMatch;
  });

  if (loading) return <LoadingBackdrop />;

  return (
    <>
      <LoginDialog open={open} handleClose={handleClose} />
      <GamesHeader style={{ backgroundImage: `url('${gameSplash}')` }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <h1>Games</h1>
          {user?.username === undefined ? (
            <GlobalButtonStyled
              onClick={() => {
                handleOpen();
              }}
            >
              Add Your Game
            </GlobalButtonStyled>
          ) : (
            <a href="/games/add">
              <GlobalButtonStyled>Add Your Game</GlobalButtonStyled>
            </a>
          )}
        </div>
      </GamesHeader>
      <div style={{ padding: "2rem" }}>
        <div>
          <h2 style={{ marginBottom: "2rem" }}>Popular Genres</h2>
          <Grid container spacing={4}>
            <Grid item xs={4} lg={2} md={3}>
              <CategoryContainer
                onClick={() => handleGenreChange('action')}
                style={{
                  backgroundImage: `url('${ActionCategoryImage}')`,
                  border: genreFilters.includes('action') ? '2px solid #ff360e' : ' 2px solid #191919'
                }}
              >
                <span>Action</span>
              </CategoryContainer>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              <CategoryContainer
                onClick={() => handleGenreChange('adventure')}
                style={{
                  backgroundImage: `url('${AdventureCategoryImage}')`,
                  border: genreFilters.includes('adventure') ? '2px solid #ff360e' : ' 2px solid #191919'
                }}
              >
                <span>Adventure</span>
              </CategoryContainer>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              <CategoryContainer
                onClick={() => handleGenreChange('strategy')}
                style={{
                  backgroundImage: `url('${StrategyCategoryImage}')`,
                  border: genreFilters.includes('strategy') ? '2px solid #ff360e' : ' 2px solid #191919'
                }}
              >
                <span>Strategy</span>
              </CategoryContainer>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              <CategoryContainer
                onClick={() => handleGenreChange('arcade')}
                style={{
                  backgroundImage: `url('${ArcadeCategoryImage}')`,
                  border: genreFilters.includes('arcade') ? '2px solid #ff360e' : ' 2px solid #191919'
                }}
              >
                <span>Arcade</span>
              </CategoryContainer>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              <CategoryContainer
                onClick={() => handleGenreChange('horror')}
                style={{
                  backgroundImage: `url('${HorrorCategoryImage}')`,
                  border: genreFilters.includes('horror') ? '2px solid #ff360e' : ' 2px solid #191919'
                }}
              >
                <span>Horror</span>
              </CategoryContainer>
            </Grid>
            <Grid item xs={4} lg={2} md={3}>
              <CategoryContainer
                onClick={() => handleGenreChange('fighting')}
                style={{
                  backgroundImage: `url('${FightingCategoryImage}')`,
                  border: genreFilters.includes('fighting') ? '2px solid #ff360e' : ' 2px solid #191919'
                }}
              >
                <span>Fighting</span>
              </CategoryContainer>
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <h2 style={{ marginBottom: "2rem" }}>Browse Games</h2>
          <Grid container spacing={4}>
            <Grid item lg={8} md={8} xs={12}>
              <Grid container spacing={3}>
                {
                  filteredGames.length > 0
                    ? (
                      filteredGames.map((game: any) => (
                        <Grid item lg={3} md={4} xs={6} key={game.id}>
                          <a
                            href={`/games/${parseTitle(game.title)}/${game.game_id}`}
                          >
                            <GameThumbnail
                              style={{
                                backgroundImage:
                                  game.thumbnail_path !== null
                                    ? `url(${process.env.REACT_APP_API_GAME_UPLOADS}/${game.thumbnail_path})`
                                    : `url(${gameDefaultImage})`,
                              }}
                            >
                              <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {game.genres !== null &&
                                  game.genres.split(",").map((tag: string) => {
                                    return (
                                      <GlobalCodeDarkSmall key={tag}>
                                        {parseStringToOriginal(tag)}
                                      </GlobalCodeDarkSmall>
                                    );
                                  })}
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <h3>{game.title}</h3>
                              </div>
                            </GameThumbnail>
                          </a>
                        </Grid>
                      ))
                    ) : (
                      <Grid item lg={12}>
                        <p>No games found matching your filters.</p>
                      </Grid>
                    )
                }
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <GlobalTextField
                variant="outlined"
                placeholder="Search games"
                name="search"
                value={searchTerm}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ paddingLeft: "0.5rem" }}
                      // onClick={(e) => {
                      //   handleSearch();
                      // }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ marginTop: "1rem" }}>
                <div style={{ marginBottom: "1rem" }}>
                  <h3>Platforms</h3>
                  {['Windows', 'MacOS', 'Linux', 'Web', 'Android', 'iOS'].map(platform => (
                    <div style={{ marginBottom: "0.5rem" }} key={platform}>
                      <GlobalCheckbox
                        checked={platformFilters.includes(platform.toLowerCase())}
                        onChange={() => handlePlatformChange(platform)}
                        style={{ padding: 0 }}
                      />
                      <span>{platform}</span>
                    </div>
                  ))}
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <h3>Status</h3>
                  {['Devlog', 'Early Access', 'Stable'].map(status => (
                    <div style={{ marginBottom: "0.5rem" }} key={status}>
                      <GlobalCheckbox
                        checked={statusFilters.includes(status)}
                        onChange={() => handleStatusChange(status)}
                        style={{ padding: 0 }}
                      />
                      <span>{status}</span>
                    </div>
                  ))}
                </div>
                <div>
                  <h3>Genre</h3>
                  {gameTags.map(genre => (
                    <div style={{ marginBottom: "0.5rem" }} key={genre.value}>
                      <GlobalCheckbox
                        checked={genreFilters.includes(genre.name.toLowerCase()) || genreFilters.includes(genre.name.replace(/\s+/g, '-').toLowerCase())}
                        onChange={() => handleGenreChange(genre.name)}
                        style={{ padding: 0 }}
                      />
                      <span>{genre.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Games;
