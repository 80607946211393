import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  GlobalButtonStyled,
  GlobalTextField,
  InputLabel,
  ThemeSpacing,
} from "../../utils/globalStyles";
import { UserProfileInfo } from "./styles/profileStyles";
import { maskEmail } from "../../utils/helpers/maskEmail";
import ProfileActionBar from "./ProfileActionBar";
import AvatarDialog from "./AvatarDialog";
import axios from "axios";
import FloatingAlert from "../floatingAlert/FloatingAlert"; // Make sure to install axios if you haven't

function EditProfile({ user }: any) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    display_name: user?.display_name || "",
    profile_bio: user?.profile_bio || "",
    website: user?.website || "",
  });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [avatarUploaded, setAvatarUploaded] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      setFormData({
        display_name: user.display_name,
        profile_bio: user.profile_bio,
        website: user.website,
      });
    }
    document.title = "Reaktor Arcade - Edit Profile";
  }, [user, navigate, showSuccessAlert, avatarUploaded]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/user/update-profile`,
        {
          user_id: user?.id,
          display_name: formData.display_name,
          profile_bio: formData.profile_bio,
          website: formData.website,
        },
      );
      if (response.status === 200) {
        setShowSuccessAlert(true);
        setAlertMessage("Successfully updated your profile");
        setTimeout(() => setShowSuccessAlert(false), 3000);
        setFormData({
          display_name: user?.display_name || "",
          profile_bio: user?.profile_bio || "",
          website: user?.website || "",
        });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile");
    }
  };

  return (
    <ThemeSpacing>
      {showSuccessAlert && (
        <FloatingAlert severity="success" message={alertMessage} />
      )}
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <h1 style={{ marginTop: 0 }}>Edit Profile</h1>
            <UserProfileInfo>
              <AvatarDialog
                user={user}
                setShowSuccessAlert={setShowSuccessAlert}
                setAlertMessage={setAlertMessage}
                setAvatarUploaded={setAvatarUploaded}
              />
              <div>
                {formData.display_name ? (
                  <>
                    <h3>{formData.display_name}</h3>
                    <h5>@{user?.username}</h5>
                  </>
                ) : (
                  <h5>@{user?.username}</h5>
                )}
              </div>
            </UserProfileInfo>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <ProfileActionBar />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Email</InputLabel>
              <GlobalTextField
                disabled
                type="text"
                value={maskEmail(user?.email)}
              />
              <InputLabel>Username</InputLabel>
              <GlobalTextField disabled type="text" value={user?.username} />
              <InputLabel>Display Name</InputLabel>
              <GlobalTextField
                name="display_name"
                type="text"
                value={formData.display_name}
                onChange={handleChange}
              />
              <InputLabel>Profile Bio</InputLabel>
              <GlobalTextField
                name="profile_bio"
                type="text"
                value={formData.profile_bio}
                onChange={handleChange}
              />
              <InputLabel>Website</InputLabel>
              <GlobalTextField
                name="website"
                type="text"
                value={formData.website}
                onChange={handleChange}
              />
              <GlobalButtonStyled
                style={{ marginTop: "1rem" }}
                onClick={handleSave}
              >
                Update Settings
              </GlobalButtonStyled>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeSpacing>
  );
}

export default EditProfile;
