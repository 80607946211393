import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

function LoadingBackdrop () {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 9999 }}
      open={true}
    >
      <CircularProgress color="inherit" size={80} />
    </Backdrop>
  );
}

export default LoadingBackdrop;