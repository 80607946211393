import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import avatar from "../../assets/logo.png";
import { UserAvatar } from "./styles/profileStyles";
import {
  FileInputWrapper,
  GlobalButtonDarkNoWidth,
  GlobalDialog,
  GlobalDialogContainer,
} from "../../utils/globalStyles";
import axios from "axios";
import { useState } from "react";
import Cookies from "js-cookie";
import { Transition } from "../dialog/Transition";

function AvatarDialog({
  user,
  setShowSuccessAlert,
  setAlertMessage,
  setAvatarUploaded,
}: any) {
  const token = Cookies.get("token");
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg"];

      // Check if the selected file has a valid file type
      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        // Display an error or provide feedback for invalid file type
        console.error("Invalid file type. Please select a PNG or JPEG file.");
      }
    }
  };

  const handleRemove = () => setSelectedFile(undefined);

  const handleSave = async () => {
    if (!selectedFile) return;

    try {
      const formData = new FormData();
      formData.append("avatar", selectedFile);
      formData.append("user_id", user.id);

      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/profile/upload-avatar`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response.status === 200) {
        setOpen(false);
        setShowSuccessAlert(true);
        setAlertMessage("Successfully updated your avatar");
        setAvatarUploaded(true);
        setTimeout(() => setShowSuccessAlert(false), 3000);
      } else {
        console.error("Avatar upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);
    } finally {
      // handleClose();
    }
  };

  return (
    <>
      {user.avatar_path ? (
        <UserAvatar
          alt={user?.username}
          src={`${process.env.REACT_APP_API_USER_UPLOADS}/avatar/${user.avatar_path}`}
          onClick={() => {
            handleClickOpen();
          }}
        />
      ) : (
        <UserAvatar
          alt={user?.username}
          onClick={() => {
            handleClickOpen();
          }}
        />
      )}
      <GlobalDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <GlobalDialogContainer>
          <DialogTitle>Upload New Avatar</DialogTitle>
          <DialogContent>
            <p>Your image must be a PNG or JPG.</p>
            <p>PNGs are highly recommended as they produce a lossless image.</p>
            <FileInputWrapper>
              <input
                type="file"
                style={{ width: "100%" }}
                onChange={handleFileChange}
              />
            </FileInputWrapper>
            {selectedFile && (
              <div>
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Selected File"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    margin: "0 0 1rem 0",
                  }}
                />
                <div>
                  <GlobalButtonDarkNoWidth onClick={handleSave}>
                    Save
                  </GlobalButtonDarkNoWidth>
                  <GlobalButtonDarkNoWidth
                    onClick={handleRemove}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    Remove Avatar
                  </GlobalButtonDarkNoWidth>
                </div>
              </div>
            )}
          </DialogContent>
        </GlobalDialogContainer>
      </GlobalDialog>
    </>
  );
}

export default AvatarDialog;
