import React from "react";
import { ThemeSpacing } from "../utils/globalStyles";
import { Container, Grid } from "@mui/material";
import DisplayLogo from "../components/logo/DisplayLogo";

function ErrorBackdrop() {
  return (
    <ThemeSpacing style={{ padding: "8rem 0" }}>
      <Container>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DisplayLogo />
            </div>
            <h1 style={{ fontSize: "4rem", marginBottom: "1rem" }}>500</h1>
            <h2>Internal Server Error</h2>
            <p>Something went wrong on our end. We're working dilligently to fix the issue, please try again later.</p>
          </Grid>
        </Grid>
      </Container>
    </ThemeSpacing>
  );
}

export default ErrorBackdrop;
