export function parseCount(string: string): string {
  const views = parseInt(string);

  if (views >= 1000000000) {
    return (views / 1000000000).toFixed(1) + "b";
  } else if (views >= 1000000) {
    return (views / 1000000).toFixed(1) + "m";
  } else if (views >= 1000) {
    return (views / 1000).toFixed(1) + "k";
  } else {
    return string;
  }
}
