import axios from "axios";

export const getUserInfo = async (userId: number) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/user/get-user-info`,
      { id: userId },
    );

    if (response.status === 200) {
      return {
        username: response.data.username,
        avatar: response.data.avatar_path,
        display_name: response.data.display_name,
      };
    } else {
      console.error("Failed to get User Info:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching User Info:", error);
  }
};
