import React, { useRef, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { EditorContainer } from "./styles/editorStyles";
import ReactQuill from "react-quill";
import axios from "axios";
import { RangeStatic } from "quill";

interface EditorProps {
  game: any;
  community: any;
  handleOverviewChange: (content: string) => void;
}

const CommunityOverviewEditor: React.FC<EditorProps> = ({ game, community, handleOverviewChange }) => {
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.getModule("toolbar").addHandler("image", imageHandler);
    }
  }, []);

  let quillSelection: RangeStatic | null = null;

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("game_id", game.game_id);
    formData.append("image", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/upload-image`,
        formData,
      );

      if (response.status === 200 && quillRef.current) {
        const quill = quillRef.current.getEditor();
        // Insert image into the editor at the saved selection
        if (quillSelection) {
          quill.insertEmbed(
            quillSelection.index,
            "image",
            response.data.image_path,
          );
        }
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const imageHandler = () => {
    if (quillRef.current) {
      quillSelection = quillRef.current.getEditor().getSelection(); // Save the current selection
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const file = input.files ? input.files[0] : null;
        if (file) {
          await handleImageUpload(file);
        }
      };
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image", "link", "video", "code-block"],
    ],
  };

  return (
    <EditorContainer>
      <ReactQuill
        ref={quillRef}
        value={community?.overview || null}
        onChange={handleOverviewChange}
        modules={modules}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "link",
          "image",
          "video",
          "code-block",
        ]}
      />
    </EditorContainer>
  );
};

export default CommunityOverviewEditor;
