import styled from "styled-components";

export const EditorContainer = styled.div`
  border-radius: 0.25rem;
  .ql-toolbar.ql-snow {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .ql-container.ql-snow {
    font-size: 1rem !important;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    p {
      margin: 0 0 1rem 0 !important;
    }
    img {
      padding: 0 1rem;
    }
  }
`;

export const CommentEditorContainer = styled.div`
  background-color: #fff;
  color: #000;
  border-radius: 0.25rem;
  .ql-toolbar.ql-snow {
    display: none;
  }
  .ql-container.ql-snow {
    font-size: 1rem !important;
    border-radius: 0.25rem;
    p {
      margin-top: 0;
      &:not(:last-child) {
        margin: 0 0 1rem 0 !important;
      }
    }
    img {
      padding: 0 1rem;
    }
  }
`;
