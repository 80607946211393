import React, { useState } from "react";
import {
  GlobalButtonStyled,
  GlobalDialog,
  GlobalDialogContainer,
  GlobalTextField,
} from "../../utils/globalStyles";
import { useNavigate } from "react-router-dom";
import { validateLoginForm } from "../../utils/helpers/loginValidation";
import axios from "axios";
import { Transition } from "../dialog/Transition";
import DialogContent from "@mui/material/DialogContent";
import logo from "../../assets/Logo-White-Transparent.png";

function LoginDialog({ user, returnLink, open, handleClose }: any) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    if (!validateLoginForm(formData, setErrors)) {
      return;
    }
    setErrors({
      username: "",
      password: "",
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/auth/login`,
        formData,
      );

      if (response.status === 200) {
        const { token } = response.data;

        // Store the token securely, e.g., in an HTTP-only cookie or local storage
        document.cookie = `token=${token}; path=/`;

        await window.location.reload();
      } else {
        console.error("Login failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  if (user) navigate("/");

  return (
    <GlobalDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="xs"
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <GlobalDialogContainer>
        <DialogContent>
          <div style={{ textAlign: "center", margin: "0 0 1rem 0" }}>
            <img src={logo} alt="Reaktor Arcade" style={{ width: "60%" }} />
          </div>
          <GlobalTextField
            type="text"
            placeholder="Username"
            name="username"
            className={errors.username ? "form-error" : ""}
            value={formData.username ? formData.username : ""}
            onChange={handleChange}
          />
          {errors.username && (
            <p style={{ color: "red", marginTop: 0 }}>{errors.username}</p>
          )}
          <GlobalTextField
            type="password"
            placeholder="Password"
            name="password"
            className={errors.password ? "form-error" : ""}
            value={formData.password ? formData.password : ""}
            onChange={handleChange}
          />
          {errors.password && (
            <p style={{ color: "red", marginTop: 0 }}>{errors.password}</p>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            <GlobalButtonStyled
              variant="contained"
              onClick={handleLogin}
              style={{ width: "100%" }}
            >
              Login
            </GlobalButtonStyled>
            <a href="/register" style={{ marginLeft: "0.5rem" }}>
              <GlobalButtonStyled variant="contained" style={{ width: "100%" }}>
                Register
              </GlobalButtonStyled>
            </a>
          </div>
        </DialogContent>
      </GlobalDialogContainer>
    </GlobalDialog>
  );
}

export default LoginDialog;
