import React, { useEffect, useState } from "react";
import {
  GlobalButtonStyled,
  GlobalTextField,
  InputLabel,
  ThemeSpacing,
} from "../../utils/globalStyles";
import { Alert, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserProfileInfo } from "./styles/profileStyles";
import ProfileActionBar from "./ProfileActionBar";
import AvatarDialog from "./AvatarDialog";
import axios from "axios";
import QRCode from "qrcode.react";

function TwoFactorAuthentication({ user }: any) {
  const navigate = useNavigate();
  const [secret, setSecret] = useState("");
  const [authenticatorCode, setAuthenticatorCode] = useState("");
  const [twoFactorSuccess, setTwoFactorSuccess] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  const confirmTwoFactor = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/auth/verify-token`,
        {
          ...user,
          token: authenticatorCode,
        },
      );

      if (response.data.verified) {
        setTwoFactorSuccess(true);
        window.location.reload();
      } else {
        setInvalidCode(true);
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      setError("An unexpected error occured while attempting to enable two-factor authentication.");
    }
  };

  const disableTwoFactor = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/auth/disable-two-factor`,
        {
          ...user,
          token: authenticatorCode,
        },
      );
  
      if (response.data.success) {
        setTwoFactorSuccess(false);
        window.location.reload();
      } else {
        setInvalidCode(true);
      }
    } catch (error) {
      setError("An unexpected error occurred while attempting to disable two-factor authentication.");
    }
  };  

  const generateSecret = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/auth/generate-secret`,
      { ...user },
    );
    setSecret(response.data.secret);
  };

  const userHasTwoFactor = user?.two_factor_enabled === 1;

  return (
    <ThemeSpacing>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <h1 style={{ marginTop: 0 }}>Edit Profile</h1>
            <UserProfileInfo>
              <AvatarDialog user={user} />
              <div>
                {user?.display_name ? (
                  <>
                    <h3>{user?.display_name}</h3>
                    <h5>@{user?.username}</h5>
                  </>
                ) : (
                  <h5>@{user?.username}</h5>
                )}
              </div>
            </UserProfileInfo>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <ProfileActionBar />
            </Grid>
            <Grid item xs={6}>
              {error && <Alert severity="error" style={{ marginBottom: "0.5rem" }}>{error}</Alert>}
              {twoFactorSuccess && (
                <Alert severity="success">Two-factor authentication is enabled. Your account is secure!</Alert>
              )}
              {invalidCode && (
                <Alert severity="error" style={{ marginBottom: "0.5rem" }}>Invalid authentication code. Please try again.</Alert>
              )}
              {userHasTwoFactor && (
                <>
                  <Alert severity="success">Two-factor authentication is currently enabled. Your account is secure!</Alert>
                  <p>Enter your authentication code below to disable two-factor authentication, and then click Disable Two Factor.</p>
                  <InputLabel>Enter Authenticator Code</InputLabel>
                  <GlobalTextField
                    type="text"
                    value={authenticatorCode}
                    onChange={(e) => setAuthenticatorCode(e.target.value)}
                  />
                  <GlobalButtonStyled style={{ marginTop: "1rem" }} onClick={disableTwoFactor}>
                    Disable Two Factor
                  </GlobalButtonStyled>
                </>
              )}
              {!userHasTwoFactor && (
                !secret ? (
                  <div>
                    <Alert severity="error">Two-factor authentication is not enabled. Your account is at risk!</Alert>
                    <GlobalButtonStyled onClick={generateSecret} style={{ marginTop: "1rem" }}>
                      Enable Two Factor
                    </GlobalButtonStyled>
                  </div>
                ) : secret && !twoFactorSuccess && (
                  <>
                    <QRCode value={secret} />
                    <p>Scan this QR code with your authenticator app.</p>
                    <InputLabel>Enter Authenticator Code</InputLabel>
                    <GlobalTextField
                      type="text"
                      value={authenticatorCode}
                      onChange={(e) => setAuthenticatorCode(e.target.value)}
                    />
                    <GlobalButtonStyled
                      style={{ marginTop: "1rem" }}
                      onClick={confirmTwoFactor}
                    >
                      Confirm
                    </GlobalButtonStyled>
                  </>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeSpacing>
  );
}

export default TwoFactorAuthentication;
