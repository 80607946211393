import styled from "styled-components";
import { Avatar, Popover } from "@mui/material";

export const NavigationContainer = styled.div`
  z-index: 999;
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.text};
  padding: 0.25rem 2rem;
  //border-bottom: 4px solid #0677a1;
`;

export const NavigationLogo = styled.div`
  filter: drop-shadow(8px 8px 4px #191919);
  font-family: "Sixtyfour", sans-serif;
  display: flex;
  align-items: center;
  span {
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: 1rem;
  }
`;

export const BetaText = styled.h3`
  font-family:
    Nunito,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  margin: -4px 0 0 1rem;
  font-size: 1rem;
  letter-spacing: 2px;
  font-style: italic;
`;

export const NavigationLogoContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 36px;
  }
  ul {
    list-style: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    @media screen and (max-width: 1024px) {
      display: none;
    }
    li {
      font-weight: bold;
      display: inline;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
`;

export const SearchContainer = styled.div`
  width: 500px;
  margin: 0 auto;
`;

export const SearchInput = styled.input`
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 1rem;
  color: ${(props) => props.theme.colors.dark};
  background-color: ${(props) => props.theme.backgrounds.primary};
  border: 1px solid ${(props) => props.theme.backgrounds.primary};
  // firefox
  &:focus-visible {
    outline: 0;
  }
`;

export const NavPopoverContainer = styled.div`
  display: none !important;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const UserActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  ul {
    list-style: none;
    @media screen and (max-width: 1024px) {
      display: none;
    }
    li {
      a {
        transition: 0.25s;
        &:hover {
          color: #ff360e;
        }
      }
      text-shadow: 3px 3px 4px #191919;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      display: inline-block;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
`;

export const UserNav = styled.div`
  display: flex;
  margin-left: 2rem;
  justify-content: flex-end;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const NavCartContainer = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const AvatarCircle = styled(Avatar)`
  width: 30px !important;
  height: 30px !important;
`;

export const AvatarCircleMedium = styled(Avatar)`
  width: 50px !important;
  height: 50px !important;
`;

export const AvatarCircleLarge = styled(Avatar)`
  width: 90px !important;
  height: 90px !important;
`;

export const AvatarWrapper = styled.div.attrs({ as: "div" })`
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
`;

export const GlobalPopover = styled(Popover)`
  .MuiPaper-root {
    border-radius: 0.25rem;
    background-color: #3dad5f;
    margin-top: 0.5rem;
  }
`;

export const UserDropdown = styled.div`
  .MuiPaper-root-MuiPopover-paper {
    border-radius: 0.25rem !important;
  }
  border-radius: 0.25rem !important;
  background-color: #4c4c4c;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 250px;
  button {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

export const UserInfo = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;
  a {
    color: #fff;
  }
  h4 {
    margin: 0;
  }
  span {
    font-size: 0.8rem;
  }
`;

export const HamburgerContainer = styled.div`
  color: #fff;
  display: none;
  @media screen and (max-width: 1024px) {
    display: inline-block;
  }
`;

export const MobileMenuContainer = styled.div`
  background-color: #191919;
  position: fixed; /* Changed from absolute to fixed */
  top: 0; /* Changed from 92px to 0 */
  left: 0;
  width: 100%;
  height: 100vh; /* Ensure it covers the full viewport height */
  padding: 5rem 2rem 2rem 2rem;
  z-index: 998;
  transition: 0.3s ease-in-out;
  overflow: hidden; /* Prevents scrolling within the menu */
  ul {
    list-style: none;
    padding-inline-start: 0;
    li {
      font-size: 1.5rem;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
`;
