import React, { useEffect, useRef, useState } from "react";
import { GlobalButtonStyledSmall, ThemeSpacing } from "../../utils/globalStyles";
import { Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { parseTitle } from "../../utils/helpers/parseTitle";
import { GamesHeader } from "../games/styles/gameStyles";
import axios from "axios";
import LoadingBackdrop from "../loading/LoadingBackdrop";
import NotFound from "../notFound/NotFound";
import { parseDate } from "../../utils/helpers/parseDate";
import { DiscussionSingleCommentContainer, DiscussionSingleContainer } from "./styles/communityStyles";
import { AvatarCircleMedium } from "../navigation/styles/navigationStyles";
import DOMPurify from "dompurify";
import { CommentEditorContainer } from "../editor/styles/editorStyles";
import ReactQuill from "react-quill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { CommentEditingActions } from "../comments/styles/commentsStyles";

function CommunityDiscussion({ user }: { user: any }) {
  const communityId = useParams().id;
  const communityTitle = useParams().title;
  const discussionId = useParams().discussionId;

  const [community, setCommunity] = useState<any>(null);
  const [discussion, setDiscussion] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [noDiscussionFound, setNoDiscussionFound] = useState(false);
  const [comment, setComment] = useState("");
  const [editedComment, setEditedComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
  const [commentChanged, setCommentChanged] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const communityResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/community/get-community`,
          { id: communityId },
        );
        if (communityResponse.data.error) {
          setNoDiscussionFound(true);
        } else {
          setCommunity(communityResponse.data);
        }
        const discussionResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/community/get-discussion`,
          { id: discussionId },
        );
        if (discussionResponse.data.error) {
          setNoDiscussionFound(true);
        } else {
          setDiscussion(discussionResponse.data);
          setCommentChanged(false);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData().then(() => setLoading(false));
  }, [communityId, communityTitle, discussionId, commentChanged]);

  const handleChange = (value: string) => {
    setComment(value);
  };

  const handleEditChange = (value: string) => {
    setEditedComment(value);
  };

  const quillRef = useRef<ReactQuill | null>(null);

  const modules = {
    toolbar: [],
  };

  const handlePostComment = () => {
    if (editingCommentId) {
      // Edit existing comment
      axios.post(`${process.env.REACT_APP_API_HOST}/community/edit-discussion-comment`, {
        comment_id: editingCommentId,
        content: editedComment,
        user_id: user?.id,
      }).then(() => {
        setComment("");
        setEditingCommentId(null);
        // Refresh the discussion to reflect the updated comment
        fetchDiscussion();
      }).catch((error) => {
        console.error("Error editing comment:", error);
      });
    } else {
      // Post new comment
      axios.post(`${process.env.REACT_APP_API_HOST}/community/add-discussion-comment`, {
        discussion_id: discussionId,
        content: comment,
        pinned: 0,
        posted_by: user?.id,
      }).then(() => {
        setComment("");
        // Refresh the discussion to reflect the new comment
        setCommentChanged(true);
      }).catch((error) => {
        console.error("Error posting comment:", error);
      });
    }
  };

  const handleEditComment = (comment: any) => {
    setEditedComment(comment.content);
    setEditingCommentId(comment.id);
  };

  const handleDeleteComment = (commentId: number) => {
    axios.post(`${process.env.REACT_APP_API_HOST}/community/delete-discussion-comment`, {
      comment_id: commentId,
      user_id: user?.id,
    }).then(() => {
      setCommentChanged(true);
    }).catch((error) => {
      console.error("Error deleting comment:", error);
    });
  }

  const fetchDiscussion = async () => {
    try {
      const discussionResponse = await axios.post(
        `${process.env.REACT_APP_API_HOST}/community/get-discussion`,
        { id: discussionId },
      );
      if (discussionResponse.data.error) {
        setNoDiscussionFound(true);
      } else {
        setDiscussion(discussionResponse.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <LoadingBackdrop />;

  if (noDiscussionFound) {
    return <NotFound />;
  }

  return (
    <>
      <GamesHeader style={{
        backgroundImage: `url('${process.env.REACT_APP_API_GAME_UPLOADS}/${community.header_path}')`,
      }}
      />
      <ThemeSpacing>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <GlobalButtonStyledSmall style={{ marginBottom: "1rem" }}>
                <a href={`/communities/${parseTitle(communityTitle)}/${communityId}/discussions`}>
                  Go Back
                </a>
              </GlobalButtonStyledSmall>
              <DiscussionSingleContainer style={{ marginBottom: "1rem" }}>
                <Grid container spacing={2}>
                  <Grid item lg={1}>
                    {discussion.avatar_path ? (
                      <AvatarCircleMedium
                        alt={discussion.username}
                        src={`${process.env.REACT_APP_API_USER_UPLOADS}/avatar/${discussion.avatar_path}`}
                      />
                    ) : (
                      <AvatarCircleMedium alt={discussion.username} />
                    )}
                  </Grid>
                  <Grid item lg={11}>
                    <h4>
                      {discussion.display_name ? discussion.display_name : discussion.username}
                      {' | '}
                      {parseDate(discussion.created_at)}
                    </h4>
                    <h2>{discussion.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(discussion.content) }} />
                    {discussion.posted_by == user?.id && (
                      <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                        <CommentEditingActions>
                          <FontAwesomeIcon icon={faEdit} style={{ marginLeft: 0 }} />
                          <FontAwesomeIcon icon={faTrash} />
                        </CommentEditingActions>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </DiscussionSingleContainer>
              {discussion.comments.map((comment: any) => (
                <DiscussionSingleCommentContainer style={{ marginBottom: "1rem" }} key={comment.comment_id}>
                  <Grid container spacing={2}>
                    <Grid item lg={1}>
                      {comment.avatar_path ? (
                        <AvatarCircleMedium
                          alt={comment.username}
                          src={`${process.env.REACT_APP_API_USER_UPLOADS}/avatar/${comment.avatar_path}`}
                        />
                      ) : (
                        <AvatarCircleMedium alt={comment.username} />
                      )}
                    </Grid>
                    <Grid item lg={11}>
                      <h5>
                        {comment.display_name ? comment.display_name : comment.username}
                        {' | '}
                        {parseDate(comment.created_at)}
                      </h5>
                      {editingCommentId === comment.id ? (
                        <>
                          <CommentEditorContainer>
                            <ReactQuill
                              ref={quillRef}
                              modules={modules}
                              value={editedComment}
                              onChange={handleEditChange}
                              placeholder="Leave a comment..."
                            />
                          </CommentEditorContainer>
                          <CommentEditingActions style={{ marginTop: "1rem" }}>
                            <GlobalButtonStyledSmall
                              onClick={handlePostComment}
                            >
                              Save Comment
                            </GlobalButtonStyledSmall>
                            <FontAwesomeIcon
                              icon={faUndo}
                              style={{ marginLeft: "1rem" }}
                              onClick={() => {
                                setEditingCommentId(null);
                                setEditedComment("");
                              }}
                            />
                          </CommentEditingActions>
                        </>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.content) }} />
                      )}
                      {comment.posted_by === user?.id && editingCommentId !== comment.id && (
                        <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                          <CommentEditingActions>
                            <FontAwesomeIcon icon={faEdit} style={{ marginLeft: 0 }} onClick={() => handleEditComment(comment)} />
                            <FontAwesomeIcon icon={faTrash} onClick={() => { handleDeleteComment(comment.id) }} />
                          </CommentEditingActions>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </DiscussionSingleCommentContainer>
              ))}
              {user && (
                <DiscussionSingleCommentContainer style={{ marginTop: "1rem" }}>
                  <Grid container spacing={2}>
                    <Grid item lg={1}>
                      {user?.avatar_path ? (
                        <AvatarCircleMedium
                          alt={user.username}
                          src={`${process.env.REACT_APP_API_USER_UPLOADS}/avatar/${user.avatar_path}`}
                        />
                      ) : (
                        <AvatarCircleMedium alt={user.username} />
                      )}
                    </Grid>
                    <Grid item lg={11}>
                      <CommentEditorContainer>
                        <ReactQuill
                          ref={quillRef}
                          modules={modules}
                          value={comment}
                          onChange={handleChange}
                          placeholder="Leave a comment..."
                        />
                      </CommentEditorContainer>
                      <GlobalButtonStyledSmall
                        style={{ marginTop: "1rem" }}
                        disabled={comment === "" || comment === "<p><br></p>"}
                        onClick={handlePostComment}
                      >
                        Post Comment
                      </GlobalButtonStyledSmall>
                    </Grid>
                  </Grid>
                </DiscussionSingleCommentContainer>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <>More Discussions</>
            </Grid>
          </Grid>
        </Container>
      </ThemeSpacing >
    </>
  );
}

export default CommunityDiscussion;
