import styled from "styled-components";

export const UserCardContainer = styled.div`
  border-radius: 1rem;
  background-color: #242424;
  text-align: center;
  padding-bottom: 1rem;
  h3 {
    margin-bottom: 0.25rem;
  }
`;

export const UserCardHero = styled.div`
  background-color: #4c4c4c;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: 100px;
`;

export const UserCardAvatar = styled.div`
  background-color: #121015;
  border: 4px solid #242424;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100px;
  margin: -50px auto 10px auto;
`;

export const UserCardFollowers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 0.9rem;
  }
`;
