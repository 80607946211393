export const validateRegistrationForm = (formData: any, setErrors: any) => {
  let isValid = true;

  if (
    !formData.email &&
    !formData.username &&
    !formData.password &&
    !formData.cPassword
  ) {
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      required: "You need to complete all fields",
    }));
    isValid = false;
  }

  if (formData.password !== formData.cPassword) {
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      cPassword: "Passwords do not match",
    }));
    isValid = false;
  }

  if (!formData.email || !formData.email.includes("@")) {
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      email: "Invalid email address",
    }));
    isValid = false;
  }

  if (!formData.username) {
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      username: "Username is required",
    }));
    isValid = false;
  }

  if (!formData.password) {
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      password: "Password is required",
    }));
    isValid = false;
  }

  return isValid;
};

export const validateLoginForm = (formData: any, setErrors: any) => {
  let isValid = true;

  if (!formData.username) {
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      username: "Username is required",
    }));
    isValid = false;
  }

  if (!formData.password) {
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      password: "Password is required",
    }));
    isValid = false;
  }

  return isValid;
};
