import React from "react";
import {
  FooterContainer,
  FooterCopyright,
  FooterInner,
  FooterLinkHeader,
  FooterLinks,
} from "./styles/footerStyles";
import { Grid } from "@mui/material";
import logo from "../../assets/Logo-White-Transparent.png";

function Footer() {
  return (
    <FooterContainer>
      <FooterInner>
        <div>
          <img src={logo} alt="Reaktor Arcade" width="200px" />
          <p>
            Reaktor Arcade is a Web 3.0 platform developed by uNhumanly LLC for
            play-to-earn games.
          </p>
        </div>
        <div>
          <FooterLinkHeader>Pages</FooterLinkHeader>
          <FooterLinks>
            <ul>
              <li>
                <a href="/games">Games</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
            </ul>
          </FooterLinks>
        </div>
        <div>
          <FooterLinkHeader>Resources</FooterLinkHeader>
          <FooterLinks>
            <ul>
              <li>
                <a href="/support">Support</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
            </ul>
          </FooterLinks>
        </div>
      </FooterInner>
      <FooterCopyright>
        <p>© 2024 uNhumanly LLC. All rights reserved.</p>
        <p>
          All trademarks are property of their respective owners in the US and
          other countries. VAT included in all prices where applicable.
        </p>
      </FooterCopyright>
    </FooterContainer>
  );
}

export default Footer;
