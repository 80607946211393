import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios, { AxiosRequestConfig } from "axios";
import { Grid } from "@mui/material";
import GamesActionBar from "../GamesActionBar";
import GameHeaderDialog from "../dialog/GameHeaderDialog";
import GameImageDialog from "../dialog/GameImageDialog";
import { GameTypes, PlatformType, GenreType } from "../../../utils/types/GameTypes";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../../../utils/types/ItemTypes";
import FloatingAlert from "../../floatingAlert/FloatingAlert";
import GameThumbnailDialog from "../dialog/GameThumbnailDialog";
import GamePublishDialog from "../dialog/GamePublishDialog";
import GameDeleteDialog from "../dialog/GameDeleteDialog";
import EditDetails from "./EditDetails";
import EditDescription from "./EditDescription";
import EditDesign from "./EditDesign";
import EditRating from "./EditRating";
import EditGameFiles from "./EditGameFiles";
import { GamesHeader } from "../styles/gameStyles";
import EditRequirements from "./EditRequirements";
import EditCommunity from "./EditCommunity";

function EditGame({ user }: any) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [game, setGame] = useState<GameTypes>({} as GameTypes);
  const [community, setCommunity] = useState({} as any);
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState(0);
  const [openGameHeaderDialog, setOpenGameHeaderDialog] = useState(false);
  const [openGameThumbnailDialog, setOpenGameThumbnailDialog] = useState(false);
  const [openGameImageDialog, setOpenGameImageDialog] = useState(false);
  const [gameImages, setGameImages] = useState([]);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [gameUploadPercentage, setGameUploadPercentage] = useState(0);
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [statusChanged, setStatusChanged] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [tab, setTab] = useState("");
  const [communityEnabled, setCommunityEnabled] = useState(false);

  const handleImageDrop = ({ id, index }: any) => {
    const updatedGameImages = [...gameImages];
    const [draggedItem] = updatedGameImages.splice(index, 1);
    updatedGameImages.splice(index, 0, draggedItem);
    setGameImages(updatedGameImages);
  };

  const [, drop] = useDrop({
    accept: ItemTypes.GAME_IMAGE,
    drop: (item: { id: any }) => {
      const updatedGameImages = [...gameImages];
      const draggedItem = updatedGameImages.find(
        (image: any) => image.id === item.id,
      );

      if (draggedItem) {
        const draggedIndex = updatedGameImages.indexOf(draggedItem);
        const droppedIndex = gameImages.indexOf(draggedItem);

        updatedGameImages[draggedIndex] = gameImages[droppedIndex];
        updatedGameImages[droppedIndex] = draggedItem;

        setGameImages(updatedGameImages);
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.GAME_IMAGE,
    item: { id: game.game_id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (user?.username === undefined) {
      navigate("/games");
    } else {
      axios
        .post(`${process.env.REACT_APP_API_HOST}/games/get-game`, { id })
        .then((response) => {
          setGame(response.data);
          if (user.id !== response.data.user_id && user.admin !== 1) {
            navigate("/");
          }
          document.title = `Reaktor Arcade - Edit Game - ${response.data.title}`;
          setSelectedTags(
            response.data.genres ? response.data.genres.split(",") : [],
          );
          setSelectedPlatforms(
            response.data.platforms ? response.data.platforms.split(",") : [],
          );
          axios
            .post(`${process.env.REACT_APP_API_HOST}/games/get-game-images`, {
              game_id: response.data.game_id,
            })
            .then((response) => {
              setGameImages(response.data);
              setStatusChanged(false);
              setLoading(false);
              setImageUploaded(false);
            })
            .catch((error) => {
              console.error("Error fetching game information:", error);
            });
          if (response.data.community_enabled === 1) {
            setCommunityEnabled(true);
            axios
              .post(`${process.env.REACT_APP_API_HOST}/community/get-community`, {
                id: response.data.game_id,
              })
              .then((response) => {
                setCommunity(response.data);
              })
              .catch((error) => {
                console.error("Error fetching community information:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching game information:", error);
        });
    }
  }, [user, navigate, id, imageUploaded, statusChanged]);

  const handleSelection = (idx: number) => {
    setSelection(idx);
  };

  const handleSaveAndNext = async (idx: number) => {
    try {
      if (game.genres) {
        if (typeof game.genres === "string") {
          game.genres = game.genres.split(",");
        } else {
          console.error(
            "Expected game.tags to be a string but received:",
            typeof game.genres,
          );
        }
      } else {
        game.genres = "";
      }

      if (game.platforms) {
        if (typeof game.platforms === "string") {
          game.platforms = game.platforms.split(",");
        } else {
          console.error(
            "Expected game.tags to be a string but received:",
            typeof game.platforms,
          );
        }
      } else {
        game.platforms = "";
      }

      // Spread the game object and overwrite tags with the split array
      const res = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/update-game`,
        { ...game, genres: game.genres, platforms: game.platforms },
      );

      if (res.status === 200) {
        setShowSuccessAlert(true);
        setAlertMessage("Successfully saved your game");
        if (idx !== 5 && idx !== -1) {
          setSelection(idx + 1);
        }
        setTimeout(() => setShowSuccessAlert(false), 5000);
      }
    } catch (error) {
      console.error("Error during save:", error);
      setShowErrorAlert(true);
      setAlertMessage("An unexpected error occurred while saving your game");
      setTimeout(() => setShowErrorAlert(false), 5000);
    }
  };

  const handlePublish = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/publish-game`,
        { game_id: game.game_id },
      );

      if (res.status === 200) {
        setShowSuccessAlert(true);
        setAlertMessage("Successfully updated the publishing status of your game");
        setTimeout(() => setShowSuccessAlert(false), 3000);
        setStatusChanged(true);
        setOpenPublishDialog(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/delete-game`,
        { game_id: game.game_id, user_id: user.id },
      );

      if (res.status === 200) {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenGameHeaderDialog = () => {
    setOpenGameHeaderDialog(true);
  };

  const handleOpenGameThumbnailDialog = () => {
    setOpenGameThumbnailDialog(true);
  };

  const handleOpenPublishDialog = () => {
    setOpenPublishDialog(true);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleOpenGameImageDialog = (source: any, type: string) => {
    if (source === null) {
      setTab("image");
      setSelectedImage(null);
      setSelectedVideo(null);
    }
    if (source != null && type === "image") {
      setTab("image");
      setSelectedVideo(null);
      setSelectedImage(source);
    }
    if (source != null && type === "video") {
      setTab("video");
      setSelectedImage(null);
      setSelectedVideo(source);
    }
    setOpenGameImageDialog(true);
  };

  const handleDescriptionChange = (value: any) => {
    setGame({ ...game, description: value });
  };

  const handleOverviewChange = (value: any) => {
    setCommunity({ ...community, overview: value });
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | (Event & { target: { value: unknown; name: string } }),
  ) => {
    const { name, value } = e.target;
    setGame((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePlatform = (tag: PlatformType) => {
    const isPlatformSelected = selectedPlatforms.includes(tag.value);

    setSelectedPlatforms((prevSelectedPlatforms: string[]) => {
      const newSelectedPlatforms = isPlatformSelected
        ? prevSelectedPlatforms.filter((t: string) => t !== tag.value)
        : [...prevSelectedPlatforms, tag.value];

      setGame((prevGame) => ({
        ...prevGame,
        platforms: newSelectedPlatforms.join(","),
      }));

      return newSelectedPlatforms;
    });
  };

  const handleTag = (tag: GenreType) => {
    const isTagSelected = selectedTags.includes(tag.value);

    setSelectedTags((prevSelectedTags: string[]) => {
      const newSelectedTags = isTagSelected
        ? prevSelectedTags.filter((t: string) => t !== tag.value)
        : [...prevSelectedTags, tag.value];

      setGame((prevGame) => ({
        ...prevGame,
        genres: newSelectedTags.join(","),
      }));

      return newSelectedTags;
    });
  };

  const handleChangeC = () => {
    setCommunityEnabled(prevData => !prevData);
    axios
      .post(`${process.env.REACT_APP_API_HOST}/community/enable-community`, {
        game_id: game.game_id,
        enabled: communityEnabled ? 0 : 1,
      })
      .then((response) => {
        if (response.status === 200) {
          setShowSuccessAlert(true);
          if (communityEnabled) {
            setAlertMessage("Successfully disabled your community");
          } else {
            setAlertMessage("Successfully enabled your community");
          }
          setTimeout(() => setShowSuccessAlert(false), 3000);
        }
      })
      .catch((error) => {
        console.error("Error saving community settings:", error);
        setShowErrorAlert(true);
        setAlertMessage("An unexpected error occurred while saving your community settings");
        setTimeout(() => setShowErrorAlert(false), 3000);
      });
  }

  const handleUploadGame = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;

    const formData = new FormData();
    formData.append("game_file", file);

    if (game.game_id && game.title) {
      formData.append("game_id", game.game_id);
      formData.append("title", game.title);
    }

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setGameUploadPercentage(percentCompleted);
        }
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_HOST}/games/upload-game`,
        formData,
        config,
      )
      .then((response) => {
        // console.log("File uploaded successfully", response.data);
        // // Handle successful upload here
      })
      .catch((error) => {
        // console.error("Error uploading file", error);
        // // Handle error here
      });
  };

  const handleSaveCommunity = () => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/community/update-community`, {
        game_id: game.game_id,
        overview: community.overview
      })
      .then((response) => {
        if (response.status === 200) {
          setShowSuccessAlert(true);
          setAlertMessage("Successfully saved your community settings");
          setTimeout(() => setShowSuccessAlert(false), 3000);
        }
      })
      .catch((error) => {
        console.error("Error saving community settings:", error);
        setShowErrorAlert(true);
        setAlertMessage("An unexpected error occurred while saving your community settings");
        setTimeout(() => setShowErrorAlert(false), 3000);
      });
  }

  const creationOptions = [
    "Godot Engine",
    "Unity",
    "Unreal Engine",
    "CryEngine",
    "GameMaker Studio",
    "RPG Maker",
    "Phaser",
    "Cocos2d-x",
    "Lumberyard (Amazon)",
    "Source Engine",
    "C#",
    "C++",
    "Java",
    "JavaScript",
    "Python",
    "Swift",
    "Lua",
    "Ruby",
    "TypeScript",
    "Haxe",
  ];

  // Sort the options alphabetically
  const sortedOptions = creationOptions.sort((a, b) => a.localeCompare(b));

  if (loading) return null;

  return (
    <>
      <GamesHeader
        style={{
          backgroundImage: `url('${process.env.REACT_APP_API_GAME_UPLOADS}/${game.header_path}')`,
        }}
      >
        <div
          style={{
            zIndex: 99,
          }}
        >
          <h1>Edit Game</h1>
          <h3 style={{ marginTop: '1rem', marginBottom: 0 }}>{game.title}</h3>
        </div>
      </GamesHeader>
      <div style={{ padding: '2rem' }}>
        {showSuccessAlert && (
          <FloatingAlert severity="success" message={alertMessage} />
        )}
        {showErrorAlert && (
          <FloatingAlert severity="error" message={alertMessage} />
        )}
        <GameHeaderDialog
          game={game}
          open={openGameHeaderDialog}
          setOpen={setOpenGameHeaderDialog}
          setImageUploaded={setImageUploaded}
        />
        <GameThumbnailDialog
          game={game}
          open={openGameThumbnailDialog}
          setOpen={setOpenGameThumbnailDialog}
          setImageUploaded={setImageUploaded}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
        <GameImageDialog
          game={game}
          open={openGameImageDialog}
          setOpen={setOpenGameImageDialog}
          setImageUploaded={setImageUploaded}
          selectedImage={selectedImage}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          setSelectedImage={setSelectedImage}
          editing={true}
          tab={tab}
          setTab={setTab}
        />
        <GamePublishDialog
          game={game}
          open={openPublishDialog}
          setOpen={setOpenPublishDialog}
          handlePublish={handlePublish}
        />
        <GameDeleteDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          handleDelete={handleDelete}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} lg={2}>
            <GamesActionBar
              game={game}
              handleSaveAndNext={handleSaveAndNext}
              handleSelection={handleSelection}
              handleOpenPublishDialog={handleOpenPublishDialog}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
            />
          </Grid>
          {selection === 0 && (
            <EditDetails
              game={game}
              handleChange={handleChange}
              sortedOptions={sortedOptions}
              handleSaveAndNext={handleSaveAndNext}
            />
          )}
          {selection === 1 && (
            <EditDescription
              game={game}
              handleDescriptionChange={handleDescriptionChange}
              handleSaveAndNext={handleSaveAndNext}
              handlePlatform={handlePlatform}
              selectedPlatforms={selectedPlatforms}
              handleTag={handleTag}
              selectedTags={selectedTags}
            />
          )}
          {selection === 2 && (
            <EditDesign
              game={game}
              gameImages={gameImages}
              handleOpenGameHeaderDialog={handleOpenGameHeaderDialog}
              drop={drop}
              drag={drag}
              handleOpenGameImageDialog={handleOpenGameImageDialog}
              handleOpenGameThumbnailDialog={handleOpenGameThumbnailDialog}
              handleSaveAndNext={handleSaveAndNext}
            />
          )}
          {selection === 3 && (
            <EditRating
              game={game}
              handleChange={handleChange}
              handleSaveAndNext={handleSaveAndNext}
            />
          )}
          {selection === 4 && (
            <EditRequirements
              game={game}
              handleChange={handleChange}
              handleSaveAndNext={handleSaveAndNext}
            />
          )}
          {selection === 5 && (
            <EditGameFiles
              game={game}
              gameUploadPercentage={gameUploadPercentage}
              handleUploadGame={handleUploadGame}
              handleSaveAndNext={handleSaveAndNext}
              handleChange={handleChange}
            />
          )}
          {selection === 6 && (
            <EditCommunity
              game={game}
              community={community}
              handleChangeC={handleChangeC}
              handleOverviewChange={handleOverviewChange}
              handleSaveCommunity={handleSaveCommunity}
              communityEnabled={communityEnabled}
            />
          )}
        </Grid>
      </div>
    </>
  );
}

export default EditGame;
