import axios from "axios";

export const fetchUser = async (token: undefined | string) => {
  if (!token) return;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/auth/user`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status === 200) {
      const user = response.data;
      return user;
    } else {
      console.error("Error fetching user information:", response.statusText);
      return null;
    }
  } catch (error) {
    console.error("Error during user information fetch:", error);
    return null;
  }
};
