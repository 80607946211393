import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";

export const AddGameContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
`;

export const AddGameSelector = styled.div`
  background-color: #4c4c4c;
  padding: 1rem;
  border-radius: 0.25rem;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const LibrarySelectionContainer = styled.div`
  height: 160px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  background-color: #4c4c4c;
  padding: 2rem;
  border-radius: 0.25rem;
  h3 {
    text-shadow: 4px 4px 4px #191919;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  h5 {
    text-shadow: 4px 4px 4px #191919;
    margin: 0;
  }
`;

export const LibrarySelectionInfo = styled.div`
  margin-top: 1rem;
  text-align: center;
  h3, h5 {
    margin: 0;
  }
`;

export const GamesActions = styled.div`
  width: 100%;
  ul {
    list-style: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    li {
      transition: 0.2s;
      text-align: center;
      font-weight: 500;
      padding: 1rem;
      color: #fff;
      background: rgb(76, 76, 76);
      background: linear-gradient(
        180deg,
        rgba(76, 76, 76, 1) 0%,
        rgba(25, 25, 25, 1) 100%
      );
      border-radius: 0.25rem;
      margin-bottom: 1rem;
      &:hover {
        cursor: pointer;
        // color: ${(props) => props.theme.colors.orange};
      }
    }
  }
`;

export const GameHeaderUploadContainer = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #4c4c4c;
  border-radius: 0.25rem;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
  }
  span {
    font-weight: bold;
  }
`;

export const GameImageUploadContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  padding: 1rem;
  background-color: #4c4c4c;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GameImageContainer = styled.div`
  margin-top: 2rem;
  overflow-x: scroll;
  white-space: nowrap;

  /* For WebKit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-track {
    background: none;
    border-radius: 1rem;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #555 #ddd;

  /* For Microsoft Edge */
  -ms-overflow-style: none; /* Hide scrollbar in Microsoft Edge */
`;

export const GameImage = styled.div`
  border-radius: 0.25rem;
  width: 260px;
  height: 160px;
  background-color: #4c4c4c;
  background-position: center;
  background-size: cover;
  display: inline-block;
  &:not(:last-child) {
    margin-right: 1rem;
  }
  &:hover {
    cursor: pointer;
  }
  img {
    border-radius: 0.25rem;
  }
`;

export const GameImageUploadButton = styled.div`
  border-radius: 0.25rem;
  border: 2px dashed #fff;
  height: 140px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-right: 2rem;
  &:hover {
    cursor: pointer;
  }
  span {
    font-weight: bold;
  }
`;

export const GameThumbnailUploadButton = styled.div`
  background-size: cover;
  background-position: center;
  background-color: #4c4c4c;
  border-radius: 0.25rem;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  span {
    font-weight: bold;
  }
`;

export const GameHeaderInfoContainer = styled.div`
  display: flex;
  align-items: center;
  h1 {
    margin-top: -6rem;
    margin-bottom: 0;
  }
  @media screen and (max-width: 1024px) {
    display: inline-block;
  }
`;

export const GameHeader = styled.div`
  background-position: center;
  background-size: 100%;
  height: 500px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin: -16px -1rem 0 -1rem;
  @media screen and (max-width: 1024px) {
    height: 200px;
  }
`;

export const GameActionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    display: inline-block;
    height: auto;
    width: 100%;
  }
`;

export const GameActionButtons = styled.div`
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 1rem;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

export const GameHeaderInfoActionItems = styled.div`
  position: absolute;
  left: -2.5rem;
  ul {
    list-style: none;
    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      div {
        display: flex;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    position: initial;
    ul {
      padding-inline-start: 0;
    }
  }
`;

export const GameLikeButton = styled(FontAwesomeIcon)`
  font-size: 1.2rem;
  &:hover {
    cursor: pointer;
  }
`;

export const GameLikeButtonClicked = styled(FontAwesomeIcon)`
  color: limegreen;
  font-size: 1.2rem;
  &:hover {
    cursor: pointer;
  }
`;

export const GameDescription = styled.div`
  p {
    margin-top: 0;
  }
  img {
    margin: 0 1rem;
    max-width: 95%;
  }
`;

export const GameItem = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    height: auto;
    display: flex;
    flex-direction: row;
  }
`;

export const GameItemInner = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 1024px) {
    display: inline-block;
  }
`;

export const GameImg = styled.div`
  width: 200px;
  min-height: 120px;
  border-radius: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    background-position: left;
  }
`;

export const GameMiniInfo = styled.div`
  width: 100%;
  margin-left: 1rem;
  h4 {
    margin: 0 0 0.25rem 0;
  }
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    h4 {
      margin: 1rem 0;
    }
  }
`;

export const FilterBarContainer = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  position: absolute;
  width: 200px;
  background-color: ${(props) => props.theme.backgrounds.primary};
  top: 100px;
  left: -208px;
  padding: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  ul {
    padding-inline-start: 0;
    list-style: none;
    li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const DialogTabContainer = styled.div`
  margin-bottom: 1.5rem;
  span {
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const GameTagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  code {
    font-size: 0.8rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const GameUploadContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GamePageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    margin: 0 0 1rem 0;
  }
  @media screen and (max-width: 1024px) {
    display: inline-block;
  }
`;

export const GameHeaderThumbnail = styled.div`
  margin-top: -2.5rem;
  margin-right: 2rem;
  background-size: contain;
  width: 90px;
  height: 90px;
  background-repeat: no-repeat;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const FollowersGrid = styled(Grid)`
  max-width: 1200px;
  margin: -30px auto 2rem auto;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0 !important;
  }
`;

export const GamesHeader = styled.div`
  position: relative;
  margin-top: -88px;
  height: 300px;
  background-attachment: fixed;
  background-size: 100%;
  max-width: 1920px;
  background-position: top;
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat;
  padding: 2rem;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 400px;
    background: linear-gradient(to top, #191919, rgba(255, 255, 255, 0));
    pointer-events: none;
  }
  h1 {
    z-index: 90;
    margin: 0;
  }
  @media screen and (max-width: 1024px) {
    height: 160px;
  }
`;

export const CategoryContainer = styled.div`
  height: 160px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  border-radius: 0.25rem;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  span {
    text-shadow: 2px 2px 2px #4c4c4c;
    padding-bottom: 1rem;
    font-weight: bold;
    font-size: 1.4rem;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const GameThumbnail = styled.div`
  position: relative;
  padding: 1rem;
  height: 260px;
  border-radius: 0.25rem;
  background-color: #4c4c4c;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  text-shadow: 4px 4px 4px #191919;
`;

export const GameImageFullscreen = styled.div`
  padding: 4rem;
  display: flex;
  flex-direction: row; /* Align children horizontally */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent scrolling and ensure all elements are visible */
  position: relative; /* Necessary for absolute positioning of controls */
  img {
    height: 80vh;
    object-fit: contain;
    width: auto;
    max-width: 100%; /* Ensure image does not exceed its container */
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const GameImageControls = styled.div`
  display: flex;
  position: absolute;
  top: 50%; /* Center vertically relative to the parent */
  width: 100%; /* Same width as the parent */
  justify-content: space-between; /* Place one icon at each end */
  pointer-events: none; /* Prevent the controls container from blocking clicks to the image */

  svg {
    padding: 0rem 3rem;
    font-size: 2.5rem;
    pointer-events: all; /* Enable clicks on the icons */
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 1024px) {
      padding: 0rem;
    }
  }
`;

export const ResponsiveYoutubeEmbed = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  svg {
    padding: 0rem 3rem;
    font-size: 2.5rem;
    pointer-events: all; /* Enable clicks on the icons */
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 1024px) {
      padding: 0rem;
    }
  }
`;