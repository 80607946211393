import React from "react";
import {
  GlobalButtonStyled,
  GlobalCode,
  GlobalSelect,
  GlobalTextField,
  InputLabel,
  Small,
} from "../../../utils/globalStyles";
import { Grid, InputAdornment, MenuItem } from "@mui/material";
import { parseTitle } from "../../../utils/helpers/parseTitle";

function EditDetails({
  game,
  handleChange,
  sortedOptions,
  handleSaveAndNext,
}: any) {
  return (
    <Grid item xs={12} lg={6}>
      <InputLabel>Game Status</InputLabel>
      <GlobalSelect
        defaultValue={game.stage}
        name="stage"
        value={game.stage}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        <MenuItem value="Devlog">Devlog</MenuItem>
        <MenuItem value="Early Access">Early Access</MenuItem>
        <MenuItem value="Stable">Stable</MenuItem>
      </GlobalSelect>
      <InputLabel>Title</InputLabel>
      <GlobalTextField
        type="text"
        placeholder="Title"
        name="title"
        value={game.title}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <InputLabel>Game Path</InputLabel>
      <GlobalTextField
        disabled
        type="text"
        placeholder="Game Path"
        name="game_path"
        value={game.game_path}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <Small>
        <GlobalCode>
          {game.game_path
            ? `reaktorarcade.com/games/${parseTitle(
                game.game_path,
              )}/${game.game_id}`
            : `reaktorarcade.com/games/_/${game.game_id}`}
        </GlobalCode>
      </Small>
      <InputLabel>Website</InputLabel>
      <GlobalTextField
        type="text"
        placeholder="Website"
        name="website"
        value={game.website}
        onChange={(e) => {
          handleChange(e);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">https://</InputAdornment>
          ),
        }}
      />
      <InputLabel>Store Link</InputLabel>
      <GlobalTextField
        type="text"
        placeholder="Store"
        name="store"
        value={game.website}
        onChange={(e) => {
          handleChange(e);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">https://</InputAdornment>
          ),
        }}
      />
      <Small>If your game has a store, you can link to it above.</Small>
      <InputLabel>Engine/Language/Creation Tool</InputLabel>
      <GlobalSelect
        defaultValue="select"
        name="game_engine"
        value={game.game_engine}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        <MenuItem value="select">Select Engine/Language/Creation Tool</MenuItem>
        {sortedOptions.map((option: any, index: any) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
        <MenuItem value="other">Other</MenuItem>
      </GlobalSelect>
      <GlobalButtonStyled
        style={{ marginTop: "1rem" }}
        onClick={() => {
          handleSaveAndNext(0);
        }}
      >
        Save & Next
      </GlobalButtonStyled>
    </Grid>
  );
}

export default EditDetails;
