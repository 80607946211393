import React from "react";
import { Grid } from "@mui/material";
import {
  GlobalButtonStyled,
  GlobalTextField,
  InputLabel,
  Small,
} from "../../../utils/globalStyles";

function EditRequirements({
  game,
  handleChange,
  handleSaveAndNext,
}: any) {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <Grid container spacing={3} style={{ marginBottom: '1rem' }}>
          <Grid item lg={6} xs={12}>
            <h3>
              Minimum System Requirements
            </h3>
            <InputLabel>Operating System</InputLabel>
            <GlobalTextField
              type="text"
              name="minimum_operating_system"
              value={game.minimum_operating_system || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: Windows 7, 8.1, 10 (64-bit required)</Small>
            <InputLabel>Processor</InputLabel>
            <GlobalTextField
              type="text"
              name="minimum_processor"
              value={game.minimum_processor || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: Intel Core i5-4460 / AMD FX-6300</Small>
            <InputLabel>Memory</InputLabel>
            <GlobalTextField
              type="text"
              name="minimum_memory"
              value={game.minimum_memory || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: 8 GB RAM</Small>
            <InputLabel>Graphics</InputLabel>
            <GlobalTextField
              type="text"
              name="minimum_graphics"
              value={game.minimum_graphics || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: NVIDIA GeForce GTX 760 / AMD Radeon R7 260x with 2GB Video RAM</Small>
            <InputLabel>DirectX</InputLabel>
            <GlobalTextField
              type="text"
              name="minimum_directx"
              value={game.minimum_directx || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: Version 11</Small>
            <InputLabel>Storage</InputLabel>
            <GlobalTextField
              type="text"
              name="minimum_storage"
              value={game.minimum_storage || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: 15 GB available space</Small>
            <InputLabel>Additional Notes (Optional)</InputLabel>
            <GlobalTextField
              type="text"
              name="minimum_notes"
              value={game.minimum_notes || ""}
              onChange={ (e) => { handleChange(e) }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <h3>
              Recommended System Requirements
            </h3>
          <InputLabel>Operating System</InputLabel>
            <GlobalTextField
              type="text"
              name="recommended_operating_system"
              value={game.recommended_operating_system || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: Windows 10 64-bit</Small>
            <InputLabel>Processor</InputLabel>
            <GlobalTextField
              type="text"
              name="recommended_processor"
              value={game.recommended_processor || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: Intel Core i5-10500 / AMD Ryzen 5 2600</Small>
            <InputLabel>Memory</InputLabel>
            <GlobalTextField
              type="text"
              name="recommended_memory"
              value={game.recommended_memory || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: 16 GB RAM</Small>
            <InputLabel>Graphics</InputLabel>
            <GlobalTextField
              type="text"
              name="recommended_graphics"
              value={game.recommended_graphics || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: NVIDIA GeForce GTX 970 / AMD Radeon R9 290 or better</Small>
            <InputLabel>DirectX</InputLabel>
            <GlobalTextField
              type="text"
              name="recommended_directx"
              value={game.recommended_directx || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: Version 12</Small>
            <InputLabel>Storage</InputLabel>
            <GlobalTextField
              type="text"
              name="recommended_storage"
              value={game.recommended_storage || ""}
              onChange={ (e) => { handleChange(e) }}
            />
            <Small>Example: 30 GB available space</Small>
            <InputLabel>Additional Notes (Optional)</InputLabel>
            <GlobalTextField
              type="text"
              name="recommended_notes"
              value={game.recommended_notes || ""}
              onChange={ (e) => { handleChange(e) }}
            />
          </Grid>
        </Grid>
        <GlobalButtonStyled
          onClick={() => {
            handleSaveAndNext(4);
          }}
        >
          Save & Next
        </GlobalButtonStyled>
      </Grid>
    </>
  );
}

export default EditRequirements;
