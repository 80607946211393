import React from "react";
import { Grid } from "@mui/material";
import {
  GlobalButtonStyled,
  GlobalCodeDark,
  InputLabel,
  Small,
} from "../../../utils/globalStyles";
import Editor from "../../editor/Editor";
import { GameTagsContainer } from "../styles/gameStyles";
import { gamePlatforms } from "../../../utils/gamePlatforms";
import { gameTags } from "../../../utils/gameTags";

function EditDescription({
  game,
  handleDescriptionChange,
  handleSaveAndNext,
  handlePlatform,
  selectedPlatforms,
  handleTag,
  selectedTags,
}: any) {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <InputLabel>Description</InputLabel>
        <Editor game={game} handleDescriptionChange={handleDescriptionChange} />
        <GlobalButtonStyled
          style={{ marginTop: "1rem" }}
          onClick={() => {
            handleSaveAndNext(1);
          }}
        >
          Save & Next
        </GlobalButtonStyled>
      </Grid>
      <Grid item xs={12} lg={4}>
        <h3>Writing a Good Description</h3>
        <Small>
          A good description generally contains things like a gameplay summary,
          control overview, story, credits, etc. You can edit your description
          whenever you want and add to it over time.
        </Small>
        <Small>
          You can also upload images to use as in-context shots for your game or
          for stylized headings.
        </Small>
        <h3>Game Platforms</h3>
        <Small>
          Choose the platform your game supports to increase its visibility. It's recommended to include
          at least one of the listed platforms. Simply click on a platform to select it.
        </Small>
        <GameTagsContainer style={{ marginBottom: "1rem" }}>
          {gamePlatforms.map((platform) => (
            <GlobalCodeDark
              key={platform.value}
              onClick={() => handlePlatform(platform)}
              className={selectedPlatforms.includes(platform.value) ? "selected" : ""}
            >
              {platform.name}
            </GlobalCodeDark>
          ))}
        </GameTagsContainer>
        <h3>Game Genres</h3>
        <Small>
          Choose your game genres to increase its visibility. It's recommended to include
          at least one of the listed genres. Simply click on a genre to select it.
        </Small>
        <GameTagsContainer>
          {gameTags.map((tag) => (
            <GlobalCodeDark
              key={tag.value}
              onClick={() => handleTag(tag)}
              className={selectedTags.includes(tag.value) ? "selected" : ""}
            >
              {tag.name}
            </GlobalCodeDark>
          ))}
        </GameTagsContainer>
      </Grid>
    </>
  );
}

export default EditDescription;
