import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "react-image-crop/dist/ReactCrop.css";
import {
  GlobalButtonStyled,
  GlobalButtonStyledDanger,
  GlobalDialog,
  GlobalDialogContainer,
} from "../../../utils/globalStyles";
import { Transition } from "../../dialog/Transition";

function GameDeleteDialog({ game, open, setOpen, handleDelete }: any) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <GlobalDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <GlobalDialogContainer>
          <DialogTitle>Delete Game</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete your game?</p>
            <p>WARNING: THIS ACTION CANNOT BE UNDONE!</p>
            <GlobalButtonStyledDanger
              onClick={() => {
                handleDelete();
              }}
            >
              Delete Game
            </GlobalButtonStyledDanger>
          </DialogContent>
        </GlobalDialogContainer>
      </GlobalDialog>
    </>
  );
}

export default GameDeleteDialog;
