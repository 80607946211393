import React from "react";
import {
  GameHeaderUploadContainer,
  GameImage,
  GameImageContainer,
  GameImageUploadButton,
  GameImageUploadContainer,
  GameThumbnailUploadButton,
  ResponsiveYoutubeEmbed,
} from "../styles/gameStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { Grid } from "@mui/material";
import {
  GlobalButtonStyled,
  InputLabel,
  Small,
} from "../../../utils/globalStyles";

function EditDesign({
  game,
  gameImages,
  handleOpenGameHeaderDialog,
  drop,
  drag,
  handleOpenGameImageDialog,
  handleOpenGameThumbnailDialog,
  handleSaveAndNext,
}: any) {

  return (
    <Grid item xs={12} lg={10}>
      <GameHeaderUploadContainer
        style={
          game.header_path !== null
            ? {
                backgroundImage: `url(${process.env.REACT_APP_API_GAME_UPLOADS}/${game.header_path})`,
              }
            : { backgroundImage: "none" }
        }
        onClick={() => {
          handleOpenGameHeaderDialog();
        }}
      >
        <span>Change Game Header</span>
      </GameHeaderUploadContainer>
      <GameImageUploadContainer>
        <GameImageUploadButton
          onClick={() => {
            handleOpenGameImageDialog(null);
          }}
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{ fontSize: "2rem", marginRight: "0.5rem" }}
          />
          <span>Add Media</span>
        </GameImageUploadButton>
        <GameImageContainer ref={drop} style={{ marginTop: 0 }}>
          {/* @ts-ignore */}
          {gameImages.map((image: any) => (
            image.video_path !== null && (
              <GameImage key={image.id}
                style={{
                  backgroundImage: `url(https://img.youtube.com/vi/${image.video_path}/hqdefault.jpg)`
                }}
                onClick={() => {
                  handleOpenGameImageDialog(image.video_path, "video");
                }}
              />
              )
          ))}
          {/* @ts-ignore */}
          {gameImages.map((image: any) => (
            image.image_path !== null && (
              <GameImage
                key={image.id}
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_API_GAME_UPLOADS}/${image.image_path})`,
                }}
                onClick={() => {
                  handleOpenGameImageDialog(image.image_path, "image");
                }}
              />
              )
          ))}
        </GameImageContainer>
      </GameImageUploadContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <InputLabel>Game Thumbnail</InputLabel>
          <Small>
            A thumbnail is the little rectangular image that represents your
            game throughout the site. People can click it in game listings and
            search results to get to your game page.
          </Small>
          <Small>
            Please don't choose an image that contains nudity, swear words, or
            adult-oriented imagery.
          </Small>
        </Grid>
        <Grid item xs={12} lg={3}>
          <GameThumbnailUploadButton
            style={
              game.thumbnail_path !== null
                ? {
                    backgroundImage: `url(${process.env.REACT_APP_API_GAME_UPLOADS}/${game.thumbnail_path})`,
                  }
                : { backgroundImage: "none" }
            }
            onClick={() => {
              handleOpenGameThumbnailDialog();
            }}
          >
            <span>Change Thumbnail</span>
          </GameThumbnailUploadButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <GlobalButtonStyled
          style={{ marginTop: "1rem" }}
          onClick={() => {
            handleSaveAndNext(2);
          }}
        >
          Next
        </GlobalButtonStyled>
      </Grid>
    </Grid>
  );
}

export default EditDesign;
