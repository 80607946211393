export function maskEmail(email: string) {
  const formatEmail = (email: string) => {
    if (!email || typeof email !== "string") {
      return "";
    }

    const [username, domain] = email.split("@");
    const maskedUsername = `${username.substring(0, 2)}***`;
    const maskedDomain = `@${domain.charAt(0)}***.${domain.split(".").pop()}`;

    return `${maskedUsername}${maskedDomain}`;
  };

  return formatEmail(email);
}
