import styled from "styled-components";

export const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 500px;
`;

export const RegisterInner = styled.div`
  text-align: center;
`;

export const RegisterOuter = styled.div`
  width: 500px;
  margin: 0 auto;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
