import React, { useState, useRef } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  FileInputWrapper,
  GlobalButtonDarkNoWidth,
  GlobalButtonStyled,
  GlobalButtonStyledSmall,
  GlobalDialog,
  GlobalDialogContainer,
  GlobalDialogFullScreen,
  Small,
} from "../../../utils/globalStyles";
import axios from "axios";
import Cookies from "js-cookie";
import { Transition } from "../../dialog/Transition";

function GameHeaderDialog({ game, open, setOpen, setImageUploaded }: any) {
  const token = Cookies.get("token");
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [imageCropped, setImageCropped] = useState(false);
  const [headerSaved, setHeaderSaved] = useState(false);
  const [completedCrop, setCompletedCrop] = useState<any>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const handleClose = () => {
    setOpen(false);
    setImageCropped(false);
    setSelectedFile(undefined);
    setCroppedImageUrl(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg"];

      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);
        setCroppedImageUrl(null);
      } else {
        console.error("Invalid file type. Please select a PNG or JPEG file.");
      }
    }
  };

  const handleRemove = () => {
    setImageCropped(false);
    setSelectedFile(undefined);
    setCroppedImageUrl(null);
  };

  const handleSave = async () => {
    if (!selectedFile) return;

    try {
      const formData = new FormData();
      formData.append("game_id", game.game_id);
      formData.append("game_header", selectedFile);

      // Send the FormData to the server
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/upload-game-header`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response.status === 200) {
        setImageUploaded(true);
        setSelectedFile(undefined);
      } else {
        console.error("Game Header upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading Game Header:", error);
    } finally {
      handleClose();
    }
  };

  const makeClientCrop = async (crop: Crop) => {
    if (
      imgRef.current &&
      crop.width &&
      crop.height &&
      imgRef.current.complete &&
      imgRef.current.naturalWidth &&
      imgRef.current.naturalHeight
    ) {
      const croppedFile = await getCroppedImg(imgRef.current, crop);
      setSelectedFile(croppedFile);
      setImageCropped(true);
    } else {
      console.error("Image not loaded or crop size is not defined");
    }
  };

  const onImageLoaded = () => {
    console.log("Image loaded and ready for cropping.");
  };

  const getCroppedImg = (
    image: HTMLImageElement,
    crop: Crop,
  ): Promise<File> => {
    return new Promise((resolve, reject) => {
      if (!image || !crop.width || !crop.height) {
        reject(new Error("Image or crop size is not defined"));
        return;
      }

      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      // Set canvas size to crop dimensions to avoid unnecessary scaling
      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(new Error("Could not get canvas context"));
        return;
      }

      // Fill the canvas with a transparent background
      ctx.fillStyle = "rgba(0,0,0,0)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the cropped image on the canvas
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height,
      );

      // Convert the canvas to a Blob in PNG format
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        // Create a File object from the blob
        const file = new File([blob], "cropped.png", { type: "image/png" });
        resolve(file);
      }, "image/png");
    });
  };

  return (
    <>
      <GlobalDialogFullScreen
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <GlobalDialogContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <DialogTitle>Change Game Header</DialogTitle>
            <GlobalButtonStyledSmall
              style={{ marginRight: "1.25rem" }}
              onClick={() => {
                handleClose();
              }}
            >
              Close Dialog
            </GlobalButtonStyledSmall>
          </div>
          <DialogContent>
            <Small>
              Headers are the big, banner-like images that adorn the tops of
              pages. For your header to look its best on all devices, make sure
              anything important is located near the center of the image.
            </Small>
            <Small>Your image must be a PNG or JPG.</Small>
            <Small>
              PNGs are highly recommended as they produce a lossless image.
            </Small>
            <Small style={{ marginBottom: "1rem" }}>
              The recommended size for a header image is 2000×500 (ratio of 4 ÷
              1).
            </Small>
            {!selectedFile && (
              <FileInputWrapper>
                <input type="file" onChange={handleFileChange} />
              </FileInputWrapper>
            )}
            {selectedFile && (
              <div>
                {croppedImageUrl ? (
                  <img
                    src={croppedImageUrl}
                    alt="Cropped File"
                    style={{ width: "100%", maxWidth: "2000px" }}
                  />
                ) : (
                  <>
                    {!imageCropped ? (
                      <ReactCrop
                        crop={crop}
                        onChange={(newCrop) => {
                          setCrop(newCrop);
                        }}
                        minWidth={2000}
                        minHeight={500}
                        maxWidth={2000}
                        maxHeight={500}
                        ruleOfThirds
                      >
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Crop preview"
                          onLoad={onImageLoaded}
                          ref={imgRef}
                          style={{ width: "100%", maxWidth: "2000px" }}
                        />
                      </ReactCrop>
                    ) : (
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Crop preview"
                        onLoad={onImageLoaded}
                        ref={imgRef}
                        style={{ width: "100%", maxWidth: "2000px" }}
                      />
                    )}
                  </>
                )}
                <div style={{ marginTop: "1rem" }}>
                  {!imageCropped && (
                    <GlobalButtonStyledSmall
                      onClick={() => {
                        makeClientCrop(crop).then(() => {
                          setImageCropped(true);
                        });
                      }}
                    >
                      Complete Crop
                    </GlobalButtonStyledSmall>
                  )}
                  {imageCropped && (
                    <GlobalButtonStyledSmall
                      style={{ marginLeft: "0.5rem" }}
                      onClick={() => {
                        handleSave().then(() => {
                          setHeaderSaved(true);
                        });
                      }}
                    >
                      Save
                    </GlobalButtonStyledSmall>
                  )}
                  <GlobalButtonStyledSmall
                    onClick={() => {
                      handleRemove();
                    }}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    Remove Game Header
                  </GlobalButtonStyledSmall>
                </div>
              </div>
            )}
          </DialogContent>
        </GlobalDialogContainer>
      </GlobalDialogFullScreen>
    </>
  );
}

export default GameHeaderDialog;
