import React, { useState, useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { isError, parseUnits } from "ethers";
import { Contract } from "@ethersproject/contracts";
import {
  GlobalButtonStyled,
  GlobalButtonStyledDark,
  GlobalCode,
} from "../../utils/globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons/faWallet";
import { RKR_ABI } from "../../utils/helpers/RKR_ABI";
import axios from "axios";

declare global {
  interface Window {
    ethereum: any;
  }
}

interface CryptoPaymentProps {
  totalAmount: number;
  onSuccess: any;
}

const CryptoCheckoutButton: React.FC<CryptoPaymentProps> = ({
  totalAmount,
  onSuccess,
}) => {
  const [userAccount, setUserAccount] = useState<string | null>(null);
  const [walletConnected, setWalletConnected] = useState(false);
  const [provider, setProvider] = useState<Web3Provider | null>(null);
  const [metaMaskInstalled, setMetaMaskInstalled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const connectWalletHandler = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setUserAccount(accounts[0]);
        setMetaMaskInstalled(true);
        const newProvider = new Web3Provider(window.ethereum);
        setProvider(newProvider);
      } catch (error) {
        console.error("Error connecting to MetaMask:", error);
      }
    } else {
      setMetaMaskInstalled(false);
    }
  };

  const RKR_CONTRACT_ADDRESS = `${process.env.REACT_APP_RKR_CONTRACT}`;

  const sendPaymentHandler = async () => {
    setErrorMessage(null);

    if (!provider) {
      setErrorMessage("No Ethereum provider found. Please install MetaMask.");
      return;
    }

    const signer = provider.getSigner();
    const rkrContract = new Contract(RKR_CONTRACT_ADDRESS, RKR_ABI, signer);

    const getExchangeRate = await axios.get(
      `${process.env.REACT_APP_API_HOST}/rkr/retrieve-exchange-rate`,
    );

    const exchangeRate = 1 / getExchangeRate.data.usd_rate;

    let rkrAmount = (totalAmount * exchangeRate).toFixed(9);

    const amountToTransfer = parseUnits(rkrAmount, 9);

    try {
      const transactionResponse = await rkrContract.transfer(
        process.env.REACT_APP_CRYPTO_WALLET,
        amountToTransfer,
      );
      await transactionResponse.wait();

      // TODO, confirm RKR amount, check for receipt

      // TODO: ADD GAME TO USER LIBRARY & send receipt to DB

      sessionStorage.removeItem("cart");
      onSuccess(transactionResponse);
    } catch (error) {
      if (isError(error, "INSUFFICIENT_FUNDS")) {
        setErrorMessage(
          "Insufficient funds: You do not have enough RKR to complete this transaction.",
        );
      } else if (error instanceof Error) {
        setErrorMessage("An error occurred: " + error.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };

  useEffect(() => {
    connectWalletHandler().then(() => setWalletConnected(true));
  }, []);

  return (
    <div>
      {userAccount ? (
        <div>
          <p style={{ marginTop: "1rem" }}>
            <FontAwesomeIcon icon={faWallet} />{" "}
            <GlobalCode>{userAccount}</GlobalCode>
          </p>
          <GlobalButtonStyled onClick={sendPaymentHandler}>
            Pay with RKR
          </GlobalButtonStyled>
        </div>
      ) : (
        <>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          {!metaMaskInstalled && !walletConnected ? (
            <p style={{ marginTop: "1rem", color: "red", textAlign: "center" }}>
              MetaMask is not installed or your wallet is not connected. Please
              install MetaMask to use this feature, or connect your wallet.
            </p>
          ) : (
            <GlobalButtonStyledDark onClick={connectWalletHandler}>
              Connect to MetaMask
            </GlobalButtonStyledDark>
          )}
        </>
      )}
    </div>
  );
};

export default CryptoCheckoutButton;
