import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 500px;
`;

export const LoginInner = styled.div`
  text-align: center;
  width: 100%;
`;

export const LoginOuter = styled.div`
  width: 500px;
  margin: 0 auto;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
