import React, { useEffect, useState } from "react";
import {
  FullWidthContainer,
  GlobalAlert,
  GlobalButtonStyled,
  GlobalButtonStyledSmall,
  GlobalCode,
  GlobalSelectDark,
  GlobalTextField,
  ThemeSpacing,
} from "../../utils/globalStyles";
import {
  Alert,
  Grid,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { AddGameContainer, AddGameSelector } from "./styles/gameStyles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { parseTitle } from "../../utils/helpers/parseTitle";

function AddGame({ user }: any) {
  const navigate = useNavigate();
  const [selectedStage, setSelectedStage] = useState(null);
  const [gameData, setGameData] = useState({
    user_id: user?.id,
    stage: "",
    title: "",
    urlPath: "",
    website: "",
    engineLanguage: "",
  });
  const [errors, setErrors] = useState("");

  useEffect(() => {
    if (user?.username === undefined) {
      navigate("/games");
    }
    document.title = `Reaktor Arcade - Add a Game`;
  }, []);

  const handleStageSelect = (stage: any) => {
    setSelectedStage(stage);
    setGameData((prevData) => ({ ...prevData, stage }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setGameData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    setGameData((prevData) => ({ ...prevData, engineLanguage: value }));
  };

  const validateFields = () => {
    if (
      gameData.title === "" ||
      gameData.urlPath === "" ||
      gameData.stage === "" ||
      gameData.engineLanguage === ""
    ) {
      setErrors("You need to complete all required fields");
      return false;
    } else {
      return true;
    }
  };

  const handleSaveAndNext = async () => {
    if (!validateFields()) {
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/add-game`,
        gameData,
      );
      if (response.status === 201 || response.status === 200) {
        // Adjust based on actual success status code
        setErrors(""); // Assume errors is a string for simplicity
        const gameUrl = `/games/edit/${response.data.game_id}`;
        navigate(gameUrl);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        // More specific error handling based on the backend response
        if (
          error.response.status === 409 &&
          error.response.data.error === "DUP_ENTRY"
        ) {
          setErrors("A game with this path already exists.");
        } else {
          // Generic error message for other server-side errors
          setErrors("An unexpected error occurred while saving the game.");
        }
      } else {
        console.error("An unexpected error occurred:", error);
        setErrors("An error occurred. Please try again.");
      }
    }
  };

  const creationOptions = [
    "Godot Engine",
    "Unity",
    "Unreal Engine",
    "CryEngine",
    "GameMaker Studio",
    "RPG Maker",
    "Phaser",
    "Cocos2d-x",
    "Lumberyard (Amazon)",
    "Source Engine",
    "C#",
    "C++",
    "Java",
    "JavaScript",
    "Python",
    "Swift",
    "Lua",
    "Ruby",
    "TypeScript",
    "Haxe",
  ];

  // Sort the options alphabetically
  const sortedOptions = creationOptions.sort((a, b) => a.localeCompare(b));

  return (
    <ThemeSpacing>
      <FullWidthContainer>
        <Grid container>
          <Grid item xs={12}>
            {!selectedStage && (
              <>
                <AddGameContainer>
                  <h1 style={{ textAlign: "center" }}>Add a Game</h1>
                  <p>
                    Choose the stage of development that your game is currently
                    in. You are able to change your development stage at any
                    point.
                  </p>
                  <AddGameSelector onClick={() => handleStageSelect("Devlog")}>
                    <h4>Devlog Only</h4>
                    <p>
                      You don't have anything playable yet, but would like to
                      share active game development in the form of images,
                      videos, posts and more.
                    </p>
                  </AddGameSelector>
                  <AddGameSelector
                    onClick={() => handleStageSelect("Early Access")}
                  >
                    <h4>Early Access</h4>
                    <p>
                      Your game has playable builds, but you're still actively
                      developing it.
                    </p>
                  </AddGameSelector>
                  <AddGameSelector onClick={() => handleStageSelect("Stable")}>
                    <h4>Stable</h4>
                    <p>
                      Your game is complete. It's in a stable state and you only
                      plan on making bug fixes, performance optimizations, or
                      small improvements.
                    </p>
                  </AddGameSelector>
                </AddGameContainer>
              </>
            )}
            {selectedStage && (
              <div style={{ maxWidth: "600px", margin: "0 auto" }}>
                <h1 style={{ textAlign: "center" }}>Add a Game</h1>
                {errors && (
                  <GlobalAlert
                    severity="error"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <p style={{ margin: 0 }}>{errors}</p>
                  </GlobalAlert>
                )}
                <Alert severity="info" style={{ marginBottom: "0.5rem" }}>You've selected <strong>{selectedStage}</strong> as your game status. Not correct? <strong><a href="/games/add" style={{ color: "rgb(1, 67, 97)" }}>Change status</a></strong>.</Alert>
                <GlobalTextField
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={gameData.title}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                <GlobalTextField
                  type="text"
                  placeholder="Game Path"
                  name="urlPath"
                  value={gameData.urlPath}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                <p style={{ marginTop: 0 }}>
                  Customize your game page URL. Make them really pretty.
                </p>
                <p>
                  Game Page URL{" "}
                  <GlobalCode>
                    {gameData.urlPath
                      ? `reaktorarcade.com/games/${parseTitle(
                          gameData.urlPath,
                        )}/id`
                      : "reaktorarcade.com/games/_/id"}
                  </GlobalCode>
                </p>
                <GlobalTextField
                  type="url"
                  placeholder="Website (optional)"
                  name="website"
                  value={gameData.website}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">https://</InputAdornment>
                    ),
                  }}
                />
                <GlobalSelectDark
                  defaultValue="select"
                  name="engineLanguage"
                  onChange={(e) => {
                    handleSelectChange(e);
                  }}
                >
                  <MenuItem value="select">
                    Select Engine/Language/Creation Tool
                  </MenuItem>
                  {sortedOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem value="other">Other</MenuItem>
                </GlobalSelectDark>
                <GlobalButtonStyled
                  onClick={() => {
                    handleSaveAndNext();
                  }}
                >
                  Save & Next
                </GlobalButtonStyled>
              </div>
            )}
          </Grid>
        </Grid>
      </FullWidthContainer>
    </ThemeSpacing>
  );
}

export default AddGame;
