import React from "react";
import { FormControl, FormControlLabel, Grid, RadioGroup } from "@mui/material";
import {
  GlobalButtonStyled,
  GlobalRadio,
  InputLabel,
  Small,
} from "../../../utils/globalStyles";

function EditRating({ game, handleChange, handleSaveAndNext }: any) {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <InputLabel>Game Rating</InputLabel>
        <FormControl>
          <RadioGroup
            name="rating"
            value={game.rating}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            <FormControlLabel
              value="all"
              control={<GlobalRadio />}
              label="All Ages"
            />
            <FormControlLabel
              value="teen"
              control={<GlobalRadio />}
              label="Teen Content"
            />
            <FormControlLabel
              value="mature"
              control={<GlobalRadio />}
              label="Mature Content"
            />
          </RadioGroup>
          <GlobalButtonStyled
            style={{ marginTop: "1rem" }}
            onClick={() => {
              handleSaveAndNext(3);
            }}
          >
            Save & Next
          </GlobalButtonStyled>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={3}>
        <h3>Maturity</h3>
        <Small>
          Every game, even works-in-progress, needs a maturity rating to
          describe its content. Accurate ratings and details about content help
          gamers make informed decisions. Remember, you can always come back
          later and make changes.
        </Small>
      </Grid>
    </>
  );
}

export default EditRating;
