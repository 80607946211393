import React, { useEffect, useState } from "react";
import { GlobalButtonStyled, ThemeSpacing } from "../../utils/globalStyles";
import { Alert, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  const [shake, setShake] = useState(false);
  const [scrambled, setScrambled] = useState("resource");

  useEffect(() => {
    const target = "resource";
    const characters = "abcdefghijklmnopqrstuvwxyz";
    const scrambleInterval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * target.length); // Pick a random position
      const randomChar = characters.charAt(Math.floor(Math.random() * characters.length)); // Pick a random character
      const newText = scrambled.substr(0, randomIndex) + randomChar + scrambled.substr(randomIndex + 1);
      setScrambled(newText);
    }, 150);

    return () => clearInterval(scrambleInterval);
  }, []);

  const handleClick = () => {
    setShake(true);
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  return (
    <ThemeSpacing>
      <Container>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <h1 className={shake ? "shake-animation" : ""} style={{ fontSize: "4rem", marginBottom: "1rem" }}>404</h1>
            <h2 className={shake ? "shake-animation" : ""}>Page or {scrambled} not found</h2>
            <p className={shake ? "shake-animation" : ""}>Whatever you do, don't click the button.</p>
            {
              shake && (
                <div style={{ width: "300px", margin: "0 auto" }}>
                  <Alert severity="error">Self destruct sequence initiated...</Alert>
                </div>
              )
            }
            {
              !shake && (
                <GlobalButtonStyled onClick={handleClick}>Don't Click Me</GlobalButtonStyled>
              )
            }
          </Grid>
        </Grid>
      </Container>
    </ThemeSpacing>
  );
}

export default NotFound;
