import React from "react";
import Cookies from "js-cookie";
import { GlobalButtonStyled } from "../../utils/globalStyles";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove("token");
    sessionStorage.removeItem("cart");
    navigate("/");
    window.location.reload();
  };

  return (
    <GlobalButtonStyled
      style={{ width: "100%" }}
      onClick={() => {
        handleLogout();
      }}
    >
      Logout
    </GlobalButtonStyled>
  );
}

export default Logout;
