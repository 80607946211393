import styled from "styled-components";

export const CAContainer = styled.div`
  background-color: #4c4c4c;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 1rem;
  h2 {
    margin-Bottom: 0.5rem;
  }
  p {
    margin-bottom: 0;
  }
`;

export const DiscussionContainer = styled.div`
  border-bottom: 2px solid #191919;
  border-radius: 0.25rem;
  padding: 0.25rem;
  background-color: #4c4c4c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin-bottom: 0;
  }
  h6 {
    margin-top: 0.25rem;
    margin-bottom: 0;
  }
`;

export const DiscussionSingleContainer = styled.div`
  display: flex;
  background-color: #4c4c4c;
  padding: 1rem;
  border-radius: 0.25rem;
  h4 {
    margin-bottom: 0.75rem;
  }
`;

export const DiscussionSingleCommentContainer = styled.div`
  background-color: #2c2c2c;
  padding: 1rem;
  border-radius: 0.25rem;
  h5 {
    margin-bottom: 0.75rem;
  }
`;

export const Pinned = styled.span`
  color: #fe5121;
`;

export const DiscussionIcon = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
`;

export const DiscussionTitle = styled.div`
  display: flex;
`;