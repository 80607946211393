import React, { useState } from "react";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons/faThumbsDown";
import { AdornmentHover, GlobalTextField } from "../../utils/globalStyles";
import { GameTypes } from "../../utils/types/GameTypes";
import { GameLikeButton, GameLikeButtonClicked } from "./styles/gameStyles";
import { parseCount } from "../../utils/helpers/parseCount";
import { parseTitle } from "../../utils/helpers/parseTitle";
import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import FloatingAlert from "../floatingAlert/FloatingAlert";

function GameDisplayLeft({
  user,
  game,
  gameLikes,
  handleAddGameLike,
  handleRemoveGameLike,
  hasUserLiked,
  handleOpen,
}: {
  user: any;
  game: GameTypes;
  gameLikes: any;
  handleAddGameLike: any;
  handleRemoveGameLike: any;
  hasUserLiked: any;
  handleOpen: any;
}) {
  const [copied, setCopied] = useState(false);
  const [alertMessage] = useState(
    "Successfully copied the game link to your clipboard!",
  );

  const copyToClipboard = () => {
    const urlToCopy = `https://reaktorarcade.com/games/${parseTitle(game.title)}/${game.game_id}`;
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  return (
    <>
      {copied && <FloatingAlert severity="success" message={alertMessage} />}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span>views</span>
          <h3 style={{ margin: 0 }}>
            {game.view_count ? parseCount(game.view_count.toString()) : 0}
          </h3>
        </div>
        <div>
          <span>likes</span>
          <h3 style={{ margin: 0 }}>
            {gameLikes ? parseCount(gameLikes.toString()) : 0}
          </h3>
        </div>
        <div>
          {hasUserLiked() ? (
            <GameLikeButtonClicked
              icon={faThumbsUp}
              style={{ marginRight: "1.5rem" }}
              onClick={() => {
                handleAddGameLike();
              }}
            />
          ) : (
            <>
              {user ? (
                <GameLikeButton
                  icon={faThumbsUp}
                  style={{ marginRight: "1.5rem" }}
                  onClick={() => {
                    handleAddGameLike();
                  }}
                />
              ) : (
                <GameLikeButton
                  icon={faThumbsUp}
                  style={{ marginRight: "1.5rem" }}
                  onClick={() => {
                    handleOpen();
                  }}
                />
              )}
            </>
          )}
          {user ? (
            <GameLikeButton
              icon={faThumbsDown}
              onClick={() => {
                handleRemoveGameLike();
              }}
            />
          ) : (
            <GameLikeButton
              icon={faThumbsDown}
              onClick={() => {
                handleOpen();
              }}
            />
          )}
        </div>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h3>Share</h3>
        <GlobalTextField
          type="text"
          disabled
          value={`https://reaktorarcade.com/games/${parseTitle(game.title)}/${
            game.game_id
          }`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AdornmentHover>
                  <FontAwesomeIcon
                    icon={faCopy}
                    style={{ paddingLeft: "0.5rem" }}
                    onClick={() => {
                      copyToClipboard();
                    }}
                  />
                </AdornmentHover>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
}

export default GameDisplayLeft;
