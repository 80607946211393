import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "react-image-crop/dist/ReactCrop.css";
import {
  FileInputWrapper,
  GlobalButtonDarkNoWidth,
  GlobalButtonStyled,
  GlobalDialog,
  GlobalDialogContainer,
  GlobalTextField,
  GlobalTextFieldDark,
  InputLabel,
  Small,
} from "../../../utils/globalStyles";
import Cookies from "js-cookie";
import { Transition } from "../../dialog/Transition";
import axios from "axios";
import { DialogTabContainer } from "../styles/gameStyles";

function GameThumbnailDialog({
  game,
  open,
  setOpen,
  setImageUploaded,
  selectedImage,
  setSelectedImage,
}: any) {
  const token = Cookies.get("token");
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [tab, setTab] = useState("image");

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(undefined);
    setSelectedImage(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg"];

      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        console.error("Invalid file type. Please select a PNG or JPEG file.");
      }
    }
  };

  const handleRemove = () => {
    setSelectedFile(undefined);
  };

  const handleSave = async () => {
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    try {
      const formData = new FormData();
      //console.log(selectedFile);
      formData.append("game_id", game.game_id);
      formData.append("game_thumbnail", selectedFile);

      // Send the FormData to the server
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/upload-game-thumbnail`,
        formData,
      );

      if (response.status === 200) {
        setSelectedFile(undefined);
        setImageUploaded(true);
        handleClose(); // Close the dialog after successful upload
      } else {
        console.error("Game Image upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading Game Image:", error);
    }
  };

  const handleDelete = () => {
    return null;
  };

  const handleTabChange = (value: string) => {
    setTab(value);
  };

  return (
    <>
      <GlobalDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <GlobalDialogContainer>
          <DialogTitle>Change Game Thumbnail</DialogTitle>
          <DialogContent>
            {tab === "image" && selectedImage ? (
              <>
                <img
                  src={`${process.env.REACT_APP_API_GAME_UPLOADS}/${selectedImage}`}
                  alt="Cropped File"
                  style={{
                    width: "100%",
                  }}
                />
                <InputLabel>Caption</InputLabel>
                <GlobalTextField
                  type="text"
                  placeholder="Enter caption..."
                  name="caption"
                />
                <Small>
                  This caption will appear when your image is viewed in full
                  screen.
                </Small>
                <GlobalButtonStyled
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Remove Image
                </GlobalButtonStyled>
              </>
            ) : (
              <>
                <Small>
                  A thumbnail is the little rectangular image that represents
                  your game throughout the site. People can click it in game
                  listings and search results to get to your game page.
                </Small>
                <Small>Your image must be a PNG or JPG.</Small>
                {!selectedFile && (
                  <FileInputWrapper>
                    <input
                      type="file"
                      style={{ width: "100%" }}
                      onChange={handleFileChange}
                    />
                  </FileInputWrapper>
                )}
                {selectedFile && (
                  <div>
                    {selectedFile ? (
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Cropped File"
                        style={{
                          maxWidth: "100%",
                        }}
                      />
                    ) : null}
                    <div style={{ marginTop: "1rem" }}>
                      <GlobalButtonDarkNoWidth
                        onClick={() => {
                          handleSave();
                        }}
                      >
                        Save
                      </GlobalButtonDarkNoWidth>
                      <GlobalButtonDarkNoWidth
                        onClick={() => {
                          handleRemove();
                        }}
                        style={{ marginLeft: "0.5rem" }}
                      >
                        Remove Game Media
                      </GlobalButtonDarkNoWidth>
                    </div>
                  </div>
                )}
              </>
            )}
          </DialogContent>
        </GlobalDialogContainer>
      </GlobalDialog>
    </>
  );
}

export default GameThumbnailDialog;
