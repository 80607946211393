import React from "react";
import { GameTypes } from "../../utils/types/GameTypes";

function GameRequirements({ game }: { game: GameTypes }) {
  // Check if any minimum requirements are set
  const hasMinimumRequirements = game.minimum_operating_system || game.minimum_processor || game.minimum_memory || game.minimum_graphics || game.minimum_storage || game.minimum_directx;

  // Check if any recommended requirements are set
  const hasRecommendedRequirements = game.recommended_operating_system || game.recommended_processor || game.recommended_memory || game.recommended_graphics || game.recommended_storage || game.recommended_directx;

  return (
    <div style={{ margin: '2rem 0' }}>
      {hasMinimumRequirements && (
        <>
          <h4>Minimum Requirements</h4>
          <ul>
            {game.minimum_operating_system && <li>Operating System: {game.minimum_operating_system}</li>}
            {game.minimum_processor && <li>Processor: {game.minimum_processor}</li>}
            {game.minimum_memory && <li>Memory: {game.minimum_memory}</li>}
            {game.minimum_graphics && <li>Graphics: {game.minimum_graphics}</li>}
            {game.minimum_storage && <li>Storage: {game.minimum_storage}</li>}
            {game.minimum_directx && <li>DirectX: {game.minimum_directx}</li>}
          </ul>
        </>
      )}
      {hasRecommendedRequirements && (
        <>
          <h4>Recommended Requirements</h4>
          <ul>
            {game.recommended_operating_system && <li>Operating System: {game.recommended_operating_system}</li>}
            {game.recommended_processor && <li>Processor: {game.recommended_processor}</li>}
            {game.recommended_memory && <li>Memory: {game.recommended_memory}</li>}
            {game.recommended_graphics && <li>Graphics: {game.recommended_graphics}</li>}
            {game.recommended_storage && <li>Storage: {game.recommended_storage}</li>}
            {game.recommended_directx && <li>DirectX: {game.recommended_directx}</li>}
          </ul>
        </>
      )}
    </div>
  )
}

export default GameRequirements;
