import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import {
  GlobalAlert,
  GlobalButtonStyled,
  GlobalTextField,
  ThemePaddingSpacing,
} from "../../utils/globalStyles";
import { LoginContainer, LoginInner, LoginOuter } from "./styles/loginStyles";
import axios from "axios";
import { validateLoginForm } from "../../utils/helpers/loginValidation";
import { useNavigate } from "react-router-dom";
import DisplayLogo from "../logo/DisplayLogo";
// @ts-ignore
import Video from "../../assets/Atomic-Arcade-Bg.mp4";
import { HomeVideo } from "../home/styles/homeStyles";

function Login({ user }: any) {
  const navigate = useNavigate();
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [isTwoFactorRequired, setIsTwoFactorRequired] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (user) navigate("/games");
  }, [user]);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    otp: "",
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOTPChange = (e: any) => {
    setOtp(e.target.value);
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();

    if (!validateLoginForm(formData, setErrors)) {
      return;
    }

    setErrors({
      username: "",
      password: "",
      otp: "",
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/auth/login`,
        isTwoFactorRequired ? { ...formData, token: otp } : formData
      );

      if (response.status === 200) {
        const { token } = response.data;
        document.cookie = `token=${token}; path=/`;
        setLoginSuccess(true);
        setTimeout(() => {
          navigate("/games");
          window.location.reload();
        }, 3000);
      } else if (response.status === 403 && response.data.twoFactorRequired) {
        setIsTwoFactorRequired(true);
        setErrors({ ...errors, otp: "Enter your two-factor authentication code." });
      } else {
        console.error("Login failed:", response.statusText);
      }
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 401) {
          setErrors({ ...errors, username: "Invalid username or password" });
        } else if (status === 400) {
          setErrors({ ...errors, username: "All fields are required" });
        } else if (status === 403 && error.response.data.twoFactorRequired) {
          setIsTwoFactorRequired(true);  // Ensure this is triggered correctly
          setErrors({ ...errors, otp: "Two-factor authentication required" });
        } else {
          console.error(`Unexpected server error: ${status}`, data);
        }
      } else if (error.request) {
        console.error("No response received from the server");
      } else {
        console.error("Error during request setup:", error.message);
      }
    }
  };

  return (
    <ThemePaddingSpacing style={{ padding: "4rem 0" }}>
      <HomeVideo src={Video} autoPlay={true} loop muted disablePictureInPicture>
        <source src={Video} type="video/mp4" />
      </HomeVideo>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <LoginOuter>
              <DisplayLogo />
              <LoginContainer>
                <LoginInner>
                  {loginSuccess && (
                    <GlobalAlert severity="success" style={{ marginBottom: "0.5rem" }}>
                      Login successful! Redirecting...
                    </GlobalAlert>
                  )}
                  <GlobalAlert
                    severity="error"
                    style={{ display: errors.username || errors.password || errors.otp ? 'flex' : 'none', marginBottom: "0.5rem" }}
                  >
                    {errors.username || errors.password || errors.otp}
                  </GlobalAlert>
                  <form onSubmit={handleLogin}>
                    {!isTwoFactorRequired && (
                      <>
                        <GlobalTextField
                          error={Boolean(errors.username)}
                          type="text"
                          placeholder="Username"
                          name="username"
                          value={formData.username}
                          onChange={handleChange}
                        />
                        <GlobalTextField
                          error={Boolean(errors.password)}
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                      </>
                    )}
                    {isTwoFactorRequired && (
                      <>
                        <GlobalTextField
                          error={Boolean(errors.otp)}
                          type="text"
                          placeholder="Authentication code"
                          name="otp"
                          value={otp}
                          onChange={handleOTPChange}
                        />
                        <GlobalButtonStyled
                          type="submit"
                          variant="contained"
                          style={{ width: "100%" }}
                        >
                          Verify
                        </GlobalButtonStyled>
                      </>
                    )}
                    {!isTwoFactorRequired && (
                      <GlobalButtonStyled
                        type="submit"
                        variant="contained"
                        style={{ width: "100%" }}
                      >
                        Login
                      </GlobalButtonStyled>
                    )}
                  </form>
                </LoginInner>
              </LoginContainer>
            </LoginOuter>
          </Grid>
        </Grid>
      </Container>
    </ThemePaddingSpacing>
  );
}

export default Login;
