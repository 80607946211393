import React from "react";

function FormFooter() {
  return (
    <div
      style={{
        marginTop: "1rem",
        textShadow: "2px 2px 2px rgba(0, 0, 0, 0.6)",
      }}
    >
      <small>
        By signing up, you agree to the{" "}
        <a href="/privacy-policy">Privacy Policy</a>.
      </small>
      <p>
        Already have an account? <a href="/login">Login!</a>
      </p>
    </div>
  );
}

export default FormFooter;
