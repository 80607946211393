import React from "react";

function GameDisplayRight({ game }: any) {
  return (
    <div style={{ marginTop: "2rem" }}>
      <h3>Recommended</h3>
      <p>We're working on this feature. It'll be available soon.</p>
    </div>
  );
}

export default GameDisplayRight;
