import React, { useEffect } from "react";
import {
  GlobalBoxTransparent,
  GlobalButtonStyled,
  ThemeSpacing,
} from "../../utils/globalStyles";
import { Container, Grid } from "@mui/material";
// @ts-ignore
import Video from "../../assets/Atomic-Arcade-Bg.mp4";
import { HomeVideo } from "../home/styles/homeStyles";

function Support() {
  useEffect(() => {
    document.title = "Reaktor Arcade - Support";
  }, []);

  return (
    <ThemeSpacing>
      <h1>Support</h1>
      <p>
        If you have questions about Reaktor Arcade, or you’re having an issue
        with the platform, contact uNhumanly on TG for support.
      </p>
      <p>Leave a message and we will get back with you.</p>
      <a href="https://t.me/uNhumanlyLLC" target="_blank" rel="noreferrer">
        <GlobalButtonStyled>View Telegram</GlobalButtonStyled>
      </a>
    </ThemeSpacing>
  );
}

export default Support;
