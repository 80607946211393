import React from "react";
import { Alert, AlertColor } from "@mui/material";
import { FloatingAlertContainer } from "./styles/floatingAlertStyles";
interface FloatingAlertProps {
  severity: AlertColor;
  message: string;
  close?: boolean;
}

const FloatingAlert: React.FC<FloatingAlertProps> = ({ severity, message, close }) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    localStorage.setItem("twoFactorNoticeAcknowledged", "1");
    setOpen(false);
  }

  return (
    <FloatingAlertContainer>
      {close && open && (
        <Alert severity={severity} onClose={() => { handleClose(); }}>
          <p>{message}</p>
        </Alert>
      )}
      {!close && (
        <Alert severity={severity}>
          <p>{message}</p>
        </Alert>
      )}
    </FloatingAlertContainer>
  );
};

export default FloatingAlert;
