import React, { useEffect, useState } from "react";
import { Container, Grid, MenuItem, Tooltip } from "@mui/material";
import {
  GlobalBox,
  GlobalSelect,
  GlobalSelectDark,
  InputLabel,
  Small,
  ThemeSpacing,
} from "../../utils/globalStyles";
import { GameTypes } from "../../utils/types/GameTypes";
import { CartImg } from "./styles/cartStyles";
import PayPalCheckoutButton from "./PayPalCheckoutButton";
import CryptoCheckoutButton from "./CryptoCheckoutButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import CartSuccess from "./CartSuccess";

function Cart({ user, cart, setCart }: any) {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);

  useEffect(() => {
    document.title = "Reaktor Arcade - Cart";
  }, [paymentSuccessful]);

  const handlePaymentSuccess = (paymentDetails: any) => {
    console.log("Payment Successful:", paymentDetails);
    setPaymentSuccessful(true);
  };

  const handleRemoveFromCart = (gameId: any) => {
    const updatedCart = cart.filter((item: any) => item.id !== gameId);
    setCart(updatedCart);
    sessionStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleSelectChange = (e: any) => {
    const value = e.target.value;
    setPaymentMethod(value);
  };

  const subtotal = cart.reduce((total: any, currentItem: any) => {
    return total + currentItem.price * (currentItem.quantity || 1);
  }, 0);

  if (paymentSuccessful) return <CartSuccess />;

  return (
    <ThemeSpacing>
      <Container>
        <GlobalBox>
          <Grid container>
            <Grid item xs={12}>
              <h1>Shopping Cart</h1>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={8}>
                  {cart.length === 0 ? (
                    <p>Your cart is empty.</p>
                  ) : (
                    cart.map((game: GameTypes) => (
                      <Grid
                        container
                        spacing={2}
                        key={game.game_id}
                        style={{ marginBottom: "1rem" }}
                      >
                        <Grid item xs={3} lg={3}>
                          <CartImg
                            src={`${process.env.REACT_APP_API_GAME_UPLOADS}/${game.thumbnail_path}`}
                            alt={game.title}
                          />
                        </Grid>
                        <Grid item xs={9} lg={9}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3>{game.title}</h3>
                              <Small
                                onClick={() => handleRemoveFromCart(game.id)}
                              >
                                <Tooltip title={`Remove from cart`}>
                                  <FontAwesomeIcon icon={faTrash} />
                                </Tooltip>
                              </Small>
                            </div>
                            <div>
                              {/* <span>${game.price}</span> */}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    ))
                  )}
                  {cart.length !== 0 ? (
                    <>
                      <Grid item xs={12} style={{ marginBottom: "2rem" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>Subtotal:</p>
                          <p>${subtotal.toFixed(2)}</p>
                        </div>
                        <Small>Taxes will be calculated at checkout.</Small>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Please select payment method</InputLabel>
                        <GlobalSelect
                          defaultValue="select"
                          name="paymentMethod"
                          onChange={(e) => {
                            handleSelectChange(e);
                          }}
                        >
                          <MenuItem value="select">
                            Please select payment method
                          </MenuItem>
                          <MenuItem value="paypal">PayPal</MenuItem>
                          <MenuItem value="crypto">Crypto</MenuItem>
                        </GlobalSelect>
                        {paymentMethod === "paypal" ? (
                          <PayPalCheckoutButton
                            total={subtotal}
                            onSuccess={handlePaymentSuccess}
                          />
                        ) : null}
                        {paymentMethod === "crypto" ? (
                          <CryptoCheckoutButton
                            totalAmount={subtotal}
                            onSuccess={handlePaymentSuccess}
                          />
                        ) : null}
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                {cart.length !== 0 ? (
                  <Grid item xs={12} lg={4}>
                    <h3>Purchasing on here</h3>
                    <p>
                      Once you've completed this transaction, your payment
                      method will be debited you'll receive an email message
                      confirming receipt of your purchase.
                    </p>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </GlobalBox>
      </Container>
    </ThemeSpacing>
  );
}

export default Cart;
