import React from "react";
import { GlobalButtonStyled, GlobalButtonStyledSmall, GlobalCheckbox, GlobalInput, GlobalTextField, ThemeSpacing } from "../../utils/globalStyles";
import Container from "@mui/material/Container/Container";
import Grid from "@mui/material/Grid/Grid";
import { useNavigate, useParams } from "react-router-dom";
import CommunityEditor from "../editor/CommunityEditor";
import axios from "axios";

function CommunityDiscussionCreate({ user }: { user: any }) {
  const navigate = useNavigate();
  const { id: communityId, title: communityTitle } = useParams();

  const [description, setDescription] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [pinned, setPinned] = React.useState(false);

  const handleDescriptionChange = (description: string) => {
    setDescription(description);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handlePinnedChange = () => {
    if (pinned) {
      setPinned(false);
    } else {
      setPinned(true);
    }
  }

  const handleCreateDiscussion = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_HOST}/community/create-discussion`, {
        community_id: communityId,
        title,
        content: description,
        pinned: pinned === true ? 1 : 0
      });

      if (response.status === 201) {
        alert("Discussion created successfully");
        // Redirect to announcements page
        navigate(`/communities/${communityTitle}/${communityId}/discussions`);
      } else {
        alert("Failed to create discussion");
      }
    } catch (error) {
      console.error("Error creating announcement:", error);
      alert("An error occurred while creating the announcement");
    }
  };

  return (
    <ThemeSpacing>
      <Container>
        <Grid container>
          <Grid item xs={8}>
            <a href={`/communities/${communityTitle}/${communityId}/discussions`}>
              <GlobalButtonStyledSmall style={{ marginBottom: "1rem" }}>
                Go Back
              </GlobalButtonStyledSmall>
            </a>
            <h1>Create Discussion</h1>
            <GlobalTextField
              onChange={handleTitleChange}
              style={{ marginBottom: "1rem" }}
              placeholder="Discussion title"
              value={title}
            />
            <div style={{ marginBottom: "1rem" }}>
              <GlobalCheckbox
                checked={pinned}
                onChange={handlePinnedChange}
                style={{ padding: 0 }}
              />
              <span>Pinned</span>
            </div>
            <CommunityEditor
              description={description}
              handleDescriptionChange={handleDescriptionChange}
            />
            <GlobalButtonStyled style={{ marginTop: "1rem" }} onClick={handleCreateDiscussion}>
              Create Discussion
            </GlobalButtonStyled>
          </Grid>
        </Grid>
      </Container>
    </ThemeSpacing>
  );
}

export default CommunityDiscussionCreate;
