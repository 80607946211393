import React, { useEffect, useState } from "react";
import avatar from "../../assets/logo00.png";
import { AvatarCircleLarge } from "../navigation/styles/navigationStyles";
import {
  GameHeaderInfoActionItems,
  GameHeaderInfoContainer,
  GameHeaderThumbnail,
} from "./styles/gameStyles";
import { Badge, CursorPointer, GameTitle } from "../../utils/globalStyles";
import { parseTitle } from "../../utils/helpers/parseTitle";
import axios from "axios";

function GameHeaderInfo({
  game,
  gameFollowers,
  gameComments,
  setOpenGameCommentDialog,
}: any) {
  const [userInfo, setUserInfo] = useState(null as any);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}/user/get-user-info`,
          { id: game.user_id },
        );
        setUserInfo(response.data);
        //console.log(userInfo);
      } catch (error: any) {
        setLoading(false);
      }
    };
    fetchData().then(() => setLoading(false));
    setLoading(false);
  }, []);

  if (loading) return null;

  return (
    <GameHeaderInfoContainer>
      <GameTitle>
        <h1>{game.title}</h1>
        <h4>by @{userInfo?.username}</h4>
      </GameTitle>
      <GameHeaderInfoActionItems>
        <ul>
          <li>
            <a href={`/games/${parseTitle(game.title)}/${game.game_id}`}>
              Overview
            </a>
          </li>
          <li>
            <CursorPointer
              onClick={() => {
                setOpenGameCommentDialog(true);
              }}
            >
              Comments{" "}
              <Badge>
                <span>
                  {gameComments.comment_count ? gameComments.comment_count : 0}
                </span>
              </Badge>
            </CursorPointer>
          </li>
          {/* <li>
            <a
              href={`/games/${parseTitle(game.title)}/${
                game.game_id
              }/followers`}
            >
              <div>
                Followers{" "}
                <Badge>
                  <span>
                    {gameFollowers.follower_count
                      ? gameFollowers.follower_count
                      : 0}
                  </span>
                </Badge>
              </div>
            </a>
          </li> */}
        </ul>
      </GameHeaderInfoActionItems>
    </GameHeaderInfoContainer>
  );
}

export default GameHeaderInfo;
