import React, { useEffect, useState } from "react";
import {
  ThemeSpacing,
} from "../../utils/globalStyles";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LibrarySelectionContainer, LibrarySelectionInfo } from "./styles/gameStyles";
import logo from "../../assets/game_default.png";

function GamesLibrary({ user }: any) {
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.username === undefined) {
      navigate("/games");
    }
    document.title = `Reaktor Arcade - Games Library`;
  }, []);

  const yourFollowedGamesLink = `/@${user?.username}/games/following`;
  const yourGamesLink = `/@${user?.username}/games`;
  const yourOwnedGamesLink = `/@${user?.username}/games/owned`;

  return (
    <ThemeSpacing>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>Games Library</h1>
          <p>
            This is your personalized library of games. Here dwell the games
            you've followed and any playlists or bundles that you've made or
            followed.
          </p>
        </Grid>
        <Grid item xs={12} lg={4} md={4} sm={4}>
          <a href={yourFollowedGamesLink}>
            <LibrarySelectionContainer
              style={{ backgroundImage: `url(${logo})` }}
            />
            <LibrarySelectionInfo>
              <h3>Your Followed Games</h3>
            </LibrarySelectionInfo>
          </a>
        </Grid>
        <Grid item xs={12} lg={4} md={4} sm={4}>
          <a href={yourGamesLink}>
            <LibrarySelectionContainer
              style={{ backgroundImage: `url(${logo})` }}
            />
            <LibrarySelectionInfo>
              <h3>Your Listed Games</h3>
            </LibrarySelectionInfo>
          </a>
        </Grid>
        <Grid item xs={12} lg={4} md={4} sm={4}>
          <a href={yourOwnedGamesLink}>
            <LibrarySelectionContainer
              style={{ backgroundImage: `url(${logo})` }}
            />
            <LibrarySelectionInfo>
              <h3>Your Owned Games</h3>
            </LibrarySelectionInfo>
          </a>
        </Grid>
      </Grid>
    </ThemeSpacing>
  );
}

export default GamesLibrary;
