export const gameTags = [
  { name: "Action", value: "action" },
  { name: "Adventure", value: "adventure" },
  { name: "Role Playing", value: "role-playing" },
  { name: "Simulation", value: "simulation" },
  { name: "Strategy", value: "strategy" },
  { name: "Sports", value: "sports" },
  { name: "Puzzle", value: "puzzle" },
  { name: "Idle", value: "idle" },
  { name: "Casual", value: "casual" },
  { name: "Arcade", value: "arcade" },
  { name: "Racing", value: "racing" },
  { name: "Fighting", value: "fighting" },
  { name: "Shooter", value: "shooter" },
  { name: "First Person Shooter", value: "first-person-shooter" },
  { name: "Third Person Shooter", value: "third-person-shooter" },
  { name: "Platformer", value: "platformer" },
  { name: "MOBA", value: "moba" },
  { name: "Battle Royale", value: "battle-royale" },
  { name: "MMO", value: "mmo" },
  { name: "MMORPG", value: "mmorpg" },
  { name: "Real Time Strategy", value: "real-time-strategy" },
  { name: "Turn Based Strategy", value: "turn-based-strategy" },
  { name: "Survival", value: "survival" },
  { name: "Horror", value: "horror" },
  { name: "Sandbox", value: "sandbox" },
  { name: "Narrative", value: "narrative" },
  { name: "Educational", value: "educational" },
  { name: "Stealth", value: "stealth" },
  { name: "Tower Defense", value: "tower-defense" },
  { name: "Virtual Reality", value: "virtual-reality" },
  { name: "Augmented Reality", value: "augmented-reality" },
  { name: "Card Game", value: "card-game" },
  { name: "Board Game", value: "board-game" },
  { name: "Text Based", value: "text-based" },
  { name: "Beat 'Em Up", value: "beat-em-up" },
  { name: "JRPG", value: "jrpg" },
  { name: "Walking Simulator", value: "walking-simulator" },
  { name: "Point & Click", value: "point-click" },
  { name: "eSports", value: "esports" },
  { name: "Space Sim", value: "space-sim" },
  { name: "Automobile Sim", value: "automobile-sim" },
  { name: "Interactive Fiction", value: "interactive-fiction" },
  { name: "Dating Sim", value: "dating-sim" },
  { name: "Life Sim", value: "life-sim" },
  { name: "Party-Based RPG", value: "party-based-rpg" },
  { name: "Grand Strategy", value: "grand-strategy" },
  { name: "Auto Battler", value: "auto-battler" },
  { name: "City Builder", value: "city-builder" },
  { name: "Tabletop", value: "tabletop" },
  { name: "Design & Illustration", value: "design-illustration" },
  { name: "Utilities", value: "utilities" },
  { name: "God Game", value: "god-game" },
  { name: "Colony Sim", value: "colony-sim" },
  { name: "Word Game", value: "word-game" },
  { name: "Farming Sim", value: "farming-sim" },
  { name: "Trivia", value: "trivia" },
];
