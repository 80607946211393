import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Navigation from "../navigation/Navigation";
import Home from "../home/Home";
import Login from "../login/Login";
import Cookies from "js-cookie";
import { fetchUser } from "../../utils/helpers/fetchUser";
import EditProfile from "../profile/EditProfile";
import ChangePassword from "../profile/ChangePassword";
import UserProfile from "../profile/UserProfile";
import TwoFactorAuthentication from "../profile/TwoFactorAuthentication";
import Register from "../register/Register";
import NotFound from "../notFound/NotFound";
import Games from "../games/Games";
import AddGame from "../games/AddGame";
import EditGame from "../games/edit/EditGame";
import GamesLibrary from "../games/GamesLibrary";
import UserGames from "../profile/UserGames";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "../../utils/theme";
import Game from "../games/Game";
import GameFollowers from "../games/GameFollowers";
import Cart from "../cart/Cart";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import UserFollowedGames from "../profile/UserFollowedGames";
import UserOwnedGames from "../profile/UserOwnedGames";
import Footer from "../footer/Footer";
import About from "../about/About";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import Support from "../support/Support";
import { Wrapper } from "../../utils/globalStyles";
import FloatingAlert from "../floatingAlert/FloatingAlert";
import Communities from "../communities/Communities";
import Community from "../communities/Community";
import CommunityAnnouncementCreate from "../communities/CommunityAnnouncementCreate";
import CommunityAnnouncement from "../communities/CommunityAnnouncement";
import CommunityDiscussionCreate from "../communities/CommunityDiscussionCreate";
import CommunityDiscussion from "../communities/CommunityDiscussion";
import CommunityAnnouncementEdit from "../communities/CommunityAnnouncementEdit";
import axios from "axios";
import ErrorBackdrop from "../../error/Error";

function App() {
  const [backendError, setBackendError] = useState(false);
  const storedTheme = Cookies.get("theme");
  const initialTheme = storedTheme === "dark" ? darkTheme : lightTheme;

  const [isDarkMode, setIsDarkMode] = useState(initialTheme === darkTheme);
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState(() => {
    const savedCart = sessionStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [loading, setLoading] = useState(true);
  const token = Cookies.get("token");

  const toggleTheme = () => {
    const newTheme = isDarkMode ? "light" : "dark";
    Cookies.set("theme", newTheme, { expires: 365 });
    setIsDarkMode(!isDarkMode);
  };

  const currentTheme = isDarkMode ? darkTheme : lightTheme;

  useEffect(() => {
    fetchUser(token)
      .then((user) => setUser(user))
      .finally(() => setLoading(false));

    axios
      .get(`${process.env.REACT_APP_API_HOST}/status/status`)
      .then(() => {
        setBackendError(false);
      })
      .catch(() => {
        setBackendError(true);
      });
  }, [token, currentTheme]);

  const twoFactorNoticeAcknowledged = localStorage.getItem("twoFactorNoticeAcknowledged");

  if (loading) return null;

  if (backendError) return <ErrorBackdrop />;

  return (
    <>
      <ThemeProvider theme={currentTheme}>
        <PayPalScriptProvider
          options={{ clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}` }}
        >
          <Router>
            <Wrapper>
              {twoFactorNoticeAcknowledged === null && user && (user as any).two_factor_enabled === 0 && (
                <FloatingAlert severity="warning" message={`Warning: You do not have two-factor authentication enabled. Your account is not secure! You can enable it in account settings.`} close={true} />
              )}
              <Navigation
                user={user}
                toggleTheme={toggleTheme}
                isDarkMode={isDarkMode}
                cart={cart}
                setCart={setCart}
              />
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Home user={user} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about" element={<About />} />
                <Route path="/support" element={<Support />} />
                <Route path="/login" element={<Login user={user} />} />
                <Route path="/register" element={<Register user={user} />} />
                <Route
                  path="/cart"
                  element={<Cart user={user} cart={cart} setCart={setCart} />}
                />
                <Route
                  path="/profile/settings"
                  element={<EditProfile user={user} />}
                />
                <Route
                  path="/profile/settings/change-password"
                  element={<ChangePassword user={user} />}
                />
                <Route
                  path="/profile/settings/two-factor"
                  element={<TwoFactorAuthentication user={user} />}
                />
                {/*<Route*/}
                {/*  path="/user/:username"*/}
                {/*  element={<UserProfile user={user} />}*/}
                {/*/>*/}
                <Route
                  path="/:username/games"
                  element={<UserGames user={user} />}
                />
                <Route
                  path="/:username/games/following"
                  element={<UserFollowedGames user={user} />}
                />
                <Route
                  path="/:username/games/owned"
                  element={<UserOwnedGames user={user} />}
                />
                <Route
                  path="/communities"
                  element={<Communities />}
                />
                <Route
                  path="/communities/:title/:id"
                  element={<Community user={user} endpoint="" />}
                />
                <Route
                  path="/communities/:title/:id/announcements"
                  element={<Community user={user} endpoint="announcements" />}
                />
                <Route
                  path="/communities/:title/:id/announcements/:announcementId"
                  element={<CommunityAnnouncement user={user} />}
                />
                <Route
                  path="/communities/:title/:id/announcements/:announcementId/edit"
                  element={<CommunityAnnouncementEdit user={user} />}
                />
                <Route
                  path="/communities/:title/:id/discussions"
                  element={<Community user={user} endpoint="discussions" />}
                />
                <Route
                  path="/communities/:title/:id/comments"
                  element={<Community user={user} endpoint="comments" />}
                />
                <Route
                  path="/communities/:title/:id/announcements/create"
                  element={<CommunityAnnouncementCreate user={user} />}
                />
                <Route
                  path="/communities/:title/:id/discussions/create"
                  element={<CommunityDiscussionCreate user={user} />}
                />
                <Route
                  path="/communities/:title/:id/discussions/:discussionId"
                  element={<CommunityDiscussion user={user} />}
                />
                <Route
                  path="/games/:title/:id"
                  element={<Game user={user} cart={cart} setCart={setCart} />}
                />
                <Route
                  path="/games/:title/:id/followers"
                  element={
                    <GameFollowers user={user} cart={cart} setCart={setCart} />
                  }
                />
                <Route path="/games" element={<Games user={user} />} />
                <Route
                  path="/games/library"
                  element={<GamesLibrary user={user} />}
                />
                <Route path="/games/add" element={<AddGame user={user} />} />
                <Route
                  path="/games/edit/:id"
                  element={<EditGame user={user} />}
                />
              </Routes>
              <Footer />
            </Wrapper>
          </Router>
        </PayPalScriptProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
