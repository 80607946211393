import React from "react";
import { Grid, LinearProgress } from "@mui/material";
import {
  FileInputWrapper,
  GlobalButtonStyled,
  GlobalCode,
  GlobalTextField,
  InputLabel,
} from "../../../utils/globalStyles";
import { GameUploadContainer } from "../styles/gameStyles";

function EditGameFiles({
  game,
  gameUploadPercentage,
  handleUploadGame,
  handleSaveAndNext,
  handleChange,
}: any) {
  return (
    <>
      <Grid item xs={12} lg={6}>
        <InputLabel>
          {game.game_files_path !== null
            ? "Update Game Files"
            : "Upload Game Files"}
        </InputLabel>
        {game.game_files_path !== null ? (
          <div style={{ marginBottom: "1rem" }}>
            <p>
              You've already uploaded game files. To update your game, upload new
              files below.
            </p>
            {/* <GlobalCode><a href={`https://api.reaktorarcade.com/uploads/games/files/${game.game_files_path}.zip`} download>https://api.reaktorarcacde.com/games/files/{game.game_files_path}</a></GlobalCode> */}
          </div>
        ) : null}
        {game.game_files_path === null && (
          <p>To upload your game, please ensure that it is in a single .zip file.</p>
        )}
        {gameUploadPercentage === 0 && (
          <FileInputWrapper>
            <input
              type="file"
              style={{ width: "100%" }}
              onChange={(e) => {
                handleUploadGame(e);
              }}
            />
          </FileInputWrapper>
        )}
        {gameUploadPercentage !== 0 ? (
          <GameUploadContainer>
            <div style={{ width: "90%" }}>
              <LinearProgress
                variant="determinate"
                value={gameUploadPercentage}
              />
            </div>
            <div style={{ width: "10%", marginLeft: "0.5rem" }}>
              <span>{gameUploadPercentage}%</span>
            </div>
          </GameUploadContainer>
        ) : null}
        {gameUploadPercentage === 100 && <p>Game uploaded successfully!</p>}
        <InputLabel>Launcher Link</InputLabel>
        <GlobalTextField
          type="text"
          placeholder="Launcher Link"
          name="launcher_link"
          value={game.launcher_link}
          style={{ marginBottom: "1rem" }}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <GlobalButtonStyled
          onClick={() => {
            handleSaveAndNext(4);
          }}
        >
          Save Game
        </GlobalButtonStyled>
      </Grid>
    </>
  );
}

export default EditGameFiles;
