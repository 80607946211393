import React, { useEffect, useRef, useState } from "react";
import {
  HomeLoginInnerContainer,
  HomeNotLoggedInContainer,
  HomeVideo,
  InputContainer,
  SplashLeft,
  SplashRight,
} from "./styles/homeStyles";
import {
  GlobalAlert,
  GlobalButtonStyled,
  GlobalTextField,
  ThemePaddingSpacing,
} from "../../utils/globalStyles";
import axios from "axios";
import { validateRegistrationForm } from "../../utils/helpers/loginValidation";
import FormFooter from "../formFooter/FormFooter";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import Video from "../../assets/Atomic-Arcade-Bg.mp4";
import Splash from "../../assets/splash.png";
import { Container, Grid } from "@mui/material";
import { RegisterContainer, RegisterInner, RegisterOuter } from "../register/styles/registerStyles";

function Home({ user }: any) {
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/games");
    }
  }, [user]);

  const handleRedirectLogin = () => {
    navigate("/login");
  }

  const handleRedirectRegister = () => {
    navigate("/register");
  }

  return (
    <ThemePaddingSpacing style={{ padding: "4rem 0 4rem 0" }}>
      <HomeVideo src={Video} autoPlay={true} loop muted disablePictureInPicture>
        <source src={Video} type="video/mp4" />
      </HomeVideo>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <RegisterOuter>
              <RegisterContainer style={{ flexDirection: "column" }}>
                <div style={{ textAlign: "center" }}>
                  <h1>The Home of Web3 Gaming</h1>
                  <h2>
                    Join our growing community of gamers and developers as we host
                    the newest blockchain based games.
                  </h2>
                  <h3>
                    Games launched on our platform will also benefit from retaining
                    90% of the generated revenue, making us sector leads in
                    supporting independent gaming projects.
                  </h3>
                </div>
                <div style={{ marginTop: "1rem", display: "flex", alignItems: "center" }}>
                  <GlobalButtonStyled
                    type="submit"
                    variant="contained"
                    style={{ width: "50%", marginRight: "1rem" }}
                    onClick={(e) => {
                      handleRedirectLogin();
                    }}
                  >
                    Login
                  </GlobalButtonStyled>
                  <GlobalButtonStyled
                    type="submit"
                    variant="contained"
                    style={{ width: "50%" }}
                    onClick={(e) => {
                      handleRedirectRegister();
                    }}
                  >
                    Sign up
                  </GlobalButtonStyled>
                </div>
              </RegisterContainer>
            </RegisterOuter>
          </Grid>
        </Grid>
      </Container>
    </ThemePaddingSpacing>
  );
}

export default Home;
