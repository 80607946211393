import styled from "styled-components";

export const DisplayLogoContainer = styled.div`
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.6));
  h3 {
    font-family: "Sixtyfour", sans-serif;
    margin: 0 0 0 1rem;
    font-size: 1rem;
  }
  img {
    height: 40px;
  }
`;
