const lightTheme = {
  colors: {
    primary: "#0078ff",
    secondary: "#2ecc71",
    red: "#59253A",
    dark: "#2D4159",
    text: "#fff",
    orange: "#e86f13",
    background: "#ecf0f1",
  },
  backgrounds: {
    white: "#ecf0f1",
    primary: "#2D4159",
    red: "#895061",
    lightBlue: "#0677A1",
    dark: "#1b2838",
  },
};

const darkTheme = {
  colors: {
    primary: "#3498db",
    secondary: "#2ecc71",
    red: "#59253A",
    dark: "#013671",
    text: "#ecf0f1",
    orange: "#e86f13",
    background: "#121015",
  },
  backgrounds: {
    white: "#ecf0f1",
    primary: "#2D4159",
    red: "#895061",
    lightBlue: "#0677A1",
    dark: "#1b2838",
  },
};

export { lightTheme, darkTheme };
