import React, { useEffect, useState } from "react";
import {
  UserCardAvatar,
  UserCardContainer,
  UserCardFollowers,
  UserCardHero,
} from "./styles/userStyles";
import { GlobalButtonStyled, Small } from "../../utils/globalStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import HeroImage from "../../assets/hero.jpeg";

function UserCard({ follower }: any) {
  const [userInfo, setUserInfo] = useState({
    display_name: null,
    username: null,
    avatar_path: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/user/get-user-info`, {
        id: follower.user_id,
      })
      .then((response) => {
        setUserInfo(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user's games:", error);
      });
  }, []);

  if (loading) return null;

  return (
    <UserCardContainer>
      <UserCardHero />
      <UserCardAvatar
        style={{
          backgroundImage: `url('${process.env.REACT_APP_API_USER_UPLOADS}/avatar/${userInfo.avatar_path}')`,
        }}
      />
      <h3>{userInfo.display_name}</h3>
      <Small>@{userInfo.username}</Small>
      {/*<UserCardFollowers>*/}
      {/*  <p>0 followers</p>*/}
      {/*  <FontAwesomeIcon*/}
      {/*    icon={faCircle}*/}
      {/*    style={{ fontSize: "0.25rem", margin: "0 0.5rem" }}*/}
      {/*  />*/}
      {/*  <p>0 following</p>*/}
      {/*</UserCardFollowers>*/}
      {/*<GlobalButtonStyled style={{ width: "90%", margin: "1rem 0 1rem 0" }}>*/}
      {/*  View Profile*/}
      {/*</GlobalButtonStyled>*/}
    </UserCardContainer>
  );
}

export default UserCard;
