import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { GlobalBox, ThemeSpacing } from "../../utils/globalStyles";
import { useNavigate } from "react-router-dom";

function CartSuccess() {
  // const navigate = useNavigate();
  // const [paymentCleared, setPaymentCleared] = useState(false);
  //
  // useEffect(() => {
  //   const isPaymentCleared = sessionStorage.getItem("paymentCleared");
  //   if (isPaymentCleared !== "1") {
  //     setTimeout(() => navigate("/"), 1000);
  //   } else {
  //     setPaymentCleared(true);
  //     sessionStorage.removeItem("paymentCleared");
  //   }
  // }, [navigate]);
  //
  // if (!paymentCleared) return null;

  return (
    <ThemeSpacing>
      <Container>
        <GlobalBox>
          <Grid container>
            <Grid item xs={12}>
              <h1>Payment Successful</h1>
              <p>
                Payment details have been sent to the email address on file for
                your account.
              </p>
              <p>Game(s) were automatically added to your account.</p>
            </Grid>
          </Grid>
        </GlobalBox>
      </Container>
    </ThemeSpacing>
  );
}

export default CartSuccess;
