import React, { useEffect } from "react";
import {
  AvatarCircle,
  AvatarWrapper,
  GlobalPopover,
  HamburgerContainer,
  MobileMenuContainer,
  NavigationContainer,
  NavigationLogo,
  NavigationLogoContainer,
  UserActionsContainer,
  UserDropdown,
  UserInfo,
  UserNav,
} from "./styles/navigationStyles";
import logo from "../../assets/Logo-White-Transparent.png";
import { GlobalButtonStyled } from "../../utils/globalStyles";
import Logout from "../logout/Logout";
import Hamburger from "hamburger-react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function Navigation({ user }: any) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [buttonOpen, setButtonOpen] = React.useState(false);

  useEffect(() => {
    document.body.style.overflow = buttonOpen ? 'hidden' : 'auto';
  }, [buttonOpen]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("token");
    sessionStorage.removeItem("cart");
    navigate("/");
    window.location.reload();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <NavigationContainer>
        <NavigationLogoContainer>
          <a href="/">
            <NavigationLogo>
              <img src={logo} alt="Reaktor Arcade" />
            </NavigationLogo>
          </a>
        </NavigationLogoContainer>
        <UserActionsContainer>
          <ul>
            <li>
              <a href="/games">Games</a>
            </li>
            <li>
              <a href="/communities">Communities</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/support">Support</a>
            </li>
          </ul>
          {user ? (
            <>
              <UserNav>
                {/* @ts-ignore */}
                <AvatarWrapper onClick={handleClick}>
                  {user.avatar_path ? (
                    <AvatarCircle
                      alt={user.username}
                      src={`${process.env.REACT_APP_API_USER_UPLOADS}/avatar/${user.avatar_path}`}
                    />
                  ) : (
                    <AvatarCircle alt={user.username} />
                  )}
                </AvatarWrapper>
              </UserNav>
              <GlobalPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <UserDropdown>
                  <div style={{ padding: "1rem", width: "100%" }}>
                    <UserInfo>
                      <a href={`/user/@${user.username}`}>
                        {user.display_name ? (
                          <h4>{user.display_name}</h4>
                        ) : null}
                        <span>@{user.username}</span>
                      </a>
                    </UserInfo>
                    <a href="/profile/settings">
                      <GlobalButtonStyled
                        style={{ width: "100%", marginBottom: "0.5rem" }}
                      >
                        Settings
                      </GlobalButtonStyled>
                    </a>
                    <a href="/games/library">
                      <GlobalButtonStyled
                        style={{ width: "100%", marginBottom: "0.5rem" }}
                      >
                        Game Library
                      </GlobalButtonStyled>
                    </a>
                    <Logout />
                  </div>
                </UserDropdown>
              </GlobalPopover>
              <HamburgerContainer>
                <Hamburger
                  toggled={buttonOpen}
                  toggle={setButtonOpen}
                  size={20}
                />
              </HamburgerContainer>
            </>
          ) : (
            <>
              <ul>
                <li>
                  <a href="/login">Login</a>
                </li>
                <li>
                  <a href="/register">Sign up</a>
                </li>
              </ul>
              <HamburgerContainer>
                <Hamburger
                  toggled={buttonOpen}
                  toggle={setButtonOpen}
                  size={20}
                />
              </HamburgerContainer>
            </>
          )}
        </UserActionsContainer>
      </NavigationContainer>
      {buttonOpen && (
        <MobileMenuContainer>
          <ul>
            <li>
              <a href="/games">Games</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/support">Support</a>
            </li>
            {user ? (
              <>
                <li>
                  <a href="/profile/settings">Settings</a>
                </li>
                <li>
                  <a href="/games/library">Game Library</a>
                </li>
                <li>
                  <GlobalButtonStyled
                    onClick={() => {
                      handleLogout();
                    }}
                  >
                    Logout
                  </GlobalButtonStyled>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a href="/login">Login</a>
                </li>
                <li>
                  <a href="/register">
                    <GlobalButtonStyled>Sign up</GlobalButtonStyled>
                  </a>
                </li>
              </>
            )}
          </ul>
        </MobileMenuContainer>
      )}
    </>
  );
}

export default Navigation;
