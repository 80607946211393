import React from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";

interface PayPalCheckoutButtonProps {
  total: number;
  onSuccess: (details: any) => any;
}

const PayPalCheckoutButton: React.FC<PayPalCheckoutButtonProps> = ({
  total,
  onSuccess,
}) => {
  const createOrder = (data: Record<string, unknown>, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: total.toFixed(2),
          },
        },
      ],
    });
  };

  const onApprove = (data: any, actions: any) => {
    sessionStorage.removeItem("cart");
    return actions.order.capture().then((details: any) => {
      onSuccess(details);
    });
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <PayPalButtons createOrder={createOrder} onApprove={onApprove} />
    </div>
  );
};

export default PayPalCheckoutButton;
