import styled from "styled-components";
import { Grid } from "@mui/material";

export const HomeNotLoggedInContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HomeLoginInnerContainer = styled.div`
  text-align: center;
  max-width: 500px;
  @media screen and (max-width: 1024px) {
    padding: 1rem;
    max-width: 100%;
  }
`;

export const InputContainer = styled.div`
  margin: 0 auto;
  width: 500px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const HomeVideo = styled.video`
  filter: brightness(50%); /* Adjust the percentage to get the desired darkness */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const SplashLeft = styled(Grid)`
  background-color: #2a2a2a;
  padding: 2rem;
`;

export const SplashRight = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 4rem 7rem 4rem;
  img {
    width: 60%;
  }
`;
