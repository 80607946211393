import React from "react";
import { Grid } from "@mui/material";
import {
  GlobalButtonStyled,
  GlobalSwitch,
  InputLabel,
  Small,
} from "../../../utils/globalStyles";
import CommunityOverviewEditor from "../../editor/CommunityOverviewEditor";

function EditCommunity({
  game,
  community,
  handleSaveCommunity,
  handleChangeC,
  handleOverviewChange,
  communityEnabled
}: any) {
  console.log(community.overview);
  return (
    <>
      <Grid item xs={12} lg={6}>
        <div>
          <InputLabel>Enable Community</InputLabel>
          <GlobalSwitch
            name="community_enabled"
            checked={communityEnabled}
            onChange={() => { handleChangeC(); }}
          />
        </div>
        {communityEnabled && (
          <>
            <InputLabel>Community Overview</InputLabel>
            <CommunityOverviewEditor
              game={game}
              community={community}
              handleOverviewChange={handleOverviewChange}
            />
          </>
        )}
        <GlobalButtonStyled
          style={{ marginTop: "1rem" }}
          onClick={() => {
            handleSaveCommunity();
          }}
        >
          Save
        </GlobalButtonStyled>
      </Grid>
      <Grid item xs={12} lg={3}>
        <h3>Communities</h3>
        <Small>
          Enabling communities allows players to interact with each other and the game developers. You can create forums, add announcements, and more to help build a community around your game.
        </Small>
      </Grid>
    </>
  );
}

export default EditCommunity;
