import React, { useEffect, useState } from "react";
import { GameThumbnail, GamesHeader } from "../games/styles/gameStyles";
import communitiesSplash from "../../assets/communities.png";
import { AdornmentHover, GlobalTextField } from "../../utils/globalStyles";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import Grid from "@mui/material/Grid/Grid";
import LoadingBackdrop from "../loading/LoadingBackdrop";
import axios from "axios";
import { parseTitle } from "../../utils/helpers/parseTitle";

function Communities() {
  const [loading, setLoading] = useState(true);
  const [communities, setCommunities] = useState([] as any);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCommunities, setFilteredCommunities] = useState([] as any);

  useEffect(() => {
    document.title = "Reaktor Arcade - Communities";
    axios
      .post(`${process.env.REACT_APP_API_HOST}/community/get-communities`)
      .then((response) => {
        setCommunities(response.data);
        setFilteredCommunities(response.data); // Set filtered communities initially to all communities
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching communities:", error);
      });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleSearch = (searchTerm: string) => {
    if (searchTerm.trim() === "") {
      setFilteredCommunities(communities); // Show all communities if search is empty
    } else {
      const filtered = communities.filter((community: any) =>
        community.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCommunities(filtered);
    }
  };

  if (loading) return <LoadingBackdrop />;

  return (
    <>
      <GamesHeader style={{ backgroundImage: `url('${communitiesSplash}')` }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <h1>Communities</h1>
        </div>
      </GamesHeader>
      <div style={{ padding: "2rem" }}>
        <Grid container justifyContent="center" spacing={2} style={{ marginBottom: "1.5rem" }}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <GlobalTextField
              variant="outlined"
              placeholder="Search communities"
              name="search"
              value={searchTerm}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AdornmentHover>
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ paddingLeft: "0.5rem" }}
                        onClick={() => handleSearch(searchTerm)}
                      />
                    </AdornmentHover>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {filteredCommunities.length > 0 ? (
            filteredCommunities.map((community: any) => (
              <Grid item lg={2} md={3} sm={4} xs={6} key={community.id}>
                <a
                  href={`/communities/${parseTitle(community.title)}/${community.game_id}`}
                >
                  <GameThumbnail
                    style={{
                      backgroundImage:
                        community.thumbnail_path !== null
                          ? `url(${process.env.REACT_APP_API_GAME_UPLOADS}/${community.thumbnail_path})`
                          : `url(${communitiesSplash})`,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <h3>{community.title}</h3>
                    </div>
                  </GameThumbnail>
                </a>
              </Grid>
            ))
          ) : (
            <Grid item lg={12}>
              <p>There are currently no communities.</p>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default Communities;
