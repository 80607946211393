import React from "react";
import { GamesActions } from "./styles/gameStyles";
import {
  GlobalButtonStyled,
  GlobalButtonStyledDanger,
  GlobalButtonStyledDark,
} from "../../utils/globalStyles";

function GamesActionBar({
  game,
  handleSaveAndNext,
  handleSelection,
  handleOpenPublishDialog,
  handleOpenDeleteDialog,
}: any) {
  return (
    <GamesActions>
      <ul>
        <li
          onClick={() => {
            handleSelection(0);
          }}
        >
          Details
        </li>
        <li
          onClick={() => {
            handleSelection(1);
          }}
        >
          Description
        </li>
        <li
          onClick={() => {
            handleSelection(2);
          }}
        >
          Design
        </li>
        <li
          onClick={() => {
            handleSelection(3);
          }}
        >
          Rating
        </li>
        <li
          onClick={() => {
            handleSelection(4);
          }}
        >
          System Requirements
        </li>
        <li
          onClick={() => {
            handleSelection(5);
          }}
        >
          Game Files
        </li>
        <li
          onClick={() => {
            handleSelection(6);
          }}
        >
          Community
        </li>
      </ul>
      <GlobalButtonStyled
        style={{ width: "100%", marginBottom: "1rem" }}
        onClick={() => {
          handleOpenPublishDialog();
        }}
      >
        {game.published === 0 ? <>Publish</> : <>Unpublish</>}
      </GlobalButtonStyled>
      <GlobalButtonStyled
        style={{ width: "100%", marginBottom: "1rem" }}
        onClick={() => {
          handleSaveAndNext(-1);
        }}
      >
        Save Game
      </GlobalButtonStyled>
      <GlobalButtonStyledDanger
        style={{
          width: "100%",
        }}
        onClick={() => {
          handleOpenDeleteDialog();
        }}
      >
        Delete Game
      </GlobalButtonStyledDanger>
    </GamesActions>
  );
}

export default GamesActionBar;
