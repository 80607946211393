import styled from "styled-components";

export const FloatingAlertContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
  z-index: 1000;
  p {
    margin: 0;
  }
`;
