import React from "react";
import { DisplayLogoContainer } from "./styles/logoStyles";
import logo from "../../assets/Logo-White-Transparent.png";

function DisplayLogo() {
  return (
    <DisplayLogoContainer>
      <img src={logo} alt="Logo" />
    </DisplayLogoContainer>
  );
}

export default DisplayLogo;
