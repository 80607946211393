import styled from "styled-components";

export const FooterContainer = styled.div`
  padding: 2rem;
  margin-top: auto;
  background: rgb(213, 35, 1);
  background: linear-gradient(
    180deg,
    rgba(213, 35, 1, 1) 0%,
    rgba(134, 36, 17, 1) 100%
  );
  color: ${(props) => props.theme.colors.text};
`;

export const FooterInner = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    padding: 0;
    display: inline-block;
  }
`;

export const FooterLinks = styled.div`
  ul {
    list-style: none;
    padding-inline-start: 0;
    margin-bottom: 0;
    li {
      font-size: 0.9rem;
      margin: 0 0 1rem 0;
      a {
        transition: 0.25s;
        &:hover {
          color: #191919;
        }
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
`;

export const FooterCopyright = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const FooterLinkHeader = styled.span`
  color: #d18d80;
  font-weight: bold;
  text-transform: uppercase;
`;
