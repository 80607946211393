import Grid from "@mui/material/Grid/Grid";
import Pagination from "@mui/material/Pagination/Pagination";
import React, { useEffect } from "react";
import { GlobalButtonStyled, GlobalButtonStyledSmall, GlobalCode, GlobalCodeDiscussion, GlobalTextField } from "../../utils/globalStyles";
import { InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { CAContainer, DiscussionContainer, DiscussionIcon, DiscussionTitle, Pinned } from "./styles/communityStyles";
import { faComments, faEnvelope, faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { parseTitle } from "../../utils/helpers/parseTitle";
import axios from "axios";
import LoadingBackdrop from "../loading/LoadingBackdrop";
import { parseDate } from "../../utils/helpers/parseDate";

function CommunityDiscussions({ community, isMember }: { community: any, isMember: any }) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [discussions, setDiscussions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/community/get-discussions`, { community_id: community.game_id })
      .then((response) => {
        setDiscussions(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching announcements:", error);
        setLoading(false);
      });
  }, [community.game_id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setSearchTerm(event.target.value);
  }

  const handleSearch = () => {
    return null;
  }

  console.log(discussions);

  if (loading) return <LoadingBackdrop />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "1rem" }}>
          <div>
            <span>Showing {discussions.length} out of {discussions.length} discussions</span>
          </div>
          <div>
            <Pagination count={1} variant="outlined" />
          </div>
        </div>
        <div>
          {discussions.map((discussion: any) => (
            <DiscussionContainer key={discussion.discussion_id}>
              <DiscussionTitle>
                <DiscussionIcon>
                  {discussion.pinned === 1 ? <FontAwesomeIcon icon={faThumbtack} /> : <FontAwesomeIcon icon={faEnvelope} />}
                </DiscussionIcon>
                <div>
                  <h4>
                    <a href={`/communities/${parseTitle(community.title)}/${community.game_id}/discussions/${discussion.discussion_id}`}>
                      {discussion.pinned === 1 ? <GlobalCodeDiscussion>PINNED</GlobalCodeDiscussion> : null}
                      {' '}
                      {discussion.title}
                    </a>
                  </h4>
                  <h6>{discussion.display_name ? discussion.display_name : discussion.username}</h6>
                </div>
              </DiscussionTitle>
              <div style={{ marginRight: "0.5rem" }}>
                <span>{parseDate(discussion.created_at)}</span>
                <span style={{ marginLeft: "1rem" }}><FontAwesomeIcon icon={faComments} style={{ marginRight: "0.25rem" }} /> {discussion.comment_count}</span>
              </div>
            </DiscussionContainer>
          ))}
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1rem" }}>
          <div>
            <span>Showing {discussions.length} out of {discussions.length} discussions</span>
          </div>
          <div>
            <Pagination count={1} variant="outlined" />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={4}>
        <GlobalTextField
          variant="outlined"
          placeholder="Search discussions"
          name="search"
          value={searchTerm}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{ paddingLeft: "0.5rem" }}
                  onClick={(e) => {
                    handleSearch();
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        {isMember && (
          <a href={`/communities/${parseTitle(community.title)}/${community.game_id}/discussions/create`}>
            <GlobalButtonStyled
              style={{ marginTop: "0.5rem", width: "100%" }}
            >
              Start a New Discussion

            </GlobalButtonStyled>
          </a>
        )}
      </Grid>
    </Grid>
  );
}

export default CommunityDiscussions;