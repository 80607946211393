import React, { useState, useEffect } from "react";
import {
  GlobalBox,
  GlobalButtonStyled,
  GlobalTextField,
  GlobalTextFieldDark,
  InputLabel,
  ThemeSpacing,
} from "../../utils/globalStyles";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserProfileInfo } from "./styles/profileStyles";
import ProfileActionBar from "./ProfileActionBar";
import axios from "axios";
import Cookies from "js-cookie";
import AvatarDialog from "./AvatarDialog";
import FloatingAlert from "../floatingAlert/FloatingAlert";

function ChangePassword({ user }: any) {
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [formData, setFormData] = useState({
    username: user.username,
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdatePassword = async () => {
    if (formData.newPassword !== formData.confirmNewPassword) return;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/auth/change-password`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        console.log("Password updated successfully");
        setShowSuccessAlert(true);
        setAlertMessage("Successfully updated your password");
        setTimeout(() => setShowSuccessAlert(false), 3000);
        setFormData({
          username: user.username,
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
      } else {
        console.error("Password update failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  return (
    <ThemeSpacing>
      {showSuccessAlert && (
        <FloatingAlert severity="success" message={alertMessage} />
      )}
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <h1 style={{ marginTop: 0 }}>Edit Profile</h1>
            <UserProfileInfo>
              <AvatarDialog user={user} />
              <div>
                {user?.display_name ? (
                  <>
                    <h3>{user?.display_name}</h3>
                    <h5>@{user?.username}</h5>
                  </>
                ) : (
                  <h5>@{user?.username}</h5>
                )}
              </div>
            </UserProfileInfo>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <ProfileActionBar />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Current Password</InputLabel>
              <GlobalTextField
                type="password"
                name="currentPassword"
                value={formData.currentPassword}
                onChange={handleInputChange}
              />
              <InputLabel>New Password</InputLabel>
              <GlobalTextField
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleInputChange}
              />
              <InputLabel>Confirm New Password</InputLabel>
              <GlobalTextField
                type="password"
                name="confirmNewPassword"
                value={formData.confirmNewPassword}
                onChange={handleInputChange}
              />
              <GlobalButtonStyled
                style={{ marginTop: "1rem" }}
                onClick={() => {
                  handleUpdatePassword();
                }}
              >
                Update Password
              </GlobalButtonStyled>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeSpacing>
  );
}

export default ChangePassword;
