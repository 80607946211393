import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "react-image-crop/dist/ReactCrop.css";
import {
  FileInputWrapper,
  GlobalButtonStyled,
  GlobalButtonStyledSmall,
  GlobalDialogContainerRgba,
  GlobalDialogRgba,
  GlobalTextField,
  Small,
} from "../../../utils/globalStyles";
import { Transition } from "../../dialog/Transition";
import axios from "axios";
import { DialogTabContainer, GameImageControls, GameImageFullscreen, ResponsiveYoutubeEmbed } from "../styles/gameStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

function GameImageDialog({
  game,
  open,
  setOpen,
  setImageUploaded,
  selectedImage,
  selectedVideo,
  setSelectedImage,
  setSelectedVideo,
  editing,
  gameImages,
  currentImageIndex,
  setCurrentImageIndex,
  selectedType,
  tab,
  setTab
}: any) {
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg"];

      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        console.error("Invalid file type. Please select a PNG or JPEG file.");
      }
    }
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedVideo(e.target.value);
  }

  const handleRemove = () => {
    setSelectedFile(undefined);
  };

  const handleSave = async () => {
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    try {
      const formData = new FormData();
      //console.log(selectedFile);
      formData.append("game_id", game.game_id);
      formData.append("game_image", selectedFile);

      // Send the FormData to the server
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/upload-game-image`,
        formData,
      );

      if (response.status === 200) {
        console.log("Game Image uploaded successfully");
        // Perform any additional actions or state updates as needed
        setSelectedFile(undefined);
        setImageUploaded(true);
        handleClose(); // Close the dialog after successful upload
      } else {
        console.error("Game Image upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading Game Image:", error);
    }
  };

  const handleSaveVideo = async () => {
    if (!selectedVideo) {
      console.error("No video selected");
      return;
    }

    try {
      // Send the FormData to the server
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/games/upload-game-video`,
        { game_id: game.game_id, video_id: selectedVideo },
      );

      if (response.status === 200) {
        console.log("Game Video added successfully");
        setSelectedVideo(undefined);
        setImageUploaded(true);
        handleClose();
      } else {
        console.error("Game Video add failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading Game Video:", error);
    }
  };

  const handleDelete = async () => {
    if (tab === "image") {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/delete-game-image`,
          { game_id: game.game_id, image_path: selectedImage },
        );
  
        if (res.status === 200) {
          setImageUploaded(true);
          handleClose();
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (tab === "video") {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/delete-game-video`,
          { game_id: game.game_id, video_path: selectedVideo },
        );
  
        if (res.status === 200) {
          setImageUploaded(true);
          handleClose();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleTabChange = (value: string) => {
    setTab(value);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex: number) => (prevIndex + 1) % gameImages.length); // Loop back to the first image
  };
  
  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex: number) => {
      if (prevIndex === 0) {
        return gameImages.length - 1; // Loop back to the last image
      }
      return prevIndex - 1;
    });
  };

  return (
    <>
      <GlobalDialogRgba
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <GlobalDialogContainerRgba>
          {!editing ? (
            <>
              <DialogContent>
                <div style={{ position: 'absolute', right: 0, zIndex: 999999 }}>
                  <GlobalButtonStyledSmall
                    style={{ marginRight: "1.25rem" }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close Dialog
                  </GlobalButtonStyledSmall>
                </div>
                  {selectedType === "image" && (
                    <GameImageFullscreen>
                      <img
                        src={`${process.env.REACT_APP_API_GAME_UPLOADS}/${gameImages[currentImageIndex].image_path}`}
                        alt={selectedImage}
                      />
                      <GameImageControls>
                        <FontAwesomeIcon onClick={handlePreviousImage} icon={faChevronLeft} />
                        <FontAwesomeIcon onClick={handleNextImage} icon={faChevronRight} />
                      </GameImageControls>
                    </GameImageFullscreen>
                  )}
                  {selectedType === "video" && (
                    <div style={{ padding: "10rem 18rem" }}>
                      <ResponsiveYoutubeEmbed>
                        <iframe src={`https://www.youtube.com/embed/${gameImages[currentImageIndex].video_path}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                      </ResponsiveYoutubeEmbed>
                      {/* <GameImageControls>
                        <FontAwesomeIcon onClick={handlePreviousImage} icon={faChevronLeft} />
                        <FontAwesomeIcon onClick={handleNextImage} icon={faChevronRight} />
                      </GameImageControls> */}
                    </div>
                  )}
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle>
                {selectedImage || selectedVideo ? "Edit Game Media" : "Add Game Media"}
              </DialogTitle>
              <DialogContent>
                <div style={{ position: 'absolute', top: '1rem', right: 0 }}>
                  {selectedImage && (
                    <GlobalButtonStyledSmall
                      style={{ marginRight: "1rem" }}
                      onClick={() => {
                        handleDelete();
                      }}
                    >
                      Remove
                    </GlobalButtonStyledSmall>
                  )}
                  {selectedVideo && (
                    <GlobalButtonStyledSmall
                      style={{ marginRight: "1rem" }}
                      onClick={() => {
                        handleDelete();
                      }}
                    >
                      Remove
                    </GlobalButtonStyledSmall>
                  )}
                  <GlobalButtonStyledSmall
                    style={{ marginRight: "1rem" }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close Dialog
                  </GlobalButtonStyledSmall>
                </div>
                <DialogTabContainer>
                  <span
                    onClick={() => {
                      handleTabChange("image");
                    }}
                  >
                    Insert Image
                  </span>
                  <span
                    onClick={() => {
                      handleTabChange("video");
                    }}
                  >
                  Insert Video
                  </span>
                </DialogTabContainer>
                {tab === "image" && (
                  <>
                    {selectedImage && (
                      <GameImageFullscreen style={{ padding: 0 }}>
                        <img
                          src={`${process.env.REACT_APP_API_GAME_UPLOADS}/${selectedImage}`}
                          alt="Cropped File"
                        />
                      </GameImageFullscreen>
                    )}
                    <Small>
                      Add screenshots, concept drawings, photos of little clay
                      models, fake box covers, or any other original art created
                      for the game. Yes, even if it's a text-based game!
                    </Small>
                    <Small>Your image must be a PNG or JPG.</Small>
                    <Small>
                      PNGs are highly recommended as they produce a lossless
                      image.
                    </Small>
                    {!selectedFile && (
                      <FileInputWrapper>
                        <input
                          type="file"
                          style={{ width: "100%" }}
                          onChange={handleFileChange}
                        />
                      </FileInputWrapper>
                    )}
                    {selectedFile && (
                      <div>
                        {selectedFile ? (
                          <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Cropped File"
                            style={{
                              maxWidth: "100%",
                            }}
                          />
                        ) : null}
                        <div style={{ marginTop: "1rem" }}>
                          <GlobalButtonStyled
                            onClick={() => {
                              handleSave();
                            }}
                          >
                            Save
                          </GlobalButtonStyled>
                          <GlobalButtonStyled
                            onClick={() => {
                              handleRemove();
                            }}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            Remove Game Media
                          </GlobalButtonStyled>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {tab === "video" && (
                  <>
                    <GlobalTextField
                      type="text"
                      placeholder="Enter YouTube Video ID"
                      name="video"
                      onChange={(e) => { handleChange(e); }}
                      style={{ width: "400px", marginBottom: "1rem" }}
                    />
                    {selectedVideo && (
                      <>
                        <div style={{ width: "800px", marginBottom: "1rem" }}>
                          <ResponsiveYoutubeEmbed>
                            <iframe src={`https://www.youtube.com/embed/${selectedVideo}`} title="EPIC Paladin Mount - How to get it - Full Quest Line - World of Warcraft Classic" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                          </ResponsiveYoutubeEmbed>
                        </div>
                        <GlobalButtonStyled
                          onClick={() => {
                            handleSaveVideo();
                          }}
                        >
                          Save
                        </GlobalButtonStyled>
                      </>
                    )}
                  </>
                )}
              </DialogContent>
            </>
          )}
        </GlobalDialogContainerRgba>
      </GlobalDialogRgba>
    </>
  );
}

export default GameImageDialog;
