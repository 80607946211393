import React, { useEffect, useState } from "react";
import {
  FullWidthContainer,
  GlobalBox,
  GlobalButtonStyled,
  GlobalButtonStyledDark,
  ThemeSpacing,
} from "../../utils/globalStyles";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LibrarySelectionContainer, LibrarySelectionInfo } from "../games/styles/gameStyles";
import logo from "../../assets/Atomic-Arcade-Text.png";
import { GameTypes } from "../../utils/types/GameTypes";
import { parseTitle } from "../../utils/helpers/parseTitle";
import LoadingBackdrop from "../loading/LoadingBackdrop";

function UserFollowedGames({ user }: any) {
  const navigate = useNavigate();
  const [usersGames, setUsersGames] = useState<GameTypes[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user?.username === undefined) {
      navigate("/games");
    } else {
      document.title = "Reaktor Arcade - Your Followed Games";
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/games/get-users-followed-games`,
          {
            user_id: user.id,
          },
        )
        .then((response) => {
          setUsersGames(response.data.games);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user's games:", error);
          setLoading(false);
        });
    }
  }, [user, navigate]);

  if (loading) return <LoadingBackdrop />;

  return (
    <ThemeSpacing>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1 style={{ margin: 0 }}>Your Followed Games</h1>
            <a href="/games/add">
              <GlobalButtonStyled>Add Your Game</GlobalButtonStyled>
            </a>
          </div>
          <p>
            These are the games that you've followed. Check them for updates!
          </p>
        </Grid>
        {usersGames.length > 0 &&
          usersGames.map((game) => (
            <Grid item xs={6} lg={3} md={4} key={game.game_id}>
              <a href={`/games/${parseTitle(game.title)}/${game.game_id}`}>
                <LibrarySelectionContainer
                  style={
                    game.thumbnail_path
                      ? {
                          backgroundImage: `url(${process.env.REACT_APP_API_GAME_UPLOADS}/${game.thumbnail_path})`,
                        }
                      : { backgroundImage: `url(${logo})` }
                  }
                />
                <LibrarySelectionInfo>
                  <h3>{game.title}</h3>
                </LibrarySelectionInfo>
              </a>
            </Grid>
          ))}
        {usersGames.length === 0 && (
          <Grid item xs={12} lg={12}>
            <p>You don't have any followed games yet.</p>
          </Grid>
        )}
      </Grid>
    </ThemeSpacing>
  );
}

export default UserFollowedGames;
