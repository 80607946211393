import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import LoadingBackdrop from "../loading/LoadingBackdrop";
import NotFound from "../notFound/NotFound";
import { GamesHeader } from "../games/styles/gameStyles";
import Container from "@mui/material/Container/Container";
import { Box, Grid } from "@mui/material";
import { Tab } from "@mui/base";
import { GlobalButtonStyled, GlobalTab, GlobalTabs, ThemeSpacing } from "../../utils/globalStyles";
import CommunityAbout from "./CommunityAbout";
import CommunityAnnouncements from "./CommunityAnnouncements";
import CommunityDiscussions from "./CommunityDiscussions";
import { parseTitle } from "../../utils/helpers/parseTitle";
import LoginDialog from "../login/LoginDialog";

function Community({ user, endpoint }: { user: any, endpoint: string }) {
  const { id } = useParams();
  const [community, setCommunity] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [noCommunityFound, setNoCommunityFound] = useState(false);
  const [tab, setTab] = useState(0);
  const [isMember, setIsMember] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const communityResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/community/get-community`,
          { id },
        );
        if (communityResponse.data.error) {
          setNoCommunityFound(true);
          setLoading(false);
        } else {
          setCommunity(communityResponse.data);
          document.title = `Reaktor Arcade - Community - ${communityResponse.data.title}`;
          const membershipResponse = await axios.post(
            `${process.env.REACT_APP_API_HOST}/community/check-membership`,
            { user_id: user?.id, community_id: id },
          );
          if (membershipResponse.data.membership) {
            setIsMember(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData().then(() => setLoading(false));
    checkEndpoint();
  }, [isMember]);

  const handleClose = () => {
    setOpen(false);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);

    const baseUrl = `/communities/${parseTitle(community.title)}/${id}`;

    switch (newValue) {
      case 0:
        history.pushState(null, '', `${baseUrl}/overview`);
        break;
      case 1:
        history.pushState(null, '', `${baseUrl}/announcements`);
        break;
      case 2:
        history.pushState(null, '', `${baseUrl}/discussions`);
        break;
      case 3:
        history.pushState(null, '', `${baseUrl}/comments`);
        break;
      default:
        break;
    }
  };

  const checkEndpoint = () => {
    if (endpoint === 'announcements') {
      setTab(1);
    } else if (endpoint === 'discussions') {
      setTab(2);
    } else if (endpoint === 'comments') {
      setTab(3);
    } else {
      setTab(0);
    }
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const joinCommunity = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/community/join-community`,
        {
          user_id: user?.id,
          community_id: community.game_id,
        },
      );
      if (response.data.joined) {
        setIsMember(true);
      } else {
        setIsMember(false);
      }
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  if (loading) return <LoadingBackdrop />;

  if (noCommunityFound) return <NotFound />;

  return (
    <>
      <LoginDialog open={open} handleClose={handleClose} />
      <GamesHeader style={{
        backgroundImage: `url('${process.env.REACT_APP_API_GAME_UPLOADS}/${community.header_path}')`,
      }}
      />
      <ThemeSpacing>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <GlobalTabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                  <GlobalTab label="Overview" {...a11yProps(0)} />
                  <GlobalTab label="Announcements" {...a11yProps(1)} />
                  <GlobalTab label="Discussions" {...a11yProps(2)} />
                  {/* <GlobalTab label="Comments" {...a11yProps(3)} /> */}
                </GlobalTabs>
                {!user
                  ? (
                    <GlobalButtonStyled onClick={() => setOpen(true)}>
                      Join Community
                    </GlobalButtonStyled>
                  )
                  : (
                    <GlobalButtonStyled
                      onClick={() => { joinCommunity() }}
                    >
                      {isMember ? 'Leave Community' : 'Join Community'}
                    </GlobalButtonStyled>
                  )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <CustomTabPanel value={tab} index={0}>
                <CommunityAbout community={community} />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <CommunityAnnouncements user={user} community={community} />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={2}>
                <CommunityDiscussions community={community} isMember={isMember} />
              </CustomTabPanel>
            </Grid>
          </Grid>
        </Container>
      </ThemeSpacing>
    </>
  );
}

export default Community;