import React, { useEffect } from "react";
import {
  GlobalBox,
  GlobalBoxTransparent,
  ThemeSpacing,
} from "../../utils/globalStyles";
import { Container, Grid } from "@mui/material";
// @ts-ignore
import Video from "../../assets/Atomic-Arcade-Bg.mp4";
import { HomeVideo } from "../home/styles/homeStyles";

function About() {
  useEffect(() => {
    document.title = "Reaktor Arcade - About";
  }, []);

  return (
    <ThemeSpacing>
      <h1>About</h1>
      <p>
        uNhumanly is actively developing block chain technology for video games
        and other software. Block chain technology, especially Proof of Work
        (PoW) block chains, have the distinct ability to make software such as
        video games completely free to play. Not only is the game free, but so
        is all the in game content.
      </p>
      <p>
        The best GPUs to play games on are also the best GPUs to mine crypto
        currency. Allowing your gaming rig to mine some crypto currency for the
        developers while you play, allows the developer to give you the software
        for free!
      </p>
      <p>
        No more credit card swipes and no more giving out your personal details
        for a company to sell. uNhumanly frees your personal details and bank
        account all in one swoop.
      </p>
      <p>
        Web3, PoW, Block Chain and uNhumanly will bring you software for free,
        and Reaktor Arcade is our first step into making your lives less of a
        hassle, and your games absolutely free.
      </p>
      <p>
        Note: You do not have to use the uN-Miner to play the games. It is there
        for you to try out at your convince. Be brave with us and take the next
        step into making software completely free! When you see how this works,
        you’ll never go back to swiping a credit card.
      </p>
    </ThemeSpacing>
  );
}

export default About;