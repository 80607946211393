import React from "react";
import DOMPurify from "dompurify";

function Sanitize({ content }: any) {
  const sanitizedContent = DOMPurify.sanitize(content);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
}

export default Sanitize;
