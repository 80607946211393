import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "react-image-crop/dist/ReactCrop.css";
import {
  GlobalButtonStyled,
  GlobalDialog,
  GlobalDialogContainer,
} from "../../../utils/globalStyles";
import { Transition } from "../../dialog/Transition";

function GamePublishDialog({ game, open, setOpen, handlePublish }: any) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <GlobalDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <GlobalDialogContainer>
          <DialogTitle>Publish Game</DialogTitle>
          <DialogContent>
            <p>
              Are you sure you want to{" "}
              {game.published === 0 ? "publish" : "unpublish"} your game?
            </p>
            <GlobalButtonStyled
              onClick={() => {
                handlePublish();
              }}
            >
              {game.published === 0 ? "Publish" : "Unpublish"}
            </GlobalButtonStyled>
          </DialogContent>
        </GlobalDialogContainer>
      </GlobalDialog>
    </>
  );
}

export default GamePublishDialog;
