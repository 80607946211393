import React, { useEffect, useState } from "react";
import { GlobalButtonStyledSmall, ThemeSpacing } from "../../utils/globalStyles";
import { Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { parseTitle } from "../../utils/helpers/parseTitle";
import { GamesHeader } from "../games/styles/gameStyles";
import axios from "axios";
import LoadingBackdrop from "../loading/LoadingBackdrop";
import NotFound from "../notFound/NotFound";
import { parseDate } from "../../utils/helpers/parseDate";
import DOMPurify from "dompurify";

function CommunityAnnouncement({ user }: { user: any }) {
  const communityId = useParams().id;
  const communityTitle = useParams().title;
  const announcementId = useParams().announcementId;

  const [community, setCommunity] = useState<any>(null);
  const [announcement, setAnnouncement] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [noAnnouncementFound, setNoAnnouncementFound] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const communityResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/community/get-community`,
          { id: communityId },
        );
        if (communityResponse.data.error) {
          setNoAnnouncementFound(true);
        } else {
          setCommunity(communityResponse.data);
        }
        const announcementResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/community/get-announcement`,
          { id: announcementId, community_id: communityId },
        );
        if (announcementResponse.data.error) {
          setNoAnnouncementFound(true);
        } else {
          setAnnouncement(announcementResponse.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData().then(() => setLoading(false));
  }, [communityId, communityTitle, announcementId])

  if (loading) return <LoadingBackdrop />;

  if (noAnnouncementFound) {
    return <NotFound />;
  }

  return (
    <>
      <GamesHeader style={{
        backgroundImage: `url('${process.env.REACT_APP_API_GAME_UPLOADS}/${community.header_path}')`,
      }}
      />
      <ThemeSpacing>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <GlobalButtonStyledSmall style={{ marginBottom: "1rem" }}>
                  <a href={`/communities/${parseTitle(communityTitle)}/${communityId}/announcements`}>
                    Go Back
                  </a>
                </GlobalButtonStyledSmall>
                {
                  user && user.id === announcement.posted_by
                    ? <GlobalButtonStyledSmall style={{ marginBottom: "1rem" }}>
                      <a href={`/communities/${parseTitle(communityTitle)}/${communityId}/announcements/${announcementId}/edit`}>
                        Edit Announcement
                      </a>
                    </GlobalButtonStyledSmall>
                    : null
                }
              </div>
              <h1>{announcement.title}</h1>
              <h4>{parseDate(announcement.created_at)}</h4>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcement.content) }} />
            </Grid>
          </Grid>
        </Container>
      </ThemeSpacing>
    </>
  );
}

export default CommunityAnnouncement;