import React, { useEffect, useState } from "react";
import { GlobalButtonStyled, GlobalTooltip } from "../../utils/globalStyles";
import { Container, Grid } from "@mui/material";
import LoginDialog from "../login/LoginDialog";
import { GameTypes } from "../../utils/types/GameTypes";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  GameActionButtons,
  GameActionContainer,
  GamesHeader,
} from "./styles/gameStyles";
import GameHeaderInfo from "./GameHeaderInfo";
import NotFound from "../notFound/NotFound";
import { GameFollowersTypes } from "../../utils/types/GameFollowersTypes";
import UserCard from "../user/UserCard";
import LoadingBackdrop from "../loading/LoadingBackdrop";

function GameFollowers({ user, cart, setCart }: any) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [game, setGame] = useState<GameTypes>({} as GameTypes);
  const [gameFollowers, setGameFollowers] = useState<GameFollowersTypes>({
    follower_count: 0,
    data: [
      {
        user_id: "",
        game_id: "",
      },
    ],
  });
  const [gameComments, setGameComments] = useState<any>({
    comment_count: 0,
    data: [
      {
        user_id: "",
        comment: "",
        created_at: "",
        updated_at: "",
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [noGameFound, setNoGameFound] = useState(false);
  const [viewCountUpdated, setViewCountUpdated] = useState(false);
  const [gameFollowersChanged, setGameFollowersChanged] = useState(false);
  const [userOwnsGame, setUserOwnsGame] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch game information
        const gameResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/get-game`,
          { id },
        );
        if (gameResponse.data.error) {
          setNoGameFound(true);
          setLoading(false);
          return;
        } else {
          setGame(gameResponse.data);
        }

        const gameFollowersResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/get-game-followers`,
          {
            game_id: gameResponse.data.game_id,
          },
        );
        setGameFollowers(gameFollowersResponse.data);

        const gameCommentsResponse = await axios.post(
          `${process.env.REACT_APP_API_HOST}/games/get-game-comments`,
          {
            game_id: gameResponse.data.game_id,
          },
        );
        setGameComments(gameCommentsResponse.data);
      } catch (error) {
        console.error("Error fetching game information:", error);
      }
    };

    fetchData().then(() => setLoading(false));
  }, [gameFollowersChanged]);

  useEffect(() => {
    if (!viewCountUpdated) {
      const updateViewCount = async () => {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_HOST}/games/update-game-view-count`,
            {
              game_id: id,
              user_id: user.id,
            },
          );
        } catch (error) {
          console.error("Error updating view count:", error);
        }
      };
      updateViewCount().then(() => setViewCountUpdated(true));
    }
  }, [id]);

  const handleAddGameFollower = () => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/games/update-game-followers`, {
        game_id: game.game_id,
        user_id: user.id,
      })
      .then((response) => {
        setGameFollowers(response.data);
        setGameFollowersChanged(true);
      })
      .catch((error) => {
        console.error("Error fetching game information:", error);
      });
  };

  const isUserAFollower = () => {
    if (!Array.isArray(gameFollowers.data)) {
      console.log("gameFollowers.data is not an array:", gameFollowers.data);
      return false;
    }

    const userIdAsString = String(user.id);
    const follower = gameFollowers.data.find(
      (follower) => follower.user_id === userIdAsString,
    );

    return !!follower;
  };

  const isGameInCart = (gameId: any) => {
    return cart.some((item: any) => item.game_id === gameId);
  };

  const handleDownloadGame = () => {
    navigate(`/@${user.username}/games/owned`);
  };

  const handleAddToCart = async () => {
    // if (game.free_to_play === 1) {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_API_HOST}/user/add-game-to-user`,
    //       {
    //         user_id: user.id,
    //         game_id: game.game_id,
    //       },
    //     );
    //     if (response.data.success) {
    //       setUserOwnsGame(true);
    //     }
    //   } catch (error) {
    //     console.error("Axios Error:", error);
    //   }
    // } else {
    //   const newCart = [...cart, game];
    //   setCart(newCart);
    //   sessionStorage.setItem("cart", JSON.stringify(newCart));
    // }
  };

  const handleRemoveFromCart = (gameId: any) => {
    const updatedCart = cart.filter(
      (item: { game_id: string }) => item.game_id !== gameId,
    );
    setCart(updatedCart);
    sessionStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  if (loading) return <LoadingBackdrop />;

  if (noGameFound) return <NotFound />;

  return (
    <>
      <LoginDialog open={open} handleClose={handleClose} />
      <GamesHeader
        style={{
          backgroundImage: `url('${process.env.REACT_APP_API_GAME_UPLOADS}/${game.header_path}')`,
        }}
      />
      <div style={{ padding: "2rem" }}>
        <GameActionContainer>
          <GameHeaderInfo
            game={game}
            gameFollowers={gameFollowers}
            gameComments={gameComments}
          />
          <GameActionButtons>
            {game.website && (
              <a
                href={`https://${game.website}`}
                target="_blank"
                rel="noreferrer"
              >
                <GlobalButtonStyled style={{ marginRight: "1rem" }}>
                  View Website
                </GlobalButtonStyled>
              </a>
            )}
            {isUserAFollower() ? (
              <GlobalTooltip
                title={`You follow this game!`}
                placement="top"
                arrow
              >
                <GlobalButtonStyled
                  style={{
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    handleAddGameFollower();
                  }}
                >
                  Following
                  <span style={{ marginLeft: "3rem" }}>
                    {gameFollowers.follower_count
                      ? gameFollowers.follower_count
                      : 0}
                  </span>
                </GlobalButtonStyled>
              </GlobalTooltip>
            ) : (
              <GlobalButtonStyled
                style={{
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  if (user?.id) {
                    handleAddGameFollower();
                  } else {
                    setOpen(true);
                  }
                }}
              >
                Follow
                <span style={{ marginLeft: "3rem" }}>
                  {gameFollowers.follower_count
                    ? gameFollowers.follower_count
                    : 0}
                </span>
              </GlobalButtonStyled>
            )}
            {user?.id === game.user_id ? (
              <a href={`/games/edit/${game.game_id}`}>
                <GlobalButtonStyled style={{ marginLeft: "0.5rem" }}>
                  Edit Game
                </GlobalButtonStyled>
              </a>
            ) : isGameInCart(game.game_id) ? (
              <GlobalButtonStyled
                style={{ marginLeft: "0.5rem" }}
                onClick={() => handleRemoveFromCart(game.game_id)}
              >
                {/* <span
                  style={{ fontSize: "0.8rem", marginRight: "1rem" }}
                >{`$${game.price}`}</span>{" "} */}
                Remove from Cart
              </GlobalButtonStyled>
            ) : (
              <GlobalButtonStyled
                style={{ marginLeft: "0.5rem" }}
                onClick={() => {
                  if (user?.id) {
                    handleAddToCart();
                  } else {
                    setOpen(true);
                  }
                }}
              >
                <>
                  {userOwnsGame ? (
                    <span
                      onClick={() => {
                        handleDownloadGame();
                      }}
                    >
                      Download Game
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          marginRight: "1rem",
                        }}
                      >
                        Free to Play
                      </span>
                      Add to Game Library
                    </>
                  )}
                </>
              </GlobalButtonStyled>
            )}
          </GameActionButtons>
        </GameActionContainer>
        <Grid container spacing={3} style={{ marginTop: "1rem" }}>
          {gameFollowers?.data?.map((follower: any) => (
            <Grid item xs={6} lg={2} md={3} sm={4} key={follower.user_id}>
              <UserCard follower={follower} />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}

export default GameFollowers;
